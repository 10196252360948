import { FC } from 'react'
import { styled } from '@mui/material/styles'
import Image from 'next/legacy/image'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import Typography from '~/src/shared/components/display/Typography'
import Button from '~/src/shared/components/inputs/Button'
import browserConfig from '~/src/shared/config/browser'
import { useGlobalContext } from '~/src/shared/context/GlobalContext'
import { ParsedError, ServerErrorCodes } from '~/src/shared/helpers/errors'
import { isPOSWebview } from '~/src/shared/lib/utils'

const RESTAURANT_IMAGE = browserConfig.buildAssetsPath(
  '/assets/images/TB_BrandIllustrations_KeyMessage1_ForResto_Left_Transparent.png'
)

const useStyles = makeStyles()((theme) => ({
  errorPage: {
    background: theme.palette.tb.dust,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    height: 'auto',
    minHeight: 'calc(100% - 2rem)',
    alignItems: 'center',
    padding: '1rem',
  },
  container: {
    maxWidth: theme.breakpoints.values.sm,
  },
  img: {
    position: 'relative',
    width: '100%',
    aspectRatio: '4 / 3',
  },
  unorderedList: {
    paddingInline: '1.625rem',
  },
  listItem: {
    fontFamily: theme.typography.fontFamilyRegular,
    color: theme.palette.tb.spaceGrey,
    margin: 0,
    '& > p': {
      lineHeight: '1.3rem',
      letterSpacing: 0,
    },
  },
  buttonContainer: {
    display: 'flex',
    padding: '0',
    gap: '0.5rem',
    marginTop: '1.5rem',
    width: 'max-content',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '& > a:first-child': {
      marginLeft: 0,
    },
    '& > a:last-child': {
      marginRight: 0,
    },
  },
}))

const StyledErrorBlock = styled('code')(({ theme }) => ({
  background: theme.palette.tb.spaceGrey,
  color: theme.palette.tb.scarlet,
  padding: theme.spacing(3),
  whiteSpace: 'pre-line',
  lineHeight: '1.5rem',

  '&:first-line': {
    lineHeight: '2rem',
    fontWeight: 700,
  },
}))

const DevErrorOverlay = styled('div')({
  alignContent: 'center',
  display: 'grid',
})

interface IErrorContentProps {
  // message is required, rest is conditionally rendered
  message: string
  errorCode?: string
  reasonList?: string[]
  optionList?: string[]
  parsedError?: Partial<ParsedError>
  isDashboardButtonEnabled?: boolean
}

const ErrorContent: FC<IErrorContentProps> = ({
  message,
  errorCode,
  reasonList,
  optionList,
  parsedError,
  isDashboardButtonEnabled = true,
}) => {
  const { classes } = useStyles()
  const { t, i18n } = useTranslation()
  const { incomingUserAgent } = useGlobalContext()
  const sharedServerCode = `shared.server_error_${parsedError?.code}`
  return (
    <div className={classes.errorPage} data-pw="error-page">
      <div className={classes.container}>
        <div className={classes.img}>
          <Image
            src={RESTAURANT_IMAGE}
            alt={t('alternative_image_text')}
            data-pw="merchant"
            layout="fill"
            objectFit="contain"
            priority
            unoptimized
          />
        </div>
        <Typography variant="h1" align="center">
          {message}
        </Typography>
        {errorCode && (
          <Typography variant="bodyBold" align="center">
            {errorCode}
          </Typography>
        )}
        {/* Showing the err_unknown_code is unnecessary */}
        {parsedError?.code !== ServerErrorCodes.err_unknown_code && (
          <Typography variant="subtitle" align="center">
            {i18n.exists(sharedServerCode) ? (
              <>
                {t(sharedServerCode)} ({parsedError?.code})
              </>
            ) : (
              <>{parsedError?.code}</>
            )}
          </Typography>
        )}
        {process.env.NEXT_PUBLIC_CONFIG_ENVIRONMENT_ID === 'local' && parsedError && (
          <DevErrorOverlay>
            <StyledErrorBlock>
              Original Error (Development Only)
              {'\n'}
              Message: {parsedError.message}
              {'\n'}
              Error Code: {parsedError.code}
              {'\n'}
              Status Code: {parsedError.statusCode}
            </StyledErrorBlock>
          </DevErrorOverlay>
        )}
        {reasonList && reasonList.length > 0 && (
          <>
            <Typography variant="h4">{t('error_page_reasons')}</Typography>
            <ul className={classes.unorderedList}>
              {reasonList.map((item, i) => (
                <li className={classes.listItem} key={i}>
                  <Typography variant="bodyBold">{item}</Typography>
                </li>
              ))}
            </ul>
          </>
        )}
        {optionList && optionList.length > 0 && (
          <>
            <Typography variant="h4">{t('error_page_you_can')}</Typography>
            <ul className={classes.unorderedList}>
              {optionList.map((item, i) => (
                <li className={classes.listItem} key={i}>
                  <Typography variant="bodyBold">{item}</Typography>
                </li>
              ))}
            </ul>
          </>
        )}
        <div className={classes.buttonContainer}>
          <Button
            variant="text"
            label={t('error_page_btn_contact')}
            href="https://www.touchbistro.com/contact-us/"
            target="_blank"
          />
          <Button
            variant="text"
            label={t('error_page_btn_support')}
            href={`https://help.touchbistro.com/s/?language=${i18n.language}`}
            target="_blank"
          />
          {!isPOSWebview(incomingUserAgent) && isDashboardButtonEnabled && (
            <Button href="/" variant="contained" label={t('error_page_btn_dashboard')} />
          )}
        </div>
      </div>
    </div>
  )
}

export default ErrorContent

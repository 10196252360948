const floorplanES = {
  'floorplan.tabletypes_select_label': 'Tipo de mesa',
  'floorplan.tabletypes_menu_item_name': '[ES]Name',
  'floorplan.tabletypes_menu_item_default': '[ES]Default',
  'floorplan.tabletypes_menu_item_edit': '[ES]Edit',
  'floorplan.tabletypes_create_tabletype_label': '[ES]Create Table Type',
  'floorplan.tabletypes_edit_tabletype_label': '[ES]Edit Table Type',
  'floorplan.tabletypes_tabletype_name_label': '[ES]Table Type Name',
  'floorplan.tabletypes_edit_en_textfield': '[ES]Enter Table Type name',
  'floorplan.tabletypes_edit_es_textfield': '[ES]Spanish translation',
  'floorplan.tabletypes_edit_fr_textfield': '[ES]French translation',
  'floorplan.tabletypes_en_chip': 'EN',
  'floorplan.tabletypes_es_chip': 'ES',
  'floorplan.tabletypes_fr_chip': 'FR',
  'floorplan.tabletypes_create_success_notification':
    '[ES]{{tabletype_name}} Table Type created successfully.',
  'floorplan.tabletypes_edit_success_notification':
    '[ES]{{tabletype_name}} Table Type saved successfully',
  'floorplan.tabletypes_too_many_tabletypes_dialog_title':
    '[ES]You’ve reached the maximum number of table types.',
  'floorplan.tabletypes_too_many_tabletypes_dialog_message':
    '[ES]Please modify your existing table types or delete a table type in order to create a new one.',
  'floorplan.tabletypes_duplicate_name_error': '[ES]A Table Type with this name already exists.',
  'floorplan.rightpane_tab_floorplan': '[ES]Floor Plan',
  'floorplan.rightpane_tab_table_combination': '[ES]Table Combinations',
  'floorplan.rightpane_tabletype_name_too_long_error':
    '[ES]Table Type Names must have less than 20 characters.',
  'floorplan.rightpane_create_table_combination_label': '[ES]Create a Table Combination',
  'floorplan.rightpane_create_table_combination_instructions':
    '[ES]Select 2 to 10 tables by holding down shift key or selecting and dragging across your desired tables.',
  'floorplan.rightpane_create_table_combination_button': '[ES]Create Table Combination',
  'floorplan.rightpane_create_table_combination_button_too_many_selected':
    '[ES]You can only select a maximum of 10 tables in a table combination.',
  'floorplan.rightpane_create_table_combination_button_combination_already_exists_header':
    '[ES]The Table Combination selection already exists.',
  'floorplan.rightpane_create_table_combination_button_combination_already_esists_body':
    '[ES]Please select another set of tables in order to create a new table combination or review your saved combinations in the Table Type Combination list',
  'floorplan.rightpane_tabletype_no_empty_fields':
    '[ES]Please enter a Table Type Name for at least one language.',
  'floorplan.dialog_prompt':
    'Estas seguro que deseas salir de esta pagina sin guardar tus cambios?',
  'floorplan.remove_table_combination_modal':
    '[ES]Are you sure you want to remove this combination?',
  'floorplan.cancel': '[ES]Cancel',
  'floorplan.remove': '[ES]Remove',
  'floorplan.dialog_discard_changes': 'Descartar Cambios',
  'floorplan.dialog_save_changes': 'Guardar Cambios',
  'floorplan.tabletypes_edit_default_success_notification':
    '[ES] {{tabletype_name}} has been set as the default table type',
  'floorplan.empty_table_combination': '[ES]No combinations exist',
  'floorplan.start_over': '[ES] Start Over',
  'floorplan.set_table_combination_details': '[ES] Set Table Combinations Details',
  'floorplan.table_combination_info_part_1':
    '[ES] The maximum seats for Table Combinations is 24 seats.',
  'floorplan.table_combination_info_part_2':
    '[ES] Note: Parties larger than 24 seats can still be booked in-person or over the phone by staff. ',
  'floorplan.selected_table_combination': '[ES] Selected Table Combination',
  'floorplan.see_suggested_combinations': '[ES] See Suggested Table Combination Variations',
  'floorplan.additional_combinations_info':
    '[ES] Additional combinations within your selected tables.',
  'floorplan.min_seats': '[ES] Min Seats',
  'floorplan.max_seats': '[ES] Max Seats',
  'floorplan.table_type_selection': '[ES] table type / table selection',
  'floorplan.table_combination_save_success': '[ES] New Table Combination saved successfully.',
  'floorplan.table_combination_duplicate_error': '[ES] Table combination already exists.',
  'floorplan.table_combination_update_success': '[ES] Table combination(s) updated successfully.',
  'floorplan.min_max_seats_error': '[ES] Min Seats must be less than or equal to Max Seats',
  'floorplan.max_min_seats_error': '[ES] Max Seats must be equal to or more than Min Seats',
  'floorplan.max_seats_limit_error': 'Las combinaciones de mesa no pueden exceder 24 asientos.',
  'floorplan.delete_table_part_of_combination_title':
    '[ES] Removing {{tableName}} will impact Table Combinations.',
  'floorplan.delete_table_part_of_combination_message':
    '[ES] All Table Combinations containing {{tableName}} will be removed. This change won’t be published until Floor Plan is Saved.',
  'floorplan.delete_table_part_of_combination_confirm_button': '[ES] Remove Table',
  'floorplan.priority': '[ES] Priority',
  'floorplan.view_table_combination_list_label': '[ES] View Selected Table Combination List',
  'floorplan.suggested_table_combination_label': '[ES] Suggested Table Combination',
  'floorplan.add_selection': '[ES] Add Selection',
}

export default floorplanES

import { UserProps } from '~/src/shared/helpers/withServerSidePropsDecorator'
import { SessionRole } from '~/src/shared/types'

/** @deprecated Kept for reference, prefer using policies over roleXRefIDs where possible*/
export enum RoleXRefIDs {
  ADMIN = '54f62cf7-6463-4b2b-a6c4-bec3c0e95449',
  MANAGER = '487cffd8-4830-43d9-977a-50f297935940',
  ANALYST = 'c136ef88-aac7-40b0-b76e-4a2dd708e35b',
  MENU_EDITOR = 'e7fa0ba4-58c3-4ac5-98bb-d33e18dbac91',
  CONTROLLER = '4ecbb79a-86b1-472b-9fcc-86ff4c7e9a03',
  CASHIER = '84be9d20-a4b2-456b-bfce-0c3364075e28',
  ASSISTANT_MANAGER = 'a97f8a83-f108-4a94-b41f-afca0d233301',
  KITCHEN_STAFF = 'a366d811-4989-4c5c-bf8b-34f2097d0975',
  GUEST_ENGAGEMENT = '796204a3-0bed-4acc-a1c2-d55dbc159b4d',
  SERVER = 'ee426869-f93b-48eb-8c3e-6f68abb26812',
  HOST = '58c5a69c-290b-4261-af4a-6c8aeb99c7a9',
}

export const isAgentMimicking = (user: UserProps | null): boolean => {
  // user could be null in GlobalContext
  if (!user) {
    return false
  }

  return user.role === SessionRole.AGENT && user.mimickedUser != null
}

import axios, { AxiosError, AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'
import browserConfig from '~/src/shared/config/browser'

const MAX_RETRIES = 3
const INTERVAL_MS = 1000

interface ErrorWithResponse extends AxiosError {
  response: {
    status: number
    data: string
  } & AxiosError['response']
}

type NextError = {
  message: string
}

type NextData = {
  err: NextError
}

function isErrDetails(obj: unknown): obj is NextError {
  if (typeof obj === 'object' && obj !== null) {
    return 'name' in obj && typeof (obj as { name: unknown }).name === 'string'
  }
  return false
}
function isObjectWithErr(obj: unknown): obj is { err: unknown } {
  return typeof obj === 'object' && obj !== null && 'err' in obj
}

function isValidNextData(object: unknown): object is NextData {
  if (isObjectWithErr(object)) {
    const err = object.err
    return isErrDetails(err)
  }

  return false
}

function extractNextErrorData(htmlString: string): NextError | null {
  const regex = /<script id="__NEXT_DATA__" type="application\/json">(.*?)<\/script>/s
  const match = htmlString.match(regex)
  if (match != null && match[1] != null) {
    try {
      const parsedData = JSON.parse(match[1])
      if (isValidNextData(parsedData)) {
        return parsedData.err
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to parse __NEXT_DATA__ JSON:', error)
    }
  }

  return null
}

function isErrorWithResponse(error: AxiosError): error is ErrorWithResponse {
  return typeof error.response?.status === 'number' && typeof error.response?.data === 'string'
}
/*
  nextjs can make API calls on the server or client side, this helper retries all API calls that error with ECONNREFUSED made by the given axios instance
  context: backend deploys cause ECONNREFUSED errors where the frontend cannot reach the backend servers because old revisions are shutting down
  this helper implements API call retries to the backend to mitigate the server reachability issue during deploys
*/
export function withRetries(a: AxiosInstance): AxiosInstance {
  // only retry in production env to keep tests stable
  if (
    (typeof window === 'undefined' && process.env.NODE_ENV !== 'production') ||
    browserConfig.environment !== 'production'
  ) {
    return a
  }

  axiosRetry(a, {
    retries: MAX_RETRIES,
    retryDelay: (retryCount) => Math.pow(2, retryCount) * INTERVAL_MS,
    retryCondition: (error) => {
      // server-side calls where browser window is undefined
      if (typeof window === 'undefined') {
        return error.code === 'ECONNREFUSED'
      }

      /* calls made from nextjs client-side receive a 500 status code and a html document with a <script /> element similar to this:
      <script id="__NEXT_DATA__" type="application/json">
        {"props":{"pageProps":{}},"page":"/_error","query":{},"buildId":"development","nextExport":true,"autoExport":true,"isFallback":false,"err":{"name":"Error","message":"connect ECONNREFUSED ::1:8013","stack":"Error: connect ECONNREFUSED ::1:8013\n    at TCPConnectWrap.afterConnect [as oncomplete] (node:net:1494:16)"},"scriptLoader":[]}
      />
      the check below parses the ECONNREFUSED keyword to ensure that this is a retryable API call
      */
      return (
        isErrorWithResponse(error) &&
        error.response.status === 500 &&
        (extractNextErrorData(error.response.data)?.message ?? '').includes('ECONNREFUSED')
      )
    },
    onRetry: (retryCount, err) => {
      // eslint-disable-next-line no-console
      console.log('Retrying....', { retryCount, err })
    },
  })

  return a
}

// configure global default axios instance
withRetries(axios)

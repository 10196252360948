const guestEngagementEN = {
  'ge.guest_engagement': 'Guest Engagement',
  'ge.no_results_found': 'You currently have no Rewards to be displayed.',
  'ge.create_new': 'Create New',
  'ge.rewards': 'Rewards',
  'ge.reward': 'reward',
  'ge.showing': 'Showing',
  'ge.create_new_reward': 'Create New Reward',
  'ge.set_expiring_offer_reminder_buffer_time': 'Set Expiring Offer Reminder Buffer Time',
  'ge.subscriptions': 'Subscriptions',
  'ge.expiring_promotional_offer': 'Expiring Promotional Offer Reminder Buffer',
  'ge.offer_reminder_1': 'Offer Reminder Buffer 1 (days)',
  'ge.offer_reminder_2': 'Offer Reminder Buffer 2 (days)',
  'ge.offer_subscriptions_tb_info':
    'With TouchBistro you can offer subscriptions that include automated fees, promotions, and emails.',
  'ge.offer_subscriptions': 'Offer Subscriptions',
  'ge.api_key': 'API Key',
  'ge.copy_and_paste_your_key_here': 'Copy and paste your key here',
  'ge.moonclerk_api_key_saved': 'Moonclerk Api Key saved',
  'ge.set_buffer_time_saved': 'Your set buffer time was saved',
  'ge.api_subscription_description_1': "To begin offering subscriptions, be sure you've ",
  'ge.follow_the_instructions': 'followed the instructions',
  'ge.api_subscription_description_2':
    'to set up your MoonClerk account and obtain your API Key. Enter your API Key below and click Save',
  'ge.confirm_cancel_moonclerk_dialog_msg':
    "Your account is already linked to MoonClerk and setup to offer subscriptions. To unlink your account, click the below button. This will remove the link to any activity in MoonClerk, but will not stop any Subscription related promotions or emails you've created.",
  'ge.save': 'Save',
  'ge.save_changes': 'Save Changes',
  'ge.confirm_and_save': 'Confirm & Save',
  'ge.cancel': 'Cancel',
  'ge.close': 'Close',
  'ge.continue': 'Continue',
  'ge.apply': 'Apply',
  'ge.step': 'Step',
  'ge.name_placeholder': 'Enter a name',
  'ge.status': 'Status',
  'ge.status_active': 'Active',
  'ge.status_inactive': 'Inactive',
  'ge.status_all': 'All',
  'ge.max_discount_amount': 'Max Discount Amount',
  'ge.discount_percentage': 'Discount Percentage',
  'ge.reward_points': 'Reward Points',
  'ge.reward_points.desc':
    'For Rewards that are ONLY being used in a Promotion or Item-Based Program, set this to 0 (zero).',
  'ge.reward_description': 'Description',
  'ge.reward_description_placeholder': 'Enter a description',
  'ge.select_reward_type': 'Select Reward Type:',
  'ge.reward_details': 'Reward Details:',
  'ge.CHECKFIXED': 'Bill Discount - Fixed',
  'ge.CHECKFIXED.desc': 'A dollar amount is taken off the bill',
  'ge.CHECKPERCENTAGE': 'Bill Discount - Percentage',
  'ge.CHECKPERCENTAGE.desc': 'A percentage is taken off the bill',
  'ge.ITEMFIXED': 'Item Discount - Fixed',
  'ge.ITEMFIXED.desc': 'A dollar amount is taken off the highest priced qualifying item',
  'ge.ITEMPERCENTAGE': 'Item Discount - Percentage',
  'ge.ITEMPERCENTAGE.desc': 'A percentage is taken off the highest priced qualifying item',
  'ge.ITEMBOGO': 'Buy X Get Y',
  'ge.ITEMBOGO.desc': "A percentage is taken off the 'Get' item when a 'Buy' item is bought",
  'ge.reward.save.success': 'Reward created successfully',
  'ge.reward.edit.success': 'Reward edited successfully',
  'ge.delete.success': 'Item was successfully deleted.',
  'ge.reward_name': 'Reward Name',
  'ge.type': 'Type',
  'ge.points': 'Points',
  'ge.description': 'Description',
  'ge.active': 'Active',
  'ge.inactive': 'Inactive',
  'ge.all': 'All',
  'ge.add': 'Add',
  'ge.hours': 'Hours',
  'ge.mon': 'Monday',
  'ge.tue': 'Tuesday',
  'ge.wed': 'Wednesday',
  'ge.thu': 'Thursday',
  'ge.fri': 'Friday',
  'ge.sat': 'Saturday',
  'ge.sun': 'Sunday',
  'ge.reward_availability': 'When can this Reward be redeemed?',
  'ge.all_times': 'ALL times',
  'ge.selected_days': 'Selected Days & Times',
  'ge.create_multiple_times': 'You can create multiple redemption times.',
  'ge.click_to_remove_time_block': 'Click x to remove a time block',
  'ge.available_entire_day': 'Available the entire day',
  'ge.starting_from': 'Starting From',
  'ge.up_until': 'Up Until',
  'ge.days_error_message': 'Please select the day(s) this reward is available.',
  'ge.program_days_error_message': 'Please select the day(s) this program is available.',
  'ge.select-modal-confirm-title': 'Please confirm',
  'ge.menu-groups': 'Menu Groups',
  'ge.menu-items': 'Menu Items',
  'ge.menu.no_menu_groups_message': 'There are no Menu Groups to Display',
  'ge.menu.no_menu_items_message': 'There are no Menu Items to Display',
  'ge.menu.prohibited_groups_items': 'Prohibited Groups/Items',
  'ge.menu.applicable_groups_items': 'Applicable Groups/Items',
  'ge.menu.filter_by_groups_items': 'Filter by Groups/Items',
  'ge.menu.bogo_buy_groups_items': 'Buy Groups/Items',
  'ge.menu.bogo_get_groups_items': 'Get Groups/Items',
  'ge.menu.remove_applicable_group_item': 'Click x to remove an Applicable Group/Item.',
  'ge.menu.remove_prohibited_group_item': 'Click x to remove a Prohibited Group/Item.',
  'ge.menu.remove_bogo_buy_group_item': 'Click x to remove a Buy Group/Item.',
  'ge.menu.remove_bogo_get_group_item': 'Click x to remove a Get Group/Item.',
  'ge.menu.all_venues': 'All Venues',
  'ge.menu.all_selected_venues': 'All Selected Venues ({{current}} of {{total}})',
  'ge.select-menu-items-groups-btn': 'Select Groups/Items',
  'ge.menu.refresh-failed': 'An error occurred while refreshing the Menu, please try again.',
  'ge.menu.selection_based_on_venue_filter': 'This selection is based on the Venue filter.',
  'ge.menu.refresh_menu': 'Refresh Menu',
  'ge.menu.view_menu_items': 'View Menu Items',
  'ge.menu.filtering_by': 'Filtering by',
  'ge.menu.back_to_all_menu_items': 'Back to all Menu Items',
  'ge.select-modal-confirm-category-text':
    'Selecting this Menu Group will override your Menu Items selected in this Menu Group.',
  'ge.select-modal-confirm-item-text':
    'Deselecting this Menu Item will deselect the entire Menu Group to which it belongs.',
  'ge.select-modal-confirm-ending': 'Are you sure you want to continue?',
  'ge.timeslot.overlapping_error': 'Ensure there are no overlapping time slots below',
  'ge.timeslot.start_differ_end_error': 'Start time should differ from end time.',
  'ge.timeslot.select_days_and_hours':
    "Please select the days and hours, and click the 'Add' button to create the timeslots for this reward.",
  'ge.edit_reward': 'Edit Reward',
  'ge.data-import.instructions':
    'Use this tool to import a CSV file of guests into TouchBistro. Please format the first row of the file with the following values',
  'ge.data-import.header': 'Guest Data Import ',
  'ge.data-import.action-button-choose-file': 'Choose file',
  'ge.data-import.action-button-reset-file': 'Clear',
  'ge.data-import.upload.success': 'Guest data is valid! Click send to upload.',
  'ge.data-import.upload.invalid-file':
    'CSV file is not valid. Please check the file and try again.',
  'ge.data-import.send.success': 'Guest data was sent successfully.',
  'ge.data-import.action-button-send-request': 'Send',
  'ge.reward.menu.filter_by_menu_group_items': 'Apply Menu Groups and/or Menu Items to the filter',
  'ge.reward.menu.prohibited_menu_group_items':
    'Exclude Menu Group and/or Menu Items from the Reward.',
  'ge.reward.menu.applicable_menu_group_items':
    'Apply Menu Groups and/or Menu Items to the Reward.',
  'ge.reward.menu.bogo_buy_menu_group_items': 'Apply Menu Groups and/or Menu Items to the Reward.',
  'ge.reward.menu.bogo_get_menu_group_items': 'Apply Menu Groups and/or Menu Items to the Reward.',
  'ge.reward.select-menu-items-groups': 'Select Menu Groups and/or Menu Items for the Reward.',
  'ge.reward.menu.group.placeholder': 'Search by Menu Group name',
  'ge.reward.menu.item.placeholder': 'Search by Menu Item name',
  'ge.reward.menu.item_in_group.placeholder': 'Search by Menu Item name within {{categoryName}}',
  'ge.reward.menu.select_all': 'Select All',
  'ge.reward.menu.clear': 'Clear',
  'ge.reward.menu.in_venue_only': 'In-Venue Only',
  'ge.reward.menu.available_online': 'Available Online',
  'ge.searchBar.no_results': 'No results were found',
  'ge.venues': 'Venues',
  'ge.apply_to_all_venues': 'Apply this Reward to ALL your Venues',
  'ge.select_venues': 'Select Venues',
  'ge.see_more': 'See More',
  'ge.remove_prompt': 'Click x to remove a Venue.',
  'ge.select_a_venue': 'At least 1 Venue needs to be selected.',
  'ge.reward.venues.select-venues': 'Select Venues',
  'ge.reward.venues.cancel': 'Cancel',
  'ge.reward.venues.apply': 'Apply',
  'ge.reward.venues.searchBar.placeholder': 'Search',
  'ge.reward.venues.select_all': 'Select All',
  'ge.reward.venues.clear': 'Clear',
  'ge.remove_location_modal.heading': 'Are you sure you want to change Venues selection?',
  'ge.remove_location_modal.body':
    'By changing your Venues you will lose ALL your Applicable/Prohibited Groups/Items selection.',
  'ge.reward.menu.select_category_or_item':
    'At least 1 Menu Category or Menu Item needs to be selected.',
  'ge.marketing.promotions': 'Promotions',
  'ge.marketing.promotions.promotion_name': 'Promotion Name',
  'ge.marketing.promotions.no_results_found': 'There are no Promotions to be displayed.',
  'ge.marketing.promotions.no_rewards_found': 'There are no Rewards to be displayed.',
  'ge.marketing.promotions.create': 'Create New Promotion',
  'ge.marketing.promotions.edit': 'Edit Promotion',
  'ge.marketing.promotions.create.success': 'Promotion created successfully',
  'ge.marketing.promotions.edit.success': 'Promotion updated successfully',
  'ge.marketing.promotions.details': 'Promotion Details',
  'ge.marketing.promotions.guest_page': 'Promotion Guest Page',
  'ge.marketing.promotions.new': 'New Promotion',
  'ge.marketing.promotions.reward_blocks.expires_in': 'Expires in: {{days}} day(s)',
  'ge.marketing.promotions.reward_blocks.quantity': 'Quantity',
  'ge.marketing.promotions.reward_blocks.helper': 'Click x to remove',
  'ge.marketing.promotions.rewards_to_send': 'Reward(s) to Send',
  'ge.marketing.promotions.rewards_to_send.notice':
    'A Promotion needs to have at least one Reward.',
  'ge.marketing.promotions.rewards_to_send.placeholder': 'Select Reward',
  'ge.marketing.promotions.rewards_to_send.error': 'Please select a Reward',
  'ge.marketing.promotions.rewards_to_send.create_reward': 'Create a New Reward',
  'ge.marketing.promotions.rewards_to_send.create_reward_modal.heading':
    'You will be redirected to the Rewards section.',
  'ge.marketing.promotions.rewards_to_send.create_reward_modal.body':
    'The changes you made to the page will not be saved.\nAre you sure you want to leave?',
  'ge.marketing.promotions.rewards_to_send.no_rewards': 'No Rewards to Display',
  'ge.marketing.promotions.days_till_expire': '# Days Until Expiration',
  'ge.marketing.promotions.quantity_per_user': 'Quantity Per User',
  'ge.marketing.promotions.internal_notes': 'Internal Notes',
  'ge.marketing.promotions.internal_notes.placeholder': 'Enter a Note',
  'ge.marketing.promotions.internal_notes.notice': 'Only visible to Admin.',
  'ge.marketing.promotions.reports.no_results_found':
    'You currently have no results to be displayed.',
  'ge.marketing.promotions.reports.user_count': 'Number of Users',
  'ge.marketing.promotions.reports.rewards_sent_count': 'Number of Rewards Sent',
  'ge.marketing.promotions.reports.rewards_redeemed_count': 'Number of Rewards Redeemed',
  'ge.marketing.promotions.reports.rewards_redeemed_percentage': 'Percentage of Rewards Redeemed',
  'ge.marketing.promotions.reports.total_spend': 'Total $ Spent',
  'ge.marketing.promotions.reports.average_spend_per_check': 'Avg. $ Spent Per Check',
  'ge.marketing.promotions.reports.user_report_guest_name': 'Guest Name',
  'ge.marketing.promotions.reports.user_report_email': 'Email',
  'ge.marketing.promotions.reports.user_report_phone': 'Phone Number',
  'ge.marketing.promotions.reports.user_report_rewards_name': 'Reward Name',
  'ge.marketing.promotions.reports.user_report_sent_date': 'Date Received',
  'ge.marketing.promotions.reports.user_report_expiration_date': 'Expiration Date',
  'ge.marketing.promotions.reports.user_report_redeemed_date': 'Date Redeemed',
  'ge.marketing.promotions.reports.user_report_ticket_sub_total': 'Total $ Spent On Redemption',
  'ge.marketing.promotions.reports': 'Promotion Reporting',
  'ge.marketing.promotions.reports.search_placeholder': 'Search by name, email or phone number',
  'ge.marketing.promotions.reports.status_UNREDEEMED': 'Unredeemed Rewards',
  'ge.marketing.promotions.reports.status_REDEEMED': 'Redeemed Rewards',
  'ge.marketing.promotions.reports.status_EXPIRED': 'Expired Rewards',
  'ge.marketing.promotions.reports.status_ALL': 'All',
  'ge.marketing.promotions.reports.show_by': 'Show By:',
  'ge.marketing.promotions.reports.remove_all_rewards': 'Remove All Rewards',
  'ge.marketing.promotions.reports.remove_all_rewards_modal_title':
    'Are you sure you want to continue?',
  'ge.marketing.promotions.reports.remove_all_rewards_modal_body':
    'This action will remove all Rewards from all user accounts. This CANNOT be undone.',
  'ge.marketing.promotions.reports.update_rewards_successfully':
    'Promotional rewards updated successfully.',
  'ge.marketing.promotions.reports.redeem_rewards': 'Redeem Reward',
  'ge.marketing.promotions.reports.remove_rewards_from_user': 'Remove Reward from User',
  'ge.marketing.promotions.reports.redeem_rewards_modal_title':
    'Are you sure you want to redeem this Reward?',
  'ge.marketing.promotions.reports.remove_rewards_modal_title':
    'Are you sure you want to remove this Reward?',
  'ge.marketing.promotions.view_report': 'View Report',
  'ge.of': 'of',
  'ge.marketing.promotions.guest_page.promo_link': 'Guest Promotion Page Link',
  'ge.marketing.promotions.guest_page.copy_btn': 'Copy Link',
  'ge.marketing.promotions.guest_page.copy_success': 'Link was successfully copied',
  'ge.marketing.promotions.guest_page.reward_name': 'Reward Name to Appear on Guest Promotion Page',
  'ge.marketing.promotions.guest_page.reward_description':
    'Reward Description to Appear on Guest Promotion Page',
  'ge.marketing.promotions.guest_page.max_rewards_per_user': 'Max TOTAL Rewards by SINGLE user',
  'ge.marketing.promotions.guest_page.max_rewards': 'Max TOTAL Rewards for ALL users',
  'ge.marketing.promotions.guest_page.link_exp': 'Link Expiration Date',
  'ge.guest_reporting': 'CRM',
  'ge.guest_reporting.userDisplayName': 'Guest Name',
  'ge.guest_reporting.marketingEmailConsent': 'Email opt-in',
  'ge.guest_reporting.totalVisits': 'Total Visits',
  'ge.guest_reporting.totalSpend': 'Total Spend',
  'ge.guest_reporting.totalSpendOnline': 'Online Order Spend',
  'ge.guest_reporting.totalSpendInStore': 'In-Store Spend',
  'ge.guest_reporting.averageSpend': 'Average Spend',
  'ge.guest_reporting.totalChecks': 'Total Checks',
  'ge.guest_reporting.totalPointsEarned': 'Points Earned',
  'ge.guest_reporting.totalItemsClaimed': 'Items Claimed',
  'ge.guest_reporting.totalPointsRedeemed': 'Points Redeemed',
  'ge.guest_reporting.totalMilestonesRedeemed': 'Milestones Redeemed',
  'ge.guest_reporting.totalOffersRedeemed': 'Offers Redeemed',
  'ge.guest_reporting.onlineOrdersPlaced': 'Online Orders Placed',
  'ge.guest_reporting.currentPointsBalance': 'Current Points',
  'ge.guest_reporting.birthdayRelative': 'Birthday',
  'ge.guest_reporting.registrationDateRelative': 'First Login Date',
  'ge.guest_reporting.lastVisitDateRelative': 'Last Visit Date',
  'ge.guest_reporting.no_results_found': 'You currently have no CRM data to be displayed.',
  'ge.guest_reporting.guest_data_update_alert':
    'Guest information is updated every 24 hours. The data on this page may not be up to date.',
  'ge.guest_reporting.column_header_info': 'Click on a column header to filter the results.',
  'ge.guest_reporting.export_button': 'Export',
  'ge.guest_reporting.show_guest_search': 'Show Guest Search',
  'ge.guest_reporting.hide_guest_search': 'Hide Guest Search',
  'ge.guest_reporting.selected_filters': 'Selected Filters',
  'ge.guest_reporting.save_filters': 'Save Filters',
  'ge.guest_reporting.LAST_WEEK': 'Last Week',
  'ge.guest_reporting.THIS_WEEK': 'This Week',
  'ge.guest_reporting.LAST_MONTH': 'Last Month',
  'ge.guest_reporting.THIS_MONTH': 'This Month',
  'ge.guest_reporting.NEXT_MONTH': 'Next Month',
  'ge.guest_reporting.LAST_YEAR': 'Last Year',
  'ge.guest_reporting.THIS_YEAR': 'This Year',
  'ge.guest_reporting.BEFORE_30_DAYS_AGO': 'Before 30 Days Ago',
  'ge.guest_reporting.BEFORE_90_DAYS_AGO': 'Before 90 Days Ago',
  'ge.guest_reporting.BEFORE_180_DAYS_AGO': 'Before 180 Days Ago',
  'ge.guest_reporting.BEFORE_365_DAYS_AGO': 'Before 1 Year Ago',
  'ge.guest_reporting.ascending': 'Ascending',
  'ge.guest_reporting.descending': 'Descending',
  'ge.guest_reporting.sort_by': 'Sort {{field}} By',
  'ge.guest_reporting.filter_by': 'Filter {{field}} By',
  'ge.guest_reporting.GREATER_THAN': 'Greater than',
  'ge.guest_reporting.LESS_THAN': 'Lesser than',
  'ge.guest_reporting.GREATER_THAN_EQUAL': 'Greater than (or Equal to)',
  'ge.guest_reporting.LESS_THAN_EQUAL': 'Lesser than (or Equal to)',
  'ge.guest_reporting.tags_button': 'Tags',
  'ge.guest_reporting.manage_tags': 'Manage Tags',
  'ge.guest_reporting.create_new_tag': 'Create New Tag',
  'ge.guest_reporting.add_tag': 'Add Tag',
  'ge.guest_reporting.no_tags_available': 'No Tags available',
  'ge.guest_reporting.no_locations_available': 'No Locations available',
  'ge.guest_reporting.no_programs_available': 'No Programs available',
  'ge.guest_reporting.no_promotions_available': 'No Promotions available',
  'ge.guest_reporting.add_to_filtered_users': 'Add to Filtered Users',
  'ge.guest_reporting.edit_tag': 'Edit Tag',
  'ge.guest_reporting.tag_name_placeholder': 'Enter a name',
  'ge.guest_reporting.customer_facing': "Allow guests to see that they've received this Tag?",
  'ge.guest_reporting.customer_self_subscribe': 'Allow guests to self subscribe to this Tag?',
  'ge.guest_reporting.edited_tag_saved': 'Tag "{{tag}}" successfully saved',
  'ge.guest_reporting.created_tag_saved': 'Tag "{{tag}}" successfully added',
  'ge.guest_reporting.add_tag_to_users': 'Tag "{{tag}}" successfully saved to {{users}} users',
  'ge.guest_reporting.add_tag_to_users_confirmation':
    'Are you sure you want to add the Tag "{{tag}}" to {{users}} users in this filtered search?',
  'ge.guest_reporting.remove_tag_confirmation': 'Are you sure you want to remove this Tag?',
  'ge.guest_reporting.remove_tag_to_users': 'Tag "{{tag}}" successfully removed',
  'ge.guest_reporting.advanced_filters': 'Advanced Filters',
  'ge.guest_reporting.advanced_filters.filter_by_tags': 'Filter By Tags',
  'ge.guest_reporting.advanced_filters.filter_by_loyalty_program':
    'Filter By Item-based Loyalty Program',
  'ge.guest_reporting.advanced_filters.lt': 'Current # of items are less than',
  'ge.guest_reporting.advanced_filters.gt': 'Current # of items are greater than',
  'ge.guest_reporting.advanced_filters.lte': 'Current # of items are less than or equal to',
  'ge.guest_reporting.advanced_filters.select_a_loyalty_program': 'Select a Program',
  'ge.guest_reporting.advanced_filters.filter_by_location': 'Filter By Locations',
  'ge.guest_reporting.advanced_filters.filter_by_items_ordered': 'Filter By Items Ordered',
  'ge.guest_reporting.tagIds': 'Tags',
  'ge.guest_reporting.homeLocationIds': 'Locations',
  'ge.guest_reporting.orderedCategoryIds': 'Menu Items in Categories',
  'ge.guest_reporting.orderedMenuItemIds': 'Menu Items',
  'ge.guest_reporting.greater_than_or_equal': 'Greater than or equal to',
  'ge.guest_reporting.lifetime': 'Lifetime',
  'ge.guest_reporting.last_one_year': 'Last 1 year',
  'ge.guest_reporting.last_90_days': 'Last 90 days',
  'ge.guest_reporting.last_30_days': 'Last 30 days',
  'ge.guest_reporting.last_7_days': 'Last 7 days',
  'ge.guest_reporting.userFirstName': 'First name',
  'ge.guest_reporting.userLastName': 'Last name',
  'ge.guest_reporting.userEmail': 'Email',
  'ge.guest_reporting.userPhoneNumber': 'Phone number',
  'ge.guest_reporting.submit': 'Submit',
  'ge.guest_reporting.date_range': 'Date Range',
  'ge.guest_reporting.user_details': 'User Details',
  'ge.guest_reporting.activity_feed': 'Activity Feed',
  'ge.guest_reporting.select_tags': 'Select Tags',
  'ge.guest_reporting.select_locations': 'Select Locations',
  'ge.guest_reporting.PASSPORTITEMMANUALLYADDED':
    'Loyalty Program Item Manually Added By Admin - Menu Item: {{menuItemName}} - Quantity: {{quantity}} - Loyalty Program: {{rewardBlockName}} - New Balance: {{numberOfItems}} item(s)',
  'ge.guest_reporting.PASSPORTITEMMANUALLYREMOVED':
    'Loyalty Program Item Manually Removed By Admin - Menu Item: {{menuItemName}} - Quantity: {{quantity}} - Loyalty Program: {{rewardBlockName}} - New Balance: {{numberOfItems}} item(s)',
  'ge.guest_reporting.PASSPORTITEMRECONCILED':
    'Loyalty Program Item Earned - Menu Item: {{menuItemName}} - Loyalty Program: {{rewardBlockName}}',
  'ge.guest_reporting.POINTSEARNED':
    'Points {{action}} - {{updatePoints}} point(s) - New Balance: {{newPointsBalance}} point(s)',
  'ge.guest_reporting.POINTSMANUALLYADDED':
    'Points Manually {{action}} By Admin - {{updatePoints}} point(s) - New Balance: {{newPointsBalance}} point(s) - Comment: {{comment}}',
  'ge.guest_reporting.REDEMPTIONVOIDED_PROMOTION':
    'Promotion Reward Redemption Voided - Reward: {{rewardName}}',
  'ge.guest_reporting.REDEMPTIONVOIDED_MILESTONE':
    'Milestone Reward Redemption Voided - Reward: {{rewardName}}',
  'ge.guest_reporting.REDEMPTIONVOIDED_POINTS':
    'Reward Redemption Voided - Reward: {{rewardName}} - {{updatePoints}} point(s) - New Balance: {{newPointsBalance}} point(s)',
  'ge.guest_reporting.REWARDREDEEMED_PROMOTION':
    'Promotion Reward Redeemed - Reward: {{rewardName}}',
  'ge.guest_reporting.REWARDREDEEMED_MILESTONE':
    'Milestone Reward Redeemed - Reward: {{rewardName}} - Loyalty Program: {{rewardBlockName}}',
  'ge.guest_reporting.REWARDREDEEMED_POINTS':
    'Reward Redeemed - Reward: {{rewardName}} - {{updatePoints}} point(s) - New Balance: {{newPointsBalance}} point(s)',
  'ge.guest_reporting.restaurant': 'at {{restaurantName}}',
  'ge.guest_reporting.removed': 'Removed',
  'ge.guest_reporting.earned': 'Earned',
  'ge.guest_reporting.added': 'Added',
  'ge.guest_reporting.view_bill': 'View Bill',
  'ge.guest_reporting.no_activity_available': 'No User Activity Yet',
  'ge.guest_reporting.promotions_and_comm': 'Promotions & Communications',
  'ge.guest_reporting.communicate_with_guests': 'Communicate With Guests',
  'ge.guest_reporting.promotions': 'Promotions',
  'ge.guest_reporting.email': 'Email',
  'ge.guest_reporting.in_app_messaging': 'In-App Messaging',
  'ge.guest_reporting.message_title': 'Title',
  'ge.guest_reporting.valid_message_sent': 'Your message was sent successfully',
  'ge.guest_reporting.message': 'Message',
  'ge.guest_reporting.promotion_to_send': 'Promotion to Send',
  'ge.guest_reporting.send_promotions_success': 'Success! Users have been added to the promotion',
  'ge.guest_reporting.send_to_guests': 'Send to {{guestNum}} Guest(s)',
  'ge.guest_reporting.configure_send_grid': 'Configure SendGrid',
  'ge.guest_reporting.connect_send_grid_account_msg':
    "Enter your API key and the email address used to register your SendGrid account to connect. \nEmails sent to guests will be 'From' this email address.",
  'ge.guest_reporting.configure_send_grid_info_message':
    'To send emails with TouchBistro Marketing, connect your SendGrid account.',
  'ge.guest_reporting.select_a_promotion': 'Select a Promotion',
  'ge.guest_reporting.api_key': 'API Key',
  'ge.guest_reporting.from_email': '"From" Email',
  'ge.guest_reporting.valid_update_api_key': 'Update Successful',
  'ge.guest_reporting.email_template': 'Email Template',
  'ge.guest_reporting.choose_a_template': 'Choose a template',
  'ge.guest_reporting.preview_template': 'Preview Template',
  'ge.guest_reporting.manage_templates_info':
    'To manage your templates, visit your SendGrid Dashboard and click Email API, then Dynamic Templates in the left navigation',
  'ge.guest_reporting.change_email_text': 'Change SendGrid API Key and "from" email address',
  'ge.guest_reporting.back': 'Back',
  'ge.guest_reporting.send_grid_success_message':
    'Your emails have been successfully added to the queue and will be sent shortly',
  'ge.guest_reporting.trouble_connecting_sendgrid_header':
    'We are having trouble connecting to your SendGrid Account.',
  'ge.guest_reporting.trouble_connecting_sendgrid_num_1': '1. Ensure that your ',
  'ge.guest_reporting.trouble_connecting_sendgrid_num_1_link': 'SendGrid account',
  'ge.guest_reporting.trouble_connecting_sendgrid_num_1_end': ' is active',
  'ge.guest_reporting.trouble_connecting_sendgrid_num_2': '2. Double check that your ',
  'ge.guest_reporting.trouble_connecting_sendgrid_num_2_link': 'SendGrid API key',
  'ge.guest_reporting.trouble_connecting_sendgrid_num_2_end': ' is correct.',
  'ge.guest_reporting.subtotal': 'Subtotal',
  'ge.guest_reporting.online_order': 'Online Order',
  'ge.guest_reporting.quantity': 'Quantity',
  'ge.guest_reporting.create_a_new_saved_filter': 'Create A New Saved Filter',
  'ge.guest_reporting.updated_saved_filter': 'Update Saved Filter',
  'ge.guest_reporting.filter_name': 'Filter Name',
  'ge.guest_reporting.filter_saved': 'Filter "{{filterName}}" Saved',
  'ge.guest_reporting.filter_updated': 'Filter "{{filterName}}" Updated',
  'ge.guest_reporting.filter_applied': 'Saved Filter: {{customFilterName}}',
  'ge.guest_reporting.guest_list': 'Guest List',
  'ge.guest_reporting.your_saved_filters': 'Your Saved Filters',
  'ge.guest_reporting.no_filters_found': 'There are no Filters to be displayed.',
  'ge.guest_reporting.update_or_create_filters':
    'Do you want to update this filter or create a new filter?',
  'ge.guest_reporting.update_this_filter': 'Update This Filter',
  'ge.guest_reporting.create_a_filter': 'Create New Filter',
  'ge.guest_reporting.filter_delete': 'Are you sure you want to delete this filter?',
  'ge.guest_reporting.filter_delete_success': 'Filter {{filterName}} was deleted successfully',
  'ge.guest_reporting.automations_delete_modal_warning':
    'This filter is currently being used by {{automationsTotal}} Marketing Automation(s) and cannot be deleted.',
  'ge.guest_reporting.go_to_marketing': 'Go to Marketing Automations',
  'ge.guest_feedback': 'Guest Feedback',
  'ge.guest_feedback.experience_feedback': 'Experience Feedback',
  'ge.guest_feedback.item_feedback': 'Item Feedback',
  'ge.guest_feedback.placeholder': 'Search reviews',
  'ge.guest_feedback.buffer_time': 'Set Text Notification Buffer Time',
  'ge.guest_feedback.overall_rating': 'Overall Rating',
  'ge.guest_feedback.based_on_ratings': 'Based on {{ratingAmount}} Rating(s)',
  'ge.guest_feedback.since': 'Since {{sinceDate}}',
  'ge.guest_feedback.filter_by': 'Filter By',
  'ge.guest_feedback.stars': '{{star}} stars',
  'ge.guest_feedback.sort_by': 'Sort By',
  'ge.guest_feedback.most_recent': 'Most Recent',
  'ge.guest_feedback.high_to_low': 'High to Low Rating',
  'ge.guest_feedback.low_to_high': 'Low to High Rating',
  'ge.guest_feedback.only_comments': 'Only Ratings with comments',
  'ge.guest_feedback.alpha': 'Alphabetical',
  'ge.guest_feedback.rating': 'Rating',
  'ge.guest_feedback.review': 'Review',
  'ge.guest_feedback.visited': 'Visited: {{dateVisited}}',
  'ge.guest_feedback.no_results_found': 'There is no feedback to display.',
  'ge.guest_feedback.all': 'All',
  'ge.guest_feedback.venue': 'Venue',
  'ge.guest_feedback.all_dates': 'All Dates',
  'ge.guest_feedback.diner_text_notify_buffer': 'Diner Feedback Text Notification Buffer',
  'ge.guest_feedback.text_notify_buffer_time':
    'Buffer Time for Sending the Feedback Text Notification (minutes)',
  'ge.guest_reporting.suggested_filters': 'Suggested Filters',
  'ge.guest_reporting.suggested_filters.filter_desc': 'Filter Description',
  'ge.guest_reporting.suggested_filters.loyalty_club': 'Loyalty Club',
  'ge.guest_reporting.suggested_filters.loyalty_club.desc':
    'Find guests who are far along in your Beer Club, Lunch Club, etc.',
  'ge.guest_reporting.suggested_filters.birthdays': 'Birthdays',
  'ge.guest_reporting.suggested_filters.birthdays.desc':
    'Identify guests with previous or upcoming birthdays',
  'ge.guest_reporting.suggested_filters.new_guests': 'New Guests',
  'ge.guest_reporting.suggested_filters.new_guests.desc':
    'Anyone whose account was recently activated',
  'ge.guest_reporting.suggested_filters.we_love_you': 'We Love You',
  'ge.guest_reporting.suggested_filters.we_love_you.desc':
    'Identify guests who have visited frequently or have spent a lot recently',
  'ge.guest_reporting.suggested_filters.we_miss_you': 'We Miss You',
  'ge.guest_reporting.suggested_filters.we_miss_you.desc':
    'Identify big spenders that have not visited recently',
  'ge.guest_reporting.opt_out_marketing_email_dialog_title':
    'Are you sure you want to remove this guest from future email marketing?',
  'ge.guest_reporting.opt_out_marketing_email_dialog_message':
    'This action cannot be undone in TouchBistro Cloud. Only the guest will be able to re-consent to receive email marketing communications in the future.',
  'ge.guest_reporting.opt_out_marketing_email_success': 'User opted out of marketing email.',
  'ge.guest_reporting.opt_out_marketing_email_failure':
    'There was an error while trying to opt out of marketing email.',
  'ge.guest_feedback.item_placeholder': 'Search item names',
  'ge.guest_feedback.item_name': 'Item Name',
  'ge.guest_feedback.item_avg_rating': 'Average Rating',
  'ge.guest_feedback.item_num_rating': '# of Ratings',
  'ge.guest_feedback.no_item_results_found': 'There is no item feedback to display.',
  'ge.guest_feedback.positive': 'positive',
  'ge.guest_feedback.positive_ratings': 'Positive Ratings',
  'ge.guest_feedback.negative_ratings': 'Negative Ratings',
  'ge.guest_feedback.high_to_low_avg_rating': 'High to low Average Rating',
  'ge.guest_feedback.low_to_high_avg_rating': 'Low to high Average Rating',
  'ge.guest_feedback.no_ratings': 'No Ratings',
  'ge.automated_marketing': 'Automated Marketing',
  'ge.automated_marketing.automation_name': 'Automation Name',
  'ge.automated_marketing.automation_title':
    'Automated Marketing for "{{customFilterName}}" Filter',
  'ge.automated_marketing.automation_create_new': 'Create New Automation',
  'ge.automated_marketing.no_results_found': 'There are no Automations to be displayed.',
  'ge.automated_marketing.limit': 'You have reached the 10 milestone limit.',
  'ge.automated_marketing.write_a_message': 'Write a Message',
  'ge.customer_web_app': 'Customer Web App',
  'ge.customer_web_app.home_screen_buttons': 'Home Screen Buttons',
  'ge.customer_web_app.home_screen_design': 'Home Screen Design',
  'ge.customer_web_app.general_screen_design': 'General Screen Design',
  'ge.customer_web_app.app_details': 'App Details',
  'ge.customer_web_app.locations': 'Locations',
  'ge.customer_web_app.save_changes': 'Save',
  'ge.customer_web_app.view_web_app': 'View web app',
  'ge.customer_web_app.short_name': 'Short name',
  'ge.customer_web_app.short_name_placeholder': 'Enter a short name',
  'ge.customer_web_app.help_faq_link': 'Help FAQ link',
  'ge.customer_web_app.help_faq_link_placeholder': 'Enter a link',
  'ge.customer_web_app.help_faq_link_description':
    "Add a link to your website with any additional information about your app. This link will appear on the 'Help' screen of your web app.",
  'ge.customer_web_app.help_email': 'Help email',
  'ge.customer_web_app.help_email_placeholder': 'Enter a help email',
  'ge.customer_web_app.help_email_description':
    "When guests need help with your app, they can send questions to this email address for your restaurant. This appears on the 'Help' screen.",
  'ge.customer_web_app.loyalty_faq_link': 'Loyalty FAQ link',
  'ge.customer_web_app.loyalty_faq_link_placeholder': 'Enter a loyalty FAQ link',
  'ge.customer_web_app.loyalty_faq_link_description':
    'Add a link to your website with the FAQs of your loyalty program. This link will appear on the Rewards screen.',
  'ge.customer_web_app.app_text': 'Text',
  'ge.customer_web_app.app_link_text': 'Links & Emails',
  'ge.customer_web_app.app_icons': 'Icons',
  'ge.customer_web_app.pixel_icon': '{{pixel}}x{{pixel}} pixel icon',
  'ge.customer_web_app.email_validation_message': 'Please enter a valid email',
  'ge.customer_web_app.url_validation_message': 'Please enter a valid link',
  'ge.cwa_image_picker.preview_text': 'Select preview to view full size',
  'ge.cwa_image_picker.drop_text': 'Drag and drop image or browse files',
  'ge.automated_marketing.create_new_automation': 'Create New Automation',
  'ge.automated_marketing.edit_automation': 'Edit Automation',
  'ge.automated_marketing.select_a_saved_filter': 'Select a Saved Filter',
  'ge.automated_marketing.saved_filter': 'CRM Saved Filter',
  'ge.automated_marketing.promotion': 'Promotion',
  'ge.automated_marketing.set_as_active': 'Set as Active',
  'ge.automated_marketing.create_a_new_saved_filter': 'Create a New Saved Filter',
  'ge.automated_marketing.create_a_new_saved_filter.create_saved_filter_modal.heading':
    'You will be redirected to CRM',
  'ge.automated_marketing.edited_automation_saved':
    'Automation "{{automation}}" successfully saved',
  'ge.automated_marketing.created_automation_saved':
    'Automation "{{automation}}" successfully added',
  'ge.automated_marketing.select_a_promotion': 'Select a Promotion',
  'ge.automated_marketing.create_a_new_promotion': 'Create a new Promotion',
  'ge.automated_marketing.create_a_new_promotion.create_promotion_modal.heading':
    'You will be redirected to Promotions',
  'ge.automated_marketing.in_app_message': 'In-App Message',
  'ge.automated_marketing.create_in_app_message': 'Create In-App Message',
  'ge.automated_marketing.edit_message': 'Edit Message',
  'ge.automated_marketing.remove_message': 'Remove Message',
  'ge.automated_marketing.email': 'Email',
  'ge.automated_marketing.select_email_template': 'Select Email Template',
  'ge.automated_marketing.edit_email': 'Edit Email',
  'ge.automated_marketing.remove_email': 'Remove Email',
  'ge.automated_marketing.template_name': 'Template Name',
  'ge.automated_marketing.schedule': 'Schedule',
  'ge.automated_marketing.add_schedule': 'Add Schedule',
  'ge.automated_marketing.add_new': 'Add New',
  'ge.automated_marketing.add_new_schedule': 'Add New Schedule',
  'ge.automated_marketing.intervals': 'Intervals',
  'ge.automated_marketing.week_day': 'Week Day',
  'ge.automated_marketing.choose_a_week_day': 'Select a week day',
  'ge.automated_marketing.starting_date': 'Starting Date',
  'ge.automated_marketing.day_of_month': 'Day of Month',
  'ge.automated_marketing.choose_a_month': 'Select a month',
  'ge.automated_marketing.occurrence': 'Occurrence',
  'ge.automated_marketing.day_29':
    'NOTE: This automation will not run in February, except leap years',
  'ge.automated_marketing.day_30': 'NOTE: This automation will not run in February',
  'ge.automated_marketing.day_31':
    'NOTE: This automation will not run in February, April, June, September, and November',
  'ge.customer_web_app.top_image_header': 'Top Image',
  'ge.customer_web_app.top_image_info':
    'Images will be cropped to 960x714px. Supported file types are .png .jpg .jpeg.',
  'ge.customer_web_app.background_header': 'Background',
  'ge.customer_web_app.background_info':
    'Images will be cropped to 640x1600px. Supported file types are .png .jpg .jpeg.',
  'ge.customer_web_app.button_titles_header': 'Button Titles',
  'ge.customer_web_app.button_descriptions_header': 'Button Descriptions',
  'ge.customer_web_app.desktop_gradient_header': 'Desktop Gradient',
  'ge.customer_web_app.reset_section_modal_title': 'Are you sure you want to reset?',
  'ge.customer_web_app.reset_section_modal_body':
    'This will reset any changes in the {{section}} section back to its original settings.',
  'ge.customer_web_app.color_picker_tip': 'Click below to add a new color',
  'ge.customer_web_app.color_delete_modal_title': 'Are you sure you want to delete this color?',
  'ge.customer_web_app.color_delete_modal_body':
    'This color will be removed from any places that it is currently applied',
  'ge.customer_web_app.save_config_success': 'Configuration saved successfully',
  'ge.customer_web_app.top_image_alt': 'Image preview for the Top Image',
  'ge.customer_web_app.background_image_alt': 'Image preview for the Background Image',
  'ge.cwa_font_picker.drop_text': 'Drag and drop font or browse files',
  'ge.cwa_select_saved.imageurl': 'Select a saved image',
  'ge.cwa_select_saved.fonturl': 'Select a saved font',
  'ge.cwa_select_saved.imageurl_title': 'Saved Images',
  'ge.cwa_select_saved.imageurl_not_available': 'No saved images available',
  'ge.cwa_select_saved.fonturl_title': 'Saved Fonts',
  'ge.cwa_select_saved.fonturl_not_available': 'No saved fonts available',
  'ge.cwa_assetupload.imageurl_success': 'Image successfully uploaded',
  'ge.cwa_assetupload.fonturl_success': 'Font successfully uploaded',
  'ge.cwa_font_picker.sample_text': 'Sample Text',
  'ge.cwa_select_saved.imageurl_confirm_delete_title':
    'Are you sure you want to delete this image?',
  'ge.cwa_select_saved.imageurl_confirm_delete_body':
    'This image will be removed from any places that it is currently applied',
  'ge.cwa_select_saved.fonturl_confirm_delete_title': 'Are you sure you want to delete this font?',
  'ge.cwa_select_saved.fonturl_confirm_delete_body':
    'This font will be removed from any places that it is currently applied',
  'ge.customer_web_app.highlight_text_header': 'Highlight Text',
  'ge.customer_web_app.regular_text_header': 'Regular Text',
  'ge.customer_web_app.icon_button_bg_header': 'Icon & Button Background',
  'ge.customer_web_app.button_text_header': 'Button Text',
  'ge.day-0': 'Sunday',
  'ge.day-1': 'Monday',
  'ge.day-2': 'Tuesday',
  'ge.day-3': 'Wednesday',
  'ge.day-4': 'Thursday',
  'ge.day-5': 'Friday',
  'ge.day-6': 'Saturday',
  'ge.month-0': 'January',
  'ge.month-1': 'February',
  'ge.month-2': 'March',
  'ge.month-3': 'April',
  'ge.month-4': 'May',
  'ge.month-5': 'June',
  'ge.month-6': 'July',
  'ge.month-7': 'August',
  'ge.month-8': 'September',
  'ge.month-9': 'October',
  'ge.month-10': 'November',
  'ge.month-11': 'December',
  'ge.automated_marketing.weekly_every': 'Weekly every {{day}}',
  'ge.automated_marketing.monthly': 'Monthly on the {{date}}',
  'ge.automated_marketing.yearly': 'Yearly on the {{day}} of {{month}}',
  'ge.automated_marketing.starting': ' starting on {{date}}',
  'ge.automated_marketing.once': 'Once on {{date}}',
  'ge.automated_marketing.interval-DAYOFWEEK': 'Weekly',
  'ge.automated_marketing.interval-DAYOFMONTH': 'Monthly',
  'ge.automated_marketing.interval-DAYOFYEAR': 'Yearly',
  'ge.automated_marketing.interval-OCCURRENCE': 'Once',
  'ge.customer_web_app.instructions':
    'Select the pages you want visible on your app. Drag and drop to rearrange the order of the buttons.',
  'ge.customer_web_app.add_button': 'Add a Custom button',
  'ge.customer_web_app.custom_name_placeholder': 'Enter a custom title',
  'ge.customer_web_app.custom_description_placeholder': 'Enter a custom description (Optional)',
  'ge.customer_web_app.custom_url_placeholder': 'Enter a custom link',
  'ge.customer_web_app.confirm_delete': 'Are you sure you want to delete this button?',
  'ge.customer_web_app.button_name': 'Button name',
  'ge.customer_web_app.button_description': 'Button description',
  'ge.customer_web_app.enter_a_name': 'Please enter a name.',
  'ge.customer_web_app.enter_a_url': 'Please enter a valid link.',
  'ge.customer_web_app.enter_a_valid_phone_number': 'Please enter a valid phone number.',
  'ge.customer_web_app.rewards_tooltip': 'Linked to: Rewards page in app',
  'ge.customer_web_app.feedback_tooltip': 'Linked to: Feedback page in app',
  'ge.customer_web_app.giftcards_tooltip': 'Linked to: Purchase and redeem gift cards in app',
  'ge.customer_web_app.rewards_default_name': 'Rewards',
  'ge.customer_web_app.rewards_default_description': 'Earn points and redeem rewards',
  'ge.customer_web_app.feedback_default_name': 'Feedback',
  'ge.customer_web_app.feedback_default_description': 'Tell us how we did',
  'ge.customer_web_app.giftcards_default_name': 'Gift Cards',
  'ge.customer_web_app.giftcards_default_description': 'Purchase and redeem gift cards',
  'ge.customer_web_app.locations_default_name': 'Locations',
  'ge.customer_web_app.locations_default_description': 'View locations information',
  'ge.customer_web_app.giftcards_redirect': 'A TouchBistro Payments venue has not been configured.',
  'ge.customer_web_app.giftcards_redirect_reason': `Guests can view their Gift Cards in the app, but will not be able to make Gift Card purchases in the app. You can connect a TouchBistro Payments venue in the Gift Cards section.`,
  'ge.customer_web_app.redirect': `Continue to Gift Cards section`,
  'ge.customer_web_app.add': `Add Gift Cards Button anyway`,
  'ge.customer_web_app.button_delete_toast': 'Custom Button deleted successfully',
  'ge.customer_web_app.locations.venue_name': 'Name',
  'ge.customer_web_app.locations.address1': 'Address Line 1',
  'ge.customer_web_app.locations.address2': 'Address Line 2',
  'ge.customer_web_app.locations.city_name': 'City',
  'ge.customer_web_app.locations.state': 'State / Province',
  'ge.customer_web_app.locations.zipcode': 'Zip  / Postal Code',
  'ge.customer_web_app.locations.phone_number': 'Phone Number',
  'ge.customer_web_app.locations.website_url': 'Website link',
  'ge.customer_web_app.locations.facebook_url': 'Facebook link',
  'ge.customer_web_app.locations.instagram_url': 'Instagram link',
  'ge.customer_web_app.locations.twitter_url': 'Twitter link',
  'ge.customer_web_app.locations.location': 'Location',
  'ge.customer_web_app.locations.menus': 'Menus',
  'ge.customer_web_app.locations.add_menu_table_btn': 'Add menu link',
  'ge.customer_web_app.locations.menu_name': 'Name',
  'ge.customer_web_app.locations.menu_url': 'Link',
  'ge.customer_web_app.locations.add_menu_submit_btn': 'Add Menu',
  'ge.customer_web_app.locations.no_venues': 'No Venues Available',
  'ge.customer_web_app.locations.new_menu_modal_header': 'Add New Menu',
  'ge.customer_web_app.locations.state_error_message':
    'Must look like ON, NY, or other 2 letter state/province code',
  'ge.customer_web_app.locations.delete_menu_confirmation':
    'Are you sure you want to delete this menu?',
  'ge.customer_web_app.locations.locations_button_disabled_warning':
    'The Locations button is currently disabled on your Customer Web App. Enable it on the Buttons tab so your diners can see your Locations information.',
  'ge.customer_web_app.save_error_message': 'Please address any issues prior to saving.',
  'ge.customer_web_app.locations.locations_button_disabled_warning_new':
    'The Locations button is currently disabled on your Customer Web App. Enable it on the <1>Home Screen Buttons</1> tab so diners can see your Locations information.',
  'ge.customer_web_app.locations.locations_button_info':
    'Heads up! The Customer Web App automatically pulls your venue details from the <1>Venue Details</1> page.',
  'ge.loyalty_programs': 'Loyalty Programs',
  'ge.loyalty_programs.name': 'Program Name',
  'ge.loyalty_programs.points_per_dollar': 'Points per Dollar',
  'ge.loyalty_programs.description': 'Description',
  'ge.loyalty_programs.description_placeholder': 'Enter a description',
  'ge.loyalty_programs.apply_to_all_venues': 'Apply this Program to ALL your Venues',
  'ge.loyalty_programs.schedule': 'Schedule',
  'ge.loyalty_programs.spend_based': 'Spend-Based Loyalty Programs',
  'ge.loyalty_programs.spend_based_empty':
    'You currently have no Spend-Based Loyalty Programs to be displayed.',
  'ge.loyalty_programs.add_remove_points': 'Add/Remove Points',
  'ge.loyalty_programs.item_based': 'Item-Based Loyalty Programs',
  'ge.loyalty_programs.item_based_empty':
    'You currently have no Item-Based Loyalty Programs to be displayed.',
  'ge.loyalty_programs.add_remove_claimed_items': 'Add/Remove Claimed Items',
  'ge.loyalty_programs.page_title_create': 'Create New Program',
  'ge.loyalty_programs.page_title_edit': 'Edit Loyalty Program',
  'ge.loyalty_programs.step_select_program_type': 'Select Program Type',
  'ge.loyalty_programs.step_program_details': 'Program Details',
  'ge.loyalty_programs.program_type_spend_name': 'Spend-Based',
  'ge.loyalty_programs.program_type_item_name': 'Item-Based',
  'ge.loyalty_programs.program_type_spend_description':
    'Guests can accrue Points based on how much they spend. Points can be redeemed for Rewards.',
  'ge.loyalty_programs.program_type_item_description':
    'Guests make progress when they purchase specific Menu Items. They can redeem Rewards when they hit Milestones.',
  'ge.loyalty_programs.save_success': 'Program created successfully',
  'ge.loyalty_programs.edit_success': 'Program edited successfully',
  'ge.loyalty_programs.individual_item_claim_limit':
    'Number of Times Each Individual Item Can Be Claimed',
  'ge.loyalty_programs.daily_claim_limit': 'Claim Limit Per Day for ALL Items',
  'ge.loyalty_programs.reward_milestones': 'Reward Milestones',
  'ge.loyalty_programs.number_of_claimed_items': 'Number of Claimed Item(s)',
  'ge.loyalty_programs.items': '{{items}} item(s)',
  'ge.loyalty_programs.menu.select_reward_milestone':
    'At least 1 Reward Milestone needs to be added.',
  'ge.loyalty_programs.menu.sub_label': 'Apply Menu Groups and/or Menu Items to the Program.',
  'ge.loyalty_programs.search_user': 'Search for a guest by email or phone number:',
  'ge.loyalty_programs.add_remove_points_modal.comment_placeholder':
    'Why are you editing the points balance?',
  'ge.loyalty_programs.add_remove_points_modal.current_balance': 'Current Points Balance:',
  'ge.loyalty_programs.add_remove_points_modal.new_balance': 'New Points Balance:',
  'ge.invalid_email_phone': 'Invalid email address or phone number',
  'ge.loyalty_programs.add_remove_points_modal.add_successfully':
    'Points successfully added to {{name}}. New balance: {{points}} Points.',
  'ge.loyalty_programs.add_remove_points_modal.remove_successfully':
    'Points successfully removed from {{name}}. New balance: {{points}} Points.',
  'ge.provisioning': 'Guest Engagement Provisioning',
  'ge.provisioning.group_details': 'Group Details',
  'ge.provisioning.ge_group': 'Guest Engagement Group',
  'ge.provisioning.ge_groups': 'Guest Engagement Groups',
  'ge.provisioning.ge_group_products': 'Guest Engagement Products',
  'ge.provisioning.corporation_unavailable': 'Corporation details unavailable',
  'ge.provisioning.ge_enabled': 'Guest Engagement Enabled',
  'ge.provisioning.no_venues': 'There are no Venues to Display',
  'ge.provisioning.loyaltyEnabled': 'Loyalty',
  'ge.provisioning.marketingEnabled': 'Marketing',
  'ge.provisioning.giftCardsEnabled': 'Digital Gift Cards',
  'ge.provisioning.save_success': 'Provisioning data updated successfully',
  'ge.provisioning.sync_success': 'Guest Engagement Enabled Successfully',
  'ge.provisioning.no_venues_message': 'No Venues',
  'ge.provisioning.drag_and_drop_venues':
    'Drag and drop venues here to provision their Guest Engagement products',
  'ge.provisioning.unassignedVenues': 'Unassigned Venues',
  'ge.provisioning.add_venue_notice_1':
    'Please note the following before adding "{{venueName}}" to the "{{groupName}}" Guest Engagement group:',
  'ge.provisioning.add_venue_notice_1_1':
    'Any existing Rewards, Promotions, Marketing Automations, Saved Filters, and Loyalty Programs set for “ALL LOCATIONS” in "{{groupName}}" will not be valid at "{{venueName}}" automatically. These items must be updated manually to include "{{venueName}}".',
  'ge.provisioning.add_venue_notice_1_2':
    'All existing Digital Gift Cards at "{{groupName}}" will automatically be valid at "{{venueName}}".',
  'ge.provisioning.add_venue_notice_2':
    'If "{{venueName}}" was previously a member of a different Guest Engagement group:',
  'ge.provisioning.add_venue_notice_2_1':
    'The CRM order data (shown in the CRM table) and Guest Feedback data generated through this venue will become visible to "{{groupName}}"',
  'ge.provisioning.add_venue_notice_2_2':
    'The diner’s activity feed data (visible in the CRM and CWA) will remain visible in the prior group and will NOT be moved to "{{groupName}}"',
  'ge.provisioning.remove_venue_notice_1':
    'Please note the following before removing "{{venueName}}" from the "{{groupName}}" Guest Engagement group:',
  'ge.provisioning.remove_venue_notice_1_1':
    'Diners will no longer be able to use any of their existing Rewards, Promotions or Loyalty Programs at "{{venueName}}". These items will remain valid and can be used at other venues within the "{{groupName}}" Guest Engagement group, assuming the items are configured for usage at the other venues.',
  'ge.provisioning.remove_venue_notice_1_2':
    'Existing Digital Gift Cards will no longer be valid at "{{venueName}}", but will remain valid at all other venues within the "{{groupName}}" Guest Engagement group. The Digital Gift Card usage report will continue to show data from "{{venueName}}".',
  'ge.provisioning.remove_venue_notice_1_3':
    'The CRM order data (shown in the CRM table) and Guest Feedback data created by "{{venueName}}" will be removed.',
  'ge.provisioning.remove_venue_notice_1_4':
    'The diner’s activity feed data (visible in the CRM and CWA) will remain visible in "{{groupName}}".',
  'ge.provisioning.remove_venue_notice_1_5':
    'If this venue is the TB Payments destination for online DGC purchases for {{groupName}}, then it will be removed and a new venue will need to be selected.',
  'ge.provisioning.add_new_group_confirmation_title':
    'Are you sure you would like to create a new group?',
  'ge.provisioning.add_new_group_confirmation_body':
    'Once a Guest Engagement group has been created, it cannot be deleted.',
  'ge.provisioning.not_available_message':
    'Guest Engagement provisioning is not available for this venue.',
  'ge.provisioning.not_available_description':
    'The venue and group relationships for this customer are not in-sync. Once the outreach for this customer has been completed, please return to this page to provision their Guest Engagement functionality.',
  'ge.loyalty_programs.edit_claims.page_title': 'Add/Remove Claimed Items',
  'ge.loyalty_programs.edit_claims.program_name': 'Program Name',
  'ge.loyalty_programs.edit_claims.total_claimed': 'Total Claimed Items',
  'ge.loyalty_programs.edit_claims.num_claimed': '{{claims}} Claimed Items',
  'ge.loyalty_programs.edit_claims.search_menu': 'Search for a specific Menu Group/Item:',
  'ge.loyalty_programs.edit_claims.claimed': 'Claimed',
  'ge.loyalty_programs.edit_claims.success': 'Successfully modified claims for {{name}}',
  'ge.loyalty_programs.edit_claims.load_more': 'Load More',
  'ge.loyalty_programs.edit_claims.confirm_user_change.body':
    'These changes will not be saved.\nAre you sure you want to search for another guest?',
  'ge.loyalty_programs.search.no_results':
    "No user was found. You must enter an EXACT match for the guest's email address or phone number.",
  'ge.tu_redirect_page.title':
    'The Loyalty Portal (production.tableup.com) has been decommissioned.',
  'ge.tu_redirect_page.subtitle':
    'You can now manage <1>TouchBistro Loyalty</1>, <1>Marketing</1>, and <1>Digital Gift Cards</1> in <1>TouchBistro Cloud</1>.\nAfter logging into Cloud, look for the <1>"Guest Engagement"</1> section in the left navigation bar.',
  'ge.tu_redirect_page.login_btn': 'Login to Cloud',
  'ge.tu_redirect_page.desc':
    'If you do not have a Cloud login, contact your Venue\'s Administrator and request an invitation.\nIf you are logged in to Cloud but cannot see the "Guest Engagement" tab, please contact us to discuss necessary changes to your account.',
  'ge.tu_redirect_page.phone':
    '<1>By Phone:</1>\nCall <2>1-888-342-0131</2> and ask to be escalated to the Value Added Services team.',
  'ge.tu_redirect_page.email':
    '<1>By Email:\n</1><2>valueaddedservices@touchbistro.com</2> and please include your Venue name and ID.',
  'ge.tu_redirect_page.helpful': 'Helpful Resources',
  'ge.tu_redirect_page.helpful_links.1': 'Using Guest Engagement in TouchBistro Cloud',
  'ge.tu_redirect_page.helpful_links.2':
    'Creating accounts for my staff to access TouchBistro Cloud',
  'ge.tu_redirect_page.helpful_links.3': 'How to Reset your TouchBistro Cloud password',
  'ge.dgc': 'Digital Gift Cards',
  'ge.dgc.connection_instructions_header': 'Connect TouchBistro Payments Venue',
  'ge.dgc.connection_instructions':
    'TouchBistro Payments is required for Gift Card purchases in the Customer Web App. Choose a venue to route your web app gift card purchases to.',
  'ge.dgc.venue': 'Venue',
  'ge.dgc.success_toast': 'TouchBistro Payments Venue connected successfully',
  'ge.dgc.select_venue': 'Select a venue',
  'ge.dgc.no_tb_payments_venue': 'No venue with TouchBistro Payments available.',
  'ge.dgc.total_sold': 'Total Gift Cards Sold',
  'ge.dgc.active': 'Active Gift Cards',
  'ge.dgc.search': 'Search by Reference ID or name or email address',
  'ge.dgc.connect_tb_payments': 'Connect TouchBistro Payments Venue',
  'ge.dgc.tb_payments_connected': 'Payments Connected to venue {{venue}}',
  'ge.dgc.referenceID': 'Reference ID',
  'ge.dgc.recipientName': 'Recipient Name',
  'ge.dgc.recipientEmail': 'Recipient Email',
  'ge.dgc.purchaserName': 'Purchaser Name',
  'ge.dgc.purchaserEmail': 'Purchaser Email',
  'ge.dgc.startingBalance': 'Starting Balance',
  'ge.dgc.currentBalance': 'Current Balance',
  'ge.dgc.purchaseDate': 'Purchase Date',
  'ge.dgc.deliveryDate': 'Delivery Date',
  'ge.dgc.no_gift_cards': 'You currently have no Digital Gift Cards issued to diners.',
  'ge.dgc.no_matching_result':
    'No matching results to display. Please try a different Reference ID or name or email address',
  'ge.dgc.details': 'Digital Gift Card Details',
  'ge.dgc.recipient_info': 'Recipient Info',
  'ge.dgc.purchaser_info': 'Purchaser Info',
  'ge.dgc.no_gift_card_activity_feed': 'No Gift Card Activity',
  'ge.dgc.delivery_date_updated': 'Delivery Date updated successfully',
  'ge.dgc.transfer_card_modal_title': 'Recover an Inaccessible Gift Card',
  'ge.dgc.transfer_card_modal_subtitle':
    'You can recover an inaccessible and unused gift card by editing the email address associated to the DGC. Once the email is changed, the prior email address will not be able to retrieve or access this gift card for purchases.',
  'ge.dgc.current_owner_email': 'Current Recipient Email',
  'ge.dgc.new_recipient_email': 'New Recipient Email',
  'ge.dgc.resend_email_btn': 'Resend Email',
  'ge.dgc.resend': 'Resend',
  'ge.dgc.resend_email_title': 'Are you sure you want to resend this gift card to {{email}}?',
  'ge.dgc.resend_email_notice': 'The email will be resent immediately and cannot be undone.',
  'ge.dgc.resend_email_status': 'Email successfully resent!',
  'ge.dgc.resend_email_successfully':
    'You have successfully resent the gift card email to {{email}}.',
  'ge.dgc.new_recipient_firstname_placeholder': 'Enter a first name',
  'ge.dgc.new_recipient_lastname_placeholder': 'Enter a last name',
  'ge.dgc.new_recipient_email_placeholder': 'Enter an email',
  'ge.dgc.recipient_transferred_successfully': 'Gift Card recipient changed successfully',
  'ge.dgc.recipient_transferred_successfully_content_1':
    'You have successfully changed the gift card recipient to {{firstName}} {{lastName}} {{email}}. The current balance is {{balance}}',
  'ge.dgc.recipient_transferred_successfully_content_2':
    '{{firstName}} {{lastName}} {{email}} can no longer use this gift card.',
  'ge.dgc.activity.GIFTCARDPURCHASE': 'Gift Card Purchased',
  'ge.dgc.activity.GIFTCARDREDEEM': 'Gift Card Redeemed',
  'ge.dgc.activity.GIFTCARDREFUND': 'Order Credit Applied',
  'ge.dgc.activity.GIFTCARDROLLBACK': 'Gift Card Transaction Cancelled',
  'ge.dgc.activity.GIFTCARDDELIVERYDATECHANGED': 'Delivery Date Changed',
  'ge.dgc.activity.GIFTCARDRECIPIENTTRANSFERRED': 'Gift Card Recipient Changed',
  'ge.dgc.activity.GIFTCARDEMAILRESENT': 'Gift Card Email Resent',
  'ge.dgc.activity.source': 'Source',
  'ge.dgc.activity.amount': 'Amount: ${{amount}}',
  'ge.dgc.activity.redeemed_amount': 'Redeemed Amount: ${{amount}}',
  'ge.dgc.activity.new_balance': 'New Balance: ${{amount}}',
  'ge.dgc.activity.credit_amount': 'Credit Amount: ${{amount}}',
  'ge.dgc.activity.transaction_amount': 'Transaction Amount: ${{amount}}',
  'ge.dgc.activity.prior_delivery_date': 'Prior Delivery Date: {{date}}',
  'ge.dgc.activity.new_delivery_date': 'New Delivery Date: {{date}}',
  'ge.dgc.activity.transferred_from': 'From: {{user}}',
  'ge.dgc.activity.transferred_to': 'To: {{user}}',
  'ge.dgc.activity.balance_transferred': 'Balance: ${{amount}}',
  'ge.dgc.activity.resent_to': 'Resent To: {{user}}',
  'ge.dgc.list': 'Gift Card List',
  'ge.dgc.settings': 'Settings',
  'ge.dgc.tb_payment': 'TouchBistro Payments',
  'ge.dgc.tb_payment_note':
    '\nNOTE: If you also use TouchBistro Online Ordering, connecting a Payments venue will immediately add a banner to your menu page to promote the availability of your Digital Gift Cards and allow guests to start purchasing them.',
  'ge.dgc.limits': 'Sales Limits for Online Sales',
  'ge.dgc.limits.desc': 'Apply limits to Digital Gift Cards sold online.',
  'ge.dgc.limits.max_dollar': 'Max dollar amount that can be added on a single card',
  'ge.dgc.limits.by_single_guest':
    'Max # of Digital Gift Cards that can be purchased by a single guest',
  'ge.dgc.limits.by_single_guest.desc':
    'NOTE: If one of these maximums is reached, the guest will be prompted with a generic error message that refers to "Error code: 100"',
  'ge.dgc.limits.with_24_hour_period': 'within a 24 hour period',
  'ge.dgc.limits.with_7_day_period': 'within a 7 day period',
  'ge.dgc.limits.updated_message': 'Digital Gift Cards settings updated successfully',
  'ge.dgc.limits.error_message':
    'The 7 day limit must be equal to or greater than the 24 hour limit',
  'ge.provisioning.section_back_button': 'Back',
  'ge.provisioning.section_warning_message': 'Moving Venues to {{target_group_name}}',
  'ge.privisioning.section_info_message':
    'Please note the following before moving venue(s) to this group:',
  'ge.provisioning.section_title_staff': 'Staff',
  'ge.provisioning.section_title_menu': 'Menu',
  'ge.provisioning.section_title_tbloyalty': 'TouchBistro Loyalty',
  'ge.provisioning.section_title_tbmarketing': 'TouchBistro Marketing',
  'ge.provisioning.section_title_tbdge': 'TouchBistro Digital Gift Cards',
  'ge.provisioning.section_title_reservation': 'Reservations',
  'ge.provisioning.section_staff_item_1':
    'Prior to moving your venue, perform EOD and ensure all users are clocked out. Failure to do so will result in prohibited users having access to the venue(s).',
  'ge.provisioning.section_staff_item_2':
    'Staff that had permission to the venue may lose access unless they have access to {{destinationGroupName}}',
  'ge.provisioning.section_staff_item_3':
    'Staff that had permissions to {{destinationGroupName}} will automatically have access to the venue after the venue has been moved',
  'ge.provisioning.section_menu_item_1':
    'Any Menu Management records that were created by the {{originalGroupName}} and inherited by this venue will not be carried over',
  'ge.provisioning.section_menu_item_2':
    'Any Menu Management records that were created at the venue will be carried over',
  'ge.provisioning.section_menu_item_3':
    'Failure to re-assign printers to new prep stations will result in the printers not working for the venue(s)',
  'ge.provisioning.section_tbloyalty_desc':
    'The following applies only if the venue being moved is already entitled for TouchBistro Loyalty.',
  'ge.provisioning.section_tbloyalty_desc_1': 'If the destination group already has TB Loyalty:',
  'ge.provisioning.section_tbloyalty_item_1':
    'The existing Rewards, CRM Saved Filters, and Loyalty Programs set for “ALL LOCATIONS” in the destination group will NOT automatically become valid at the newly assigned venue(s). These items must be manually updated to include the newly assigned venue(s).',
  'ge.provisioning.section_tbloyalty_desc_2':
    'If the venue is moving from a group with TB Loyalty:',
  'ge.provisioning.section_tbloyalty_item_2':
    'The CRM order data from the newly assigned venues will be removed from their prior group(s) and become visible to the destination group',
  'ge.provisioning.section_tbloyalty_item_3':
    'Existing Rewards and Loyalty progress (points and/or items purchased) earned by guests at the prior group will no longer be valid at the newly assigned venue, but will remain valid and can be used at other venues in the prior group if they are configured for those venues.',
  'ge.provisioning.section_tbloyalty_item_4':
    'The guest’s activity feed data (visible in the CRM and CWA) will remain visible in the prior group and will NOT be moved to the destination group. However, any historical bills belonging to the newly assigned venues will become inaccessible by the prior group.',
  'ge.provisioning.section_tbmarketing_desc':
    'The following applies only if the venue being moved is already entitled for TouchBistro Marketing.',
  'ge.provisioning.section_tbmarketing_desc_1':
    'If the destination group already has TB Marketing:',
  'ge.provisioning.section_tbmarketing_item_1':
    'The existing Rewards, Promotions, Marketing Automations, and CRM Saved Filters set for “ALL LOCATIONS” in the destination group will NOT automatically become valid at the newly assigned venue(s). These items must be updated manually to include the newly assigned venue(s).',
  'ge.provisioning.section_tbmarketing_desc_2':
    'If the venue is moving from a group with TB Marketing:',
  'ge.provisioning.section_tbmarketing_item_2':
    'The CRM order data and Guest Feedback data from the newly assigned venues will be removed from their prior group(s) and become visible to the destination group.',
  'ge.provisioning.section_tbmarketing_item_3':
    'Existing Rewards or Promotions earned by guests at the prior group will no longer be valid at the newly assigned venue, but will remain valid and can be used at other venues within the prior group(s) if they are configured for those venue(s).',
  'ge.provisioning.section_tbmarketing_item_4':
    'The guest’s activity feed data (visible in the CRM and CWA) will remain visible in the prior group and will NOT be moved to the destination group. However, any historical bills belonging to the newly assigned venues will become inaccessible by the prior group.',
  'ge.provisioning.section_tbdge_desc':
    'The following applies only if the venue being moved is already entitled for TouchBistro Digital Gift Cards.',
  'ge.provisioning.section_tbdge_desc_1':
    'If the destination group already has TB Digital Gift Cards:',
  'ge.provisioning.section_tbdge_item_1':
    'All existing Digital Gift Cards at the destination group will automatically be valid at the newly assigned venue(s).',
  'ge.provisioning.section_tbdge_desc_2':
    'If the venue is moving from a group with TouchBistro Digital Gift Cards:',
  'ge.provisioning.section_tbdge_item_2':
    'Digital Gift Cards previously purchased at the newly assigned venue(s) will not be valid at the destination group, but will remain valid at other venues within the prior group(s).',
  'ge.provisioning.section_tbdge_item_3':
    'The Digital Gift Card usage report for the prior group(s) will continue to show data from the removed venue(s).',
  'ge.provisioning.section_tbdge_item_4':
    'The guest’s activity feed data (visible in the CRM and CWA) will remain visible in the prior group and will NOT be moved to the destination group.',
  'ge.provisioning.section_reservation_desc':
    'The following applies only if the venue being moved is already entitled for TouchBistro Reservations in Cloud.',
  'ge.provisioning.section_reservation_desc_1':
    'If the destination group already has TB Reservations in Cloud, please note:',
  'ge.provisioning.section_reservation_item_1':
    'The venue will inherit group level settings from the destination group, which may result in a configuration change for the venue.',
  'ge.provisioning.section_reservation_item_2':
    'Group level booking widgets for the destination group will not automatically include the new venue.',
  'ge.provisioning.unassigned': 'Unassigned',
}

export default guestEngagementEN

import { Policies } from '~/src/shared/helpers/permissions'
import defaultLanguage from '~/src/shared/locales/en'

export interface Policy {
  policyXRefID: string
  name: string
  resource: string
  operation: string
}

export type PoliciesForAccessibleBases = {
  [key in Policies]?: string[]
}

export interface Base {
  name: string
  baseXRefID: string
  country: string
  state: string
  city: string
  address: string
  postalCode: string
  type: string
  entitlements: string[]
}

export interface UserRoleOnBase {
  userXRefID: string
  roleXRefID: string
  roleName: string
  roleDescription: string | null
  roleTranslationCode: string | null
  type: BaseType
  baseXRefID: string
  venueName: string | null
  groupName: string | null
  corpName: string | null
}

interface HierarchyRole {
  roleName: string
  roleDescription: string | null
  roleTranslationCode: string | null
  roleXRefID: string
}

export interface HierarchyChildWithRoles {
  baseID: string
  baseXRefID: string
  name: string
  type: BaseType
  roles: HierarchyRole[]
  children: Omit<HierarchyChildWithRoles, 'corpName' | 'corporationXRefID'>[]
  venueXRefIDsInSubHierarchy: string[]
  corpName: string
  corporationXRefID: string
  rolesDirectlyOnBase: HierarchyRole[]
  rolesInHierarchy: HierarchyRole[]
  permissionTypesInSubHierarchy: string[]
}

export interface Venue {
  venueXRefID: string
  dbaName: string | null
  salesforceXRefID: string | null
  phone: string | null
  email: string | null
  businessNumber: string | null
  timezone: string | null
  serviceTime: string
  currency: string | null
  startOfWeek: Weekday
  isTest: boolean
}

export interface Role {
  name: string
  roleXRefID: string
  default: boolean
  policies: Policy[]
}

export interface BaseRole {
  userXRefID: string
  baseXRefID: string
  roleXRefID: string
  roleName: string
  roleDescription: string | null
  roleTranslationCode: string | null
}

export interface VenueRole extends BaseRole {
  venueXRefID: string
  venueGroupXRefID: string
  venueGroupName: string
}

export enum SessionRole {
  NOT_FOUND = 'not_found',
  AGENT = 'agent',
  VENUE = 'venue',
}

// TODO: Do we need this???
export enum AddressTypes {
  TEMP_SHIPPING = 'TEMP_SHIPPING',
  LOCATION = 'LOCATION',
  SHIPPING = 'SHIPPING',
  LEGAL = 'LEGAL',
}

export enum SignupSource {
  MANAGE = 'MANAGE',
}

export enum BaseType {
  GROUP = 'GROUP',
  VENUE = 'VENUE',
  ROOT = 'ROOT',
}

export interface ServerErrorResponse {
  error?: {
    message: string
    code: string
  }
}
export interface UserProfileResponse {
  id: string // userXRefID
  firstName: string
  lastName: string
  email: string
  mobilePhone?: string
  username?: string
  additionalProperties: {
    isStaffMemberSignup?: boolean
    archived?: boolean
    upgradedUsername?: string
    isMultiLocationUpgrade?: boolean
    organization?: string
    venueXRefIds?: string[]
  }
}

type Translation = typeof defaultLanguage

export interface Translations {
  [key: string]: Translation
}

export interface Option {
  label: string | JSX.Element
  value: string
  id: string
  disabled?: boolean
  labelDescription?: string
}

export interface Pagination {
  limit?: number
  page?: number
  total?: number
  max?: number
}

export interface VenueIntegration {
  name: string
  description: string
  venueXRefID: string
  integrationXRefID: string
  serviceTime: string
  timeZone: string | null
  businessDateAtStart: boolean
  enabled: boolean
  config: unknown
}

export enum Weekday {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
}

export enum StatusCodes {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  FOUND = 302,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  NOT_ACCEPTABLE = 406,
  UNPROCESSABLE_ENTITY = 422,
  SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
}

export type POSWebviewMessage = {
  readonly title: string
  readonly data: JSONObject
}

type JSONObject = {
  [key: string]:
    | null
    | string
    | number
    | boolean
    | JSONObject
    | (null | string | number | boolean | JSONObject)[]
}

export interface POSWebViewProps {
  isPosWebview?: boolean
  children?: React.ReactNode
}

export type Domain =
  | 'agent'
  | 'devices'
  | 'floorplan'
  | 'guest-engagement'
  | 'marketplace'
  | 'menu-management'
  | 'menus'
  | 'ordering'
  | 'payments'
  | 'reports'
  | 'settings'
  | 'staff'
  | 'venue-info'
  | 'pos-linking'
  | 'signup'
  | 'reservations'
  | 'crm'

export interface SearchUserVenueResponse {
  venueXRefID: string
  name: string | null
  address: string | null
  signupSource: SignupSource
  salesforceXRefID: string | null
}

export interface HierarchyChild {
  name: string
  type: string
  baseID: string
  baseXRefID: string
  depth: number
  parentBaseID: string | null
  parentBaseXRefID: string | null
  children?: HierarchyChild[]
}

export enum VenuePaymentProvider {
  TB_CHASE = 'TB_CHASE',
  TB_FIS = 'TB_FIS',
}

export enum VenuePaymentGateway {
  INGENICO = 'INGENICO',
  WEPAY = 'WEPAY',
  PAYRIX = 'PAYRIX',
  WORLDPAY = 'WORLDPAY',
}

import React, { ReactNode, Component } from 'react'
import { datadogRum } from '@datadog/browser-rum'

interface ErrorBoundaryState {
  hasError: boolean
  error?: Error
  prevScope: string
}

type ErrorBoundaryProps = {
  fallback: ReactNode
  scope: string
  children?: React.ReactNode
}

/**
 * This code is adapted from https://github.com/DataDog/rum-react-integration-examples/blob/master/src/ErrorBoundary/ErrorBoundary.ts
 * @public
 */
export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps = {
    scope: 'error-boundary',
  }

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, prevScope: props.scope }
  }

  static getDerivedStateFromError(error: Error): Record<string, unknown> {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  static getDerivedStateFromProps(
    props: ErrorBoundaryProps,
    state: ErrorBoundaryState
  ): ErrorBoundaryState {
    if (state.prevScope !== props.scope) {
      return {
        hasError: false,
        error: undefined,
        prevScope: props.scope,
      }
    }

    return state
  }

  componentDidCatch(error: Error): void {
    datadogRum.addError(error, {
      scope: this.props.scope,
    })
  }

  render(): ReactNode {
    const { hasError, error } = this.state
    const { fallback } = this.props

    if (!hasError || !error) {
      return this.props.children
    }

    return fallback
  }
}

import { createContext, FunctionComponent, useContext, useEffect, useState } from 'react'
import { FeatureFlagProvider } from '@touchbistro/react-feature-flags'
import browserConfig from '~/src/shared/config/browser'
import { useGlobalContext } from '~/src/shared/context/GlobalContext'
import { isAgentMimicking } from '~/src/shared/helpers/roles'
import { FeatureFlagClient } from '~/src/shared/initializers/ld_client'
import { SessionRole } from '~/src/shared/types'

const FeatureFlagContext = createContext<{ client: FeatureFlagProvider } | undefined>(undefined)

const FeatureFlagClientProvider: FunctionComponent<{
  children?: React.ReactNode
}> = ({ children }) => {
  const [featureFlagCtx, setFeatureFlagCtx] = useState<
    | {
        client: FeatureFlagProvider
      }
    | undefined
  >()
  const { user, base } = useGlobalContext()

  useEffect(() => {
    let key = browserConfig.environment !== 'production' ? 'baseXRefID-anonymous' : ''

    const isMimicking = isAgentMimicking(user)

    if (user) {
      if (user.role !== SessionRole.AGENT || isMimicking) {
        key = `baseXRefID-${base?.baseXRefID}`
      } else if (user.role === SessionRole.AGENT) {
        key = `userXRefID-${user.userXRefID}`
      }
    }

    if (key != null) {
      FeatureFlagClient.identify({
        anonymous: user == null,
        key,
        custom: {
          service: 'venue-ark-frontend',
        },
      })
        .then(() => {
          // React does not trigger a rerender if we just do setFeatureFlagCtx(FeatureFlagClient)
          // Presumably, this is because FeatureFlagClient is a singleton and react does not see the object changing

          setFeatureFlagCtx({ client: FeatureFlagClient })
        })
        .catch(() => setFeatureFlagCtx(undefined))
    }
  }, [user, base])

  return (
    <FeatureFlagContext.Provider value={featureFlagCtx}>{children}</FeatureFlagContext.Provider>
  )
}

// ReadOnly hook for convenience
const useFeatureFlagContext = (): { client: FeatureFlagProvider } | undefined =>
  useContext(FeatureFlagContext)

export * from '~/src/shared/initializers/ld_client'
export { FeatureFlagClientProvider, useFeatureFlagContext }

const crmES = {
  'crm.appbar-title': '[ES] Guest Manager',
  'crm.guestlist-title': '[ES] Guest List',
  'crm.guestlist-table-header-guest-name': '[ES] Guest Name',
  'crm.guestlist-table-header-email-opt-in': '[ES] Email Opt-In',
  'crm.guestlist-table-header-phone': '[ES] Phone',
  'crm.guestlist-table-header-email': '[ES] Email',
  'crm.guestlist-table-header-lifetime-spend': '[ES] Lifetime Spend',
  'crm.guestlist-table-header-last-order-date': '[ES] Last Order Date',
  'crm.guestlist-fetch-error': '[ES] Failed to fetch guest list',
  'crm.guestlist-search': '[ES] Guest Search',
  'crm.guestlist-empty-content':
    '[ES] Loyal Guests are only a few quick steps away. Let us help you get started by clicking the button below.',
  'crm.guestlist-nooption': '[ES] No Guests match your search.',
  'crm.guestlist-moreoptions': '[ES] And {{totalSearch}} more.',
  'crm.guestlist-getstarted': '[ES] Get Started',
  'crm.guestlist-days-since-last-order': '[ES] Days Since Last Order',
  'crm.guestlist-total-spent12m': '[ES] Total Spent (12M)',
  'crm.guestlist-average-spent-12m': '[ES] Average Spent (12M)',
  'crm.guestlist-total-orders': '[ES] Total Orders (12M)',
  'crm.guestlist-birthday': '[ES] Birthday',
  'crm.guestlist-selectalltext': '[ES] Select all {{allGuestCount}} Guests',
  'crm.guestlist-selectiontext': '[ES] You have selected {{selectedGuestCount}} Guests',
  'crm.guestlist-clearselection': '[ES] Clear Selection',

  'crm.guest-event-type-enum': {
    guest_created: '[ES] Guest Created',
    item_claimed: '[ES] Item Claimed',
    item_removed: '[ES] Item Removed',
    ordered: '[ES] Ordered',
    points_earned: '[ES] Points Earned',
    points_removed: '[ES] Points Removed',
    promotion_sent: '[ES] Promotion Sent',
    refunded: '[ES] Refunded',
    reward_earned: '[ES] Reward Earned',
    reward_redeemed: '[ES] Reward Redeemed',
    reward_removed: '[ES] Reward Removed',
    reward_returned: '[ES] Reward Returned',
    tag_added: '[ES] Tag Added',
    tag_removed: '[ES] Tag Removed',
  },

  'crm.guest-important-date-enum': {
    ANNIVERSARY: '[ES] Anniversary',
    BIRTHDAY: '[ES] Birthday',
  },

  'crm.guestprofile-back-button': '[ES] Back to Guest List',
  'crm.guest-appbar-title': '[ES] Guest Profile',
  'crm.guest-creation-date': '[ES] Guest since',
  'crm.guest-loyalty-and-reward-section': '[ES] Loyalty & Rewards',
  'crm.guest-activity-section': '[ES] Activity',
  'crm.guest-personal-detail-section': '[ES] Personal Details',
  'crm.guest-email-section': '[ES] Email',
  'crm.guest-phonenumber-section': '[ES] Phone',
  'crm.guest-marketing-section': '[ES] Marketing',
  'crm.guest-recently-ordered-section': '[ES] Most Recently Ordered',
  'crm.guest-email-subscribed': '[ES] Email Subscribed',
  'crm.guest-email-notsubscribed': '[ES] Not Subscribed',
  'crm.guestprofile-fetch-error': '[ES] Failed to fetch guest activities',
  'crm.guest-aggregate-total-spent': '[ES] total spend (12M)',
  'crm.guest-event-activity-feed-empty': '[ES] Your activity feed is currently empty',
}

export default crmES

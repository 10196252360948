const backofficeEN = {
  bo: {
    payments: {
      merchant_xref_id: 'Merchant ID',
      state: 'Status',
      state_reason_text: 'Status Reason',
      state_text: {
        data_collection: 'Data Collection',
        adjudication: 'Adjudication',
        accepted: 'Accepted',
        rejected: 'Rejected',
        blocked: 'Blocked',
        terminated: 'Terminated',
        payout_created: 'Payout Created',
        unknown: 'Unknown State',
      },
      state_reason_explanations: {
        fraud: 'The merchant was closed for fraud.',
        additional_documents_required:
          'The merchant needs to upload some additional documents to get verified.',
        high_risk_cb_rate: 'The account was closed due to high risk or high rate of chargebacks.',
        reported_user: 'The merchant was reported as fraudulent.',
        tos_violation: 'The merchant is operating against Terms of Service',
        country_not_supported:
          'The merchant is trying to KYC in a country other than US or Canada.',
        compliance: 'The risk team closed the account due to compliance reasons.',
        unsupported: 'Unsupported',
        closed_for_loss:
          "The merchant's account has maintained a negative balance for more than the allowed number of days.",
        account_closed:
          'Account was closed by payment processor. Reach out to processor for further information.',
        unknown: 'Unknown',
      },
      service: {
        wepay_cp: 'WePay Card Present',
        wepay_cnp: 'WePay Card Not Present',
        payrix_cp: 'Payrix Card Present',
        payrix_cnp: 'Payrix Card Not Present',
        worldpay_cp: 'Worldpay Card Present',
        worldpay_cnp: 'Worldpay Card Not Present',
      },
      settings: {
        title: 'Payment Settings',
        not_available_message: 'Payment settings not available for this venue.',
        not_available_description:
          'This page is only available for Payrix and Worldpay venues. For WePay venues, please use the CS Portal.',
        agent: {
          email: 'TouchBistro Email',
          helper_text: 'Enter your email to confirm you want to make this change',
        },
        express: {
          account_id: 'Account ID',
          account_token: 'Account Token',
          acceptor_id: 'Acceptor ID',
          default_terminal_id: 'Default Terminal ID',
        },
        update: {
          title: 'Update Credentials',
          submit: 'Update',
          unchanged: 'Credentials have not been altered',
          success: 'Successfully updated the merchant credentials',
        },
        confirm: {
          title: 'Hold Up',
          message:
            'You are making a change to critical merchant information. Please verify all values before saving.',
        },
        validation_error: {
          form_error: 'Please fill in all required fields correctly',
          required: 'This field is required',
          email_match: "Email doesn't match",
          merchant_xref_id: "This doesn't look like a valid Merchant ID",
          account_id: "This doesn't look like a valid Account ID",
          account_token: "This doesn't look like a valid Account Token",
          acceptor_id: "This doesn't look like a valid Acceptor ID",
        },
      },
    },
  },
}

export default backofficeEN

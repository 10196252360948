const settingsEN = {
  'settings.cannot_change_address':
    'Unfortunately, you cannot change the address’s country. Please reach out to support if this is needed.',
  'settings.venue_info_warning_modal':
    '<2>Are you sure you want to change this information? By changing the <1>venue name, country or business number</1>, this will have impacts to the merchant’s TB Payments account if applicable. Please ensure to update this information in the TB Payments portal for the merchant.</2>',
  'settings.venue_state_label_error_length': 'The state must be less than 26 characters',
  'settings.save_address': 'Save Address',
  'settings.venue_address_suggestions_modal_title': 'We Found A Similar Address',
  'settings.venue_address_suggestions_modal_message':
    'Using our suggested address ensures the address is in the correct format for us to understand. Which address would you like to use?',
  'settings.original_address': 'Original Address',
  'settings.suggested_address': 'Suggested Address',
  'settings.venue_address_confirmation_modal_title': 'We Couldn’t Find Your Address',
  'settings.venue_address_confirmation_modal_message.1':
    'The address you provided will impact certain TouchBistro products, please ensure you have entered the correct address.',
  'settings.venue_address_confirmation_modal_message.2':
    'Are you sure you want to save this address to this venue?',
}

export default settingsEN

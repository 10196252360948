// TODO > fix font load performance by loading them using link tags at _documents.tsx Header
import 'react-dates/lib/css/_datepicker.css'
import '~/public/assets/fonts/dollypro/style.css'
import '~/public/assets/fonts/graphik/style.css'
import '~/src/domains/ordering/components/calendar/ordering-hours-calendar.css' // Override react-date styles
import '~/src/shared/components/lab/inputs/calendar/report-calendar.css' // Override react-date styles
import '~/src/shared/helpers/withRetries' // init axios-retry for global default axios instance
import '~/src/shared/initializers/rum'
import '~/src/shared/initializers/undo_manager'
import '~/src/shared/locales'
import '~/src/shared/styles/globals.scss'

import { FunctionComponent, StrictMode, useMemo } from 'react'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import ErrorPage from '~/src/pages/_error'
import SnackBarWrapper from '~/src/shared/components/feedback/SnackBarWrapper'
import Initializer from '~/src/shared/components/templates/Initializer'
import { ErrorBoundary } from '~/src/shared/components/utils/ErrorBoundary'
import { DeleteQueueProvider } from '~/src/shared/context/DeleteQueueContext'
import { FeatureFlagClientProvider } from '~/src/shared/context/FeatureFlagContext'
import { GlobalContextProvider } from '~/src/shared/context/GlobalContext'
import {
  SuccessPageProps,
  UnknownPageProps,
} from '~/src/shared/helpers/withServerSidePropsDecorator'
import theme from '~/src/shared/styles/theme'

// Component.pageProps can be ONLY be {} or SuccessPageProps (not ErrorPageProps since we don't render Component on error)
type ComponentPageProps = SuccessPageProps | Record<string, unknown>
interface SSRAppProps extends AppProps<ComponentPageProps> {
  // pageProps can be {}, SuccessPageProps, or ErrorPageProps
  pageProps: Partial<UnknownPageProps>
}

const MyApp: FunctionComponent<SSRAppProps> = ({
  Component: PageComponent,
  pageProps,
  // router prop is available
}) => {
  /* Uncomment when we move back to server-side rendering
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])
  */

  /**
   * [IMPORTANT]
   * To catch errors happening on pages and to return an user friendly message we
   * need to check pageProps.success! DO NOT check pageProps.err or any other prop
   * because those are reserved to local error handling, when applicable. The only
   * way we know for sure it's not an unhandled error, or an error that needs to
   * block the UI is by pageProps.success prop.
   */
  const pageContents = useMemo(() => {
    /**
     * At this point, pageProps can be {}, SuccessPageProps, or ErrorPageProps.
     * So let's check for {} first.
     * Why can it be {}? For cases like an invalid route, NextJS sets pageProps to {}
     */
    if (Object.keys(pageProps).length === 0) {
      return <PageComponent />
    }

    /**
     * At this point, pageProps can be SuccessPageProps or ErrorPageProps.
     * So let's check if pageProps.success is false (i.e. pageProps.error exists).
     * Why? See [IMPORTANT] comment above ^^
     */
    if (pageProps.success === false) {
      return <ErrorPage parsedError={pageProps.error} />
    }

    /**
     * At this point, pageProps can only be SuccessPageProps.
     * So pageProps can be safely cast
     */
    return (
      /**
       * This is a DataDog RUM Error Boundary
       * The code is adapted from https://github.com/DataDog/rum-react-integration-examples/blob/master/src/ErrorBoundary/ErrorBoundary.ts
       * It will catch JS client-side errors only, since HTTP/server-side errors are caught above.
       * It will also render a fallback component and send the error to Datadog.
       */
      <ErrorBoundary fallback={<ErrorPage />}>
        {/* Layout holds local providers scoped by route.asPath */}
        <PageComponent {...pageProps} />
      </ErrorBoundary>
    )
  }, [PageComponent, pageProps])

  return (
    // https://reactjs.org/docs/strict-mode.html
    <StrictMode>
      <Head>
        <title>Touchbistro Admin</title>
        <link rel="icon" href={`/favicon.ico`} />
      </Head>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackBarWrapper>
            <GlobalContextProvider value={pageProps.success ? pageProps.globals : undefined}>
              <FeatureFlagClientProvider>
                <Initializer>
                  <DeleteQueueProvider>{pageContents}</DeleteQueueProvider>
                </Initializer>
              </FeatureFlagClientProvider>
            </GlobalContextProvider>
          </SnackBarWrapper>
        </ThemeProvider>
      </StyledEngineProvider>
    </StrictMode>
  )
}

export default dynamic(() => Promise.resolve(MyApp), {
  ssr: false,
})

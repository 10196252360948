import React, { FunctionComponent, useEffect } from 'react'
import browserConfig from '~/src/shared/config/browser'
import { useGlobalContext } from '~/src/shared/context/GlobalContext'
import { SessionRole } from '~/src/shared/types'

interface PendoIdentifyConfig {
  account: {
    id: string
  }
  visitor: {
    id: string
  }
}
declare global {
  interface Window {
    pendo: {
      initialize(obj: { visitor: { id: string }; account: { id: string | undefined } }): void
      getAccountId(): string | null
      identify(obj: PendoIdentifyConfig): void
    }
  }
}

const Initializer: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const { user, base } = useGlobalContext()

  const userIsNotAgent = user != null && user.role !== SessionRole.AGENT

  useEffect(() => {
    // Pendo initialize only work for the first time
    ;(async (): Promise<void> => {
      if (userIsNotAgent && window.pendo != null && browserConfig.pendoId !== '') {
        const userXRefID = user.userXRefID
        window.pendo?.initialize({
          visitor: {
            id: userXRefID, // Required if using Pendo Feedback
            // email:        // Recommended if using Pendo Feedback, or NPS Email
            // full_name:    // Recommended if using Pendo Feedback
            // role:         // Optional

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
            // You can send dates, integers, floats, booleans, and lists. Pendo does not support objects.
          },
          account: {
            id: base?.baseXRefID ?? undefined, // Required if using Pendo Feedback
            // name:         // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional

            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
          },
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Re-identify pendo to update agent metadata when user switches a base
    // Pendo agent API: https://agent.pendo.io/public/Identity/#identify
    ;(async (): Promise<void> => {
      if (userIsNotAgent && window.pendo?.getAccountId != null && browserConfig.pendoId !== '') {
        const accountID = window.pendo.getAccountId()
        if (accountID && base?.baseXRefID && accountID !== base?.baseXRefID) {
          window.pendo.identify({
            account: {
              id: base.baseXRefID,
            },
            visitor: {
              id: user.userXRefID,
            },
          })
        }
      }
    })()
  }, [user, userIsNotAgent, base])

  return <>{children}</>
}

export default Initializer

const agentEN = {
  'agent.venue_details': 'Venue Details',
  'agent.venue_xrefid': 'Venue ID',
  'agent.venue_xrefid_value': 'Venue ID: {{venueXRefID}}',
  'agent.salesforce_xrefid': 'Salesforce ID',
  'agent.salesforce_xrefid_null_error': 'Error: SalesforceXRefID cannot be null',
  'agent.dba_name': 'DBA Name',
  'agent.timezone': 'Timezone',
  'agent.is_test': 'Is Test?',
  'agent.service_time': 'Service Time',
  'agent.start_of_week': 'Start Of Week',
  'agent.currency': 'Currency',
  'agent.locale': 'Locale',
  'agent.legacy_integrations': 'Legacy Integrations',
  'agent.phone': 'Phone',
  'agent.email': 'Email',
  'agent.venue_details_unavailable': 'Venue details unavailable',
  'agent.venue_address': 'Venue Address',
  'agent.venue_address_line1': 'Address Line 1',
  'agent.venue_address_line2': 'Address Line 2',
  'agent.corporation_details': 'Corporation Details',
  'agent.corporation_admin_email': 'Corporation Admin Email',
  'agent.corporation_signup_created': 'Corporation signup email has been sent to {{email}}',
  'agent.corporation_invite_resent': 'Corporation invite email has been resent',
  'agent.error_user_already_exists': 'Error: User already exists',
  'agent.unexpected_error': 'An unexpected error occurred.',
  'agent.corporation_name': 'Name',
  'agent.corporation_xrefid': 'Corporation ID',
  'agent.corporation_name_successfully_updated': 'Corporation name successfully updated',
  'agent.venue_address_state': 'State',
  'agent.venue_address_city': 'City',
  'agent.venue_address_country': 'Country',
  'agent.venue_address_postal_code': 'Postal Code',
  'agent.venue_active_entitlements': 'Active Entitlements',
  'agent.venue_entitlement_properties': 'Entitlement Properties',
  'agent.active_entitlement_name': 'Name',
  'agent.active_entitlement_description': 'Description',
  'agent.venue_integration_name': 'Name',
  'agent.venue_integration_description': 'Description',
  'agent.no_active_venue_entitlements': 'Venue has no active entitlements',
  'agent.no_enabled_integrations': 'Venue has no enabled integrations',
  'agent.venue_enabled_integrations': 'Enabled Integrations',
  'agent.licence_xrefid': 'Id',
  'agent.licence_start_date': 'Start Date',
  'agent.licence_expiry_date': 'Expiry Date',
  'agent.licence_void_date': 'Void Date',
  'agent.licence_actual_end_date': 'Actual End Date',
  'agent.licence_type': 'Type',
  'agent.licence_pos_count': 'Pad Count',
  'agent.licence_cfd_count': 'CFD Count',
  'agent.licence_status': 'Status',
  'agent.licence_pos_restaurant_xrefid': 'UUID',
  'agent.licence_status_valid': 'Valid',
  'agent.licence_status_voided': 'Voided',
  'agent.licence_status_expired': 'Expired',
  'agent.licence_issuance_reason': 'Issuance Reason',
  'agent.licence_issuance_reason_demo': 'Demo',
  'agent.licence_issuance_reason_collections': 'Collections',
  'agent.licence_issuance_reason_upgrade': 'Upgrade',
  'agent.licence_issuance_reason_new_or_renewal': 'New / Renewal',
  'agent.licence_issuance_reason_prelive': 'Prelive',
  'agent.licence_issuance_reason_temporary_extension': 'Temporary Extension',
  'agent.licence_issuance_reason_unknown': 'Unknown',
  'agent.void_licence': 'Make Void',
  'agent.unlink_licence': 'Unlink licence',
  'agent.unlink_licence_confirm': 'Are you sure you want to unlink this licence?',
  'agent.void_licence_confirm': 'Are you sure you want to void this licence?',
  'agent.licence_success': 'Licences changed successfully',
  'agent.licence_error': 'Error occured while updating this licence',
  'agent.create_licence': 'Create Licence',
  'agent.evergreen_licence_button': 'Evergreen',
  'agent.create_licence_header': 'Create Licence',
  'agent.licences_table_header': 'Licences',
  'agent.no_pos_checkin': 'Venue has no POS checkins',
  'agent.pos_diagnostics': 'POS Diagnostics',
  'agent.no_pos_diagnostics': 'Venue has no POS Diagnostics',
  'agent.checkin_settings': 'Settings',
  'agent.pos_diagnostics_printers': 'Printers',
  'agent.pos_diagnostics_payment_gateway_settings': 'Settings',
  'agent.pos_diagnostics_settings': 'Restaurant Settings',
  'agent.pos_diagnostics_no_printers': 'Venue has no printers',
  'agent.pos_diagnostics_printer_ip_address': 'IP Address',
  'agent.pos_diagnostics_printer_name': 'Name',
  'agent.pos_diagnostics_printer_type': 'Type',
  'agent.pos_diagnostics_payment_terminals': 'Payment Terminals',
  'agent.pos_diagnostics_diagnosis_time_utc': 'Diagnosis Time UTC',
  'agent.pos_diagnostics_time_since_diagnosis': 'Time since Diagnosis',
  'agent.pos_diagnostics_pos_uuid': 'POS UUID',
  'agent.pos_diagnostics_device_id': 'Device ID',
  'agent.pos_diagnostics_client_ip': 'Client IP',
  'agent.pos_diagnostics_pos_version': 'POS Version',
  'agent.pos_diagnostics_device_settings': 'Device Settings',
  'agent.pos_diagnostics_main_device': 'Main Device',
  'agent.pos_diagnostics_main_device_name': 'Name',
  'agent.pos_diagnostics_main_device_ip': 'IP Address',
  'agent.pos_diagnostics_main_device_os': 'Operating System',
  'agent.pos_diagnostics_main_device_network_name': 'Network Name',
  'agent.pos_diagnostics_main_device_running_time_seconds': 'Running Time In Seconds',
  'agent.pos_diagnostics_device_storage': 'Device Storage',
  'agent.pos_diagnostics_device_storage_free_space': 'Free Space',
  'agent.pos_diagnostics_device_storage_data_size': 'Data Size',
  'agent.pos_diagnostics_device_storage_db_size': 'Database Size',
  'agent.pos_diagnostics_venue_operations': 'Venue Operations',
  'agent.pos_diagnostics_venue_operations_last_app_start': 'Last App Start',
  'agent.pos_diagnostics_venue_operations_last_day_start': 'Last Day Start',
  'agent.pos_diagnostics_venue_operations_last_day_end': 'Last Day End',
  'agent.pos_diagnostics_venue_operations_last_restaurant_open': 'Last Restaurant Open',
  'agent.pos_diagnostics_payment_gateway': 'Payment Gateway',
  'agent.pos_diagnostics_no_payment_gateway': 'Venue has not configured a payment gateway',
  'agent.pos_diagnostics_payment_gateway_gateway_name': 'Gateway Name',
  'agent.pos_diagnostics_no_payment_terminals': 'Venue has no payment terminals',
  'agent.pos_diagnostics_payment_terminal_ip_address': 'IP Address',
  'agent.pos_diagnostics_payment_terminal_name': 'Name',
  'agent.pos_diagnostics_payment_terminal_type': 'Type',
  'agent.pos_diagnostics_payment_terminal_id': 'Terminal ID',
  'agent.pos_diagnostics_payment_terminal_lane_number': 'Lane Number',
  'agent.expand': 'Expand',
  'agent.collapse': 'Collapse',
  'agent.audit_logs': 'Audit Logs',
  'agent.no_audit_logs': 'Venue has no audit logs',
  'agent.audit_logs_created_at': 'Created At',
  'agent.audit_logs_event': 'Event',
  'agent.audit_logs_event_type': 'Event Type',
  'agent.audit_logs_description': 'Description',
  'agent.audit_logs_actioned_by': 'Actioned By',
  'agent.patches': 'Patches',
  'agent.support_patch_code': 'Patch Code',
  'agent.support_patch_restaurant_uuid': 'Restaurant Uuid',
  'agent.support_patch_content': 'Content',
  'agent.support_patch_auto_apply': 'Can Auto Apply on Start',
  'agent.support_patch_result': 'Patch Result',
  'agent.support_patch_comments': 'Patch Comments',
  'agent.support_patch_create_date': 'Create Date (UTC)',
  'agent.support_patch_patch_date': 'Patch Date (UTC)',
  'agent.support_patch_created_by': 'Created By',
  'agent.no_support_patches': 'Venue has no support patches',
  'agent.support_patch_create': 'Create Patch',
  'agent.support_patch_sql': 'Patch SQL',
  'agent.support_patch_sql_error': 'SQL is required',
  'agent.support_patch_created_success': 'Support patch created successfully',
  'agent.support_patch_can_auto_apply': 'Can Auto Apply on Start',
  'agent.support_patch_code_create': 'Create Patch Code',
  'agent.venue_devices': 'Devices',
  'agent.no_venue_devices': 'Venue has no linked devices',
  'agent.venue_devices_device_id': 'Device ID',
  'agent.venue_devices_name': 'Name',
  'agent.venue_devices_last_connected': 'Last Connected (Local)',
  'agent.venue_devices_app_name': 'App Name',
  'agent.venue_devices_os': 'OS',
  'agent.venue_devices_hardware': 'Hardware',
  'agent.venue_devices_os_version': 'OS Version',
  'agent.request_sql_file': 'Request SQL File',
  'agent.request_sql_file.success': 'SQL file requested from POS for support',
  'agent.request_sql_file.error': 'There was an error requesting the SQL File, please try again',
  'agent.request_sql_file.error.timeout':
    'Unable to connect with the venue, please try again later',
  'agent.request_log_file': 'Request Log File',
  'agent.request_log_file.success': 'Log file requested from POS for support',
  'agent.request_log_file.error': 'There was an error requesting the Log File, please try again',
  'agent.request_log_file.info': 'Request payment and device logs from POS',
  'agent.latest_backup_uploads': 'Latest Backup Uploads',
  'agent.ftp_sql_dir': 'FTP SQL Directory',
  'agent.stop_mimicking': 'Stop Viewing As',
  'agent.stop_mimicking.success': 'Successfully stopped viewing user',
  'agent.stop_mimicking.error':
    'An error occurred while trying to stop viewing user, please try again',
  'agent.shard_name': 'Shard Name',
  'agent.assign_to_prod_shard': 'Assign to Prod Shard?',
  'agent.assign_to_dev_shard': 'Assign to Dev Shard?',
  'agent.venue_sync_status': 'Sync Status',
  'agent.amplisync_first_sync_is_done': 'First Sync Is Done',
  'agent.amplisync_last_sync_date': 'Last Sync Date',
  'agent.amplisync_state': 'Sync State',
  'agent.amplisync_sync_down_enabled': 'Sync Down Enabled',
  'agent.amplisync_sync_up_enabled': 'Sync Up Enabled',
  'agent.amplisync_sync_subscriber_id': 'Subscriber ID',
  'agent.amplisync_shardxrefid': 'ShardXRefID',
  'agent.amplisync_last_completed_sync_date': 'Last Completed Sync Date',
  'agent.venue_sync_status_unavailable': 'Venue sync status is unavailable',
  'agent.venue_id': 'Venue ID',
  'agent.table_of_contents': 'Table Of Contents',
  'agent.venue_diagnostics_title': 'Diagnostics',
  'agent.venue_amplisync_details': 'Amplisync Details',
  'agent.view_venue_details_snackbar': "Search and press View to see a Venue's Details",
  'agent.resync.create_resync': 'Create Resync',
  'agent.resync.id': 'ID',
  'agent.resync.start_date': 'Start Date',
  'agent.resync.end_date': 'End Date',
  'agent.resync.resync_type': 'Resync Type',
  'agent.resync.acknowledged_at': 'Acknowledged At',
  'agent.resync.notes': 'Notes',
  'agent.resync.created_by': 'Created By',
  'agent.resync.created_at': 'Created At',
  'agent.resync.warning':
    'DO NOT USE: This page is intended for use by Tier 3 and Dev teams only. If you require a Resync to be executed and are not part of DevIT, please escalate a ticket to Tier 3 for actioning.',
  'agent.resync.authorized_confirmation': 'I am authorized to create Resyncs',
  'agent.resync.perform_resync': 'Perform Resync',
  'agent.send_invite': 'Send Invite',
  'agent.resend_invite': 'Resend Invite',
  'agent.amplisync_operations.sync_enablement_header': 'Sync Enablement',
  'agent.amplisync_operations.venue_ids_label': 'Venue ID or list of comma separated Venue IDs',
  'agent.amplisync_operations.venue_id_label': 'Venue ID',
  'agent.amplisync_operations.sync_up': 'Sync Up',
  'agent.amplisync_operations.sync_down': 'Sync Down',
  'agent.amplisync_operations.demo_mode': 'Demo Mode',
  'agent.amplisync_operations.sync_enablements_update_error': 'Error updating venue enablements',
  'agent.amplisync_operations.sync_enablements_fetch_error': 'Error fetching venue enablements',
  'agent.amplisync_operations.sync_enablements_update_success': 'Enablements successfully updated',
  'agent.amplisync_operations.sync_enablement_button': 'Save Changes',
  'agent.amplisync_batch_reset': 'Amplisync Batch Reset',
  'agent.amplisync_operations.enablements_not_found': 'No venues were found with the given IDs',
  'agent.amplisync_operations.reset_amplisync': 'Reset Amplisync',
  'agent.amplisync_operations.batch_reset': 'Batch Reset',
  'agent.amplisync_operations.batch_reset_modal_header':
    'Do you want to perform a batch reset for venues: ',
  'agent.amplisync_operations.batch_reset_success': 'Venues reset successfully',
  'agent.amplisync_operations.batch_reset_error': 'Venues failed to reset',
  'agent.domain_data_xfer.title': 'Domain Data Transfer',
  'agent.domain_data_xfer.confirm': 'Confirm',
  'agent.domain_data_xfer.confirm_transfer_title': 'Confirm Transfer',
  'agent.domain_data_xfer.confirm_transfer':
    "Please confirm you want to transfer '{{domain}}' data from '{{sourceBaseName}}' - {{sourceBaseXRefID}} to '{{targetBaseName}}' - {{targetBaseXRefID}}",
  'agent.domain_data_xfer.success':
    "Success transferring '{{domain}}' data from '{{sourceBaseName}}' - {{sourceBaseXRefID}} to '{{targetBaseName}}' - {{targetBaseXRefID}}",
  // RMM3 Upgrade
  'agent.domain_data_xfer.source_base': 'Select a source base',
  'agent.domain_data_xfer.source_base_description': 'Data to be transferred from this base',
  'agent.domain_data_xfer.target_base': 'Select a target base',
  'agent.domain_data_xfer.target_base_description': 'Data to be transferred to this base',
  'agent.domain_data_xfer.hierarchy_data_option': 'Select a hierarchy data option',
  'agent.domain_data_xfer.wipe_data_option_warning':
    'Wipe Option = This will wipe menu data from all venues except from source base before elevate data. If there are existing data at root/group, please delete them before select this option. There is no rollback',
  'agent.domain_data_xfer.no_wipe_data_option_warning':
    'No Wipe Option = This will elevate data and keep all exisiting data and overrides data from root/group and other venues',
  'agent.domain_data_xfer.wipe_data_option': 'Wipe all menu data then elevate',
  'agent.domain_data_xfer.merge_data_option': 'Do not wipe any menu data before elevation',
  'agent.domain_data_xfer.domain': 'Domain',
  'agent.domain_data_xfer.no_domain_info': 'No upgradeable domains found',
  'agent.rmm3_upgrade.venues_to_upgrade': 'Venues To Upgrade',
  'agent.upgrade': 'Upgrade',
  'agent.rmm3_upgrade': 'RMM3 Upgrade',
  'agent.rmm3_upgrade_do_not_touch': 'RMM3 Upgrade (DO NOT TOUCH)',
  'agent.rmm3_upgrade_name': 'Name (links to details)',
  'agent.rmm3_upgrade_venueid': 'Venue ID (links to DataDog)',
  'agent.rmm3_upgrade_status': 'Upgrade Status',
  'agent.rmm3_upgrade_error': 'Upgrade Error',
  'agent.rmm3_upgrade_error_err_non_supported_pos_version':
    'Venue is using a non-supported POS version',
  'agent.rmm3_upgrade_error_err_inclusive_tax_error': 'Venue has inclusive tax enabled',
  'agent.rmm3_upgrade_error_err_rmm3_migration_failed': 'RMM3 Upgrade Failed',
  'agent.rmm3_upgrade.stats_title': 'Stats for customer accounts',
  'agent.rmm3_upgrade.stats':
    "We've attempted upgrades for {{total}} customer venues: {{success}} have succeeded and {{fail}} have failed, the other {{rest}} are in progress.",
  'agent.rmm3_upgrade.are_you_sure': 'Are you sure you want to upgrade these venues?',
  'agent.rmm3_upgrade.warning_message':
    '<1>Please take the time to confirm the following venues are valid.</1><2>NOTE: Attempting to upgrade a venue may cause disruptions to live customers during service hours. If you are unsure, please click cancel and leave this page immediately.</2>',
  'agent.rmm3_upgrade.upgrade_statuses': 'Upgrade Statuses',
  'agent.rmm3_upgrade.upgrade_statuses_subtitle':
    'The following is a list of RMM3 upgrade statuses',
  'agent.rmm3_upgrade.upgrade_error': '{{errorCode}}',
  'agent.rmm3_upgrade.no_upgrades': 'No RMM3 Upgrades found',
  'agent.rmm3_upgrade.legend': 'Legend',
  'agent.rmm3_upgrade.statuses.completed':
    'Upgrade for this feature is complete. If all features have completed, the customer is on the new cloud platform.',
  'agent.rmm3_upgrade.statuses.failed_cloud':
    '<1>There was an issue with the Cloud. Please review the error log in the dashboard and/or in the </1><2>wiki</2>',
  'agent.rmm3_upgrade.statuses.failed_pos':
    '<1>There was an issue with the POS. Please review the error log in the dashboard and/or in the </1><2>wiki</2>',
  'agent.rmm3_upgrade.statuses.failed_validation':
    'The customer did not pass system and business validation. If you see this error, it means the customer is not a good fit to be part of the upgrade process',
  'agent.rmm3_upgrade.statuses.pending_pos':
    'The system is currently waiting for the latest POS data',
  'agent.rmm3_upgrade.statuses.pending_restart':
    'To complete the upgrade, please restart the POS main device/server',
  'agent.rmm3_upgrade.statuses.requested':
    'An agent has initiated the upgrade from the upgrades tool. We are requesting the latest data from the POS. Please restart the POS main device/server',
  'agent.rmm3_upgrade.statuses.started': 'The upgrade has started. Please wait at least 15 minutes',
  'agent.rmm3_upgrade.statuses.synced': 'POS Staff data has been uploaded to cloud successfully',
  'agent.rmm3_upgrade.success': 'Upgrades successfully requested',
  'agent.rmm3_upgrade.search': 'Search by name or ID',
  'agent.rmm3_upgrade.migration_type': 'Migration Type',
  'agent.rmm3_upgrade.menu_migration': 'Menu',
  'agent.rmm3_upgrade.staff_migration': 'Staff',
  'agent.rmm3_upgrade.staff_migration.completed': 'COMPLETED',
  'agent.rmm3_upgrade.staff_migration.sync_error': 'SYNC ERROR',
  'agent.rmm3_upgrade.staff_migration.synced': 'SYNCED',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_dupe_roles':
    'Duplicate roles with mismatched data: {{count}}',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_waiter_pin_missing':
    'Waiters missing pins: {{count}}',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_dupe_waiters':
    'Duplicate waiters with mismatched data: {{count}}',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_dupe_waiter_roles':
    'Duplicate waiterRoles with mismatched rateOfPay: {{count}}',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_role_baseID':
    'Existing roles not found on base: {{count}}',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_user_baseID':
    'Existing users not found on base: {{count}}',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_role_not_found':
    'Migrated roles not found: {{count}}',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_user_not_found':
    'Migrated users not found: {{count}}',
  'agent.venue_pos_changes': 'POS Changes',
  'agent.venue_pos_version_changes': 'Version Changes',
  'agent.venue_pos_change_created_at': 'Created At',
  'agent.venue_pos_change_old_value': 'Old Value',
  'agent.venue_pos_change_new_value': 'New Value',
  'agent.no_pos_version_changes': 'Venue has no POS version changes',
  'agent.venue_pos_restaurant_uuid_changes': 'Restaurant UUID Changes',
  'agent.venue_pos_device_id_changes': 'Device ID Changes',
  'agent.no_changes': 'Venue has no changes',
  'agent.no_restaurant_uuid_changes': 'Venue has no restaurant UUID changes',
  'agent.no_device_id_changes': 'Venue has no device ID changes',
  'agent.venue_pos_time_since_change': 'Time Since Change',
  'agent.user_mimic': 'View as staff member',
  'agent.username.sort_by.venues': 'Venues',
  'agent.username.sort_by.groups': 'Groups',
  'agent.username.table.header.corporation': 'CORPORATION',
  'agent.username.table.header.corporation_groups': 'CORPORATION / GROUPS',
  'agent.username.table.header.venues': 'VENUES',
  'agent.username.table.header.roles': 'ROLES',
  'agent.username.table.header.permission_type': 'PERMISSION TYPE',
  'agent.view_staff': 'View Staff',
  'agent.view_venues': 'View Venues',
  'agent.sort_by': 'Sort By',
  'agent.groups': 'Groups',
  'agent.view_details': 'View Details',
  'agent.add_a_new_group': 'Add a New Group',
  'agent.group_name': 'Group Name',
  'agent.group_id': 'Group ID',
  'agent.reservation_entitlement_list': 'TB Reservations in Cloud*',
  'agent.reservation_entitlement_title': 'TB Reservations in Cloud',
  'agent.reservation_entitlement_caption':
    "* Provisions TB Reservations in Cloud only. Legacy Reservations is provisioned from the Reservation's admin tool.",
  'agent.reservation_entitlement_warning': 'To provision Guest Products, add venues to this group.',
  'agent.reservation_eligibility_warning':
    'Venues in this group are ineligible for TB Reservations in Cloud.',
  'agent.reservation_move_eligibility_warning':
    'Disabled Venues can not be moved to this Group because of entitlement conflicts.',
  'agent.group_assigned_venues': 'Assigned Venues',
  'agent.group_move_venues': 'Move Venues to this Group',
  'agent.group_move_venues_modal_title': 'Move venues to {{group_name}}',
  'agent.group_move_venues_modal_subtitle':
    'Select venues that will be removed from other groups and added to this group.',
  'agent.group_move_venues_modal_submit': 'Move to Group',
  'agent.group_assigned_products': 'Guest Product Provisioning',
  'agent.group_unassigned_venue_err': 'You need to assign all venues to groups',
  'agent.enter_a_group_name': 'Please enter a group name',
  'agent.group_move_venues_to_unassigned': 'Move Venues to Unassigned',
  'agent.group_move_venues_to_unassigned_modal_subtitle':
    'Select venues that will be removed from groups and added to corporation as unassigned venues.',
  'agent.group_move_venues_to_unassigned_modal_submit': 'Move to Unassigned',
  'agent.no_items_available': 'No Items',
  'agent.products': 'Products',
  'agent.baseXRefID': 'BaseXRefID',
  'agent.ge_sync_required': 'TableUp synchronization required.',
  'agent.ge_sync_title': 'Legacy TableUp synchronization required.',
  // Group Management
  'agent.group_management.post_save.modal_title': 'Action Required!',
  'agent.group_management.post_save.staff.text1':
    'Please make sure Staff have been assigned permissions to the correct location. Staff that had permission to the group may no longer have access to the venue. Staff that had access to the old group should not have access to the venue anymore (unless the Staff has access to both groups).',
  'agent.group_management.post_save.menu.text1':
    'After moving the venue, publish your menu on Cloud to see the latest changes reflected. Failure to do so will result in the previous menu displaying on the POS and Online Ordering until a successful publish occurs. This includes any 3rd party integration that integrates with menu management. Please publish your menu when you are ready for the menus of the new groups to be reflected.',
  'agent.group_management.post_save.menu.text2':
    'Please make sure to re-assign printers to new prep stations, otherwise, it will result in the printers not working for the venue(s)',
  'agent.dgc': 'Digital Gift Cards Admin',
  'agent.dgc.restaurantGroupName': 'Restaurant Group',
  'agent.dgc.origin': 'Origin',
  'agent.dgc.active': 'Status',
  'agent.dgc.purchasedAtVenueXRefID': 'Purchased At',
  'agent.dgc.search.placeholder': 'Search by Reference ID or email address',
  'agent.dgc.empty_table_text': 'Enter a reference ID or email address in the search bar',
  'agent.dgc.no_matching_result':
    'No matching results to display. Please try a different Reference ID or email address',
  'agent.dgc.deactivate': 'Deactivate',
  'agent.dgc.reactivate': 'Reactivate',
  'agent.dgc.deactivate.reason': 'Deactivation Reason',
  'agent.dgc.deactivate.info': 'Deactivation Information',
  'agent.dgc.deactivate.reason.placeholder': 'Please enter a deactivation reason',
  'agent.dgc.info': 'Gift Card Information',
  'agent.dgc.deactivated_successfully': 'Gift Card {{referenceID}} is deactivated successfully!',
  'agent.dgc.reactivated_successfully': 'Gift Card {{referenceID}} is reactivated successfully!',
  'agent.time': 'Time',
  'agent.reason': 'Reason',
  'agent.save_discard_changes_dialog_prompt':
    'Are you sure you want to leave this page without saving your changes?',
  'agent.dialog_save_changes': 'Save Changes',
  'agent.dialog_discard_changes': 'Discard Changes',
  'agent.changes_saved_successfully': 'Changes made to {{groupName}} were successfully saved!',
  'agent.new_group_created_successfully': 'Group created successfully',
  'agent.bill_processor_entitlement_page': 'Bill Processor Entitlement',
  'agent.bill_processor_entitlement_update_success':
    'The bill processor entitlement has been updated successfully',
  'agent.bill_processor_entitlement_page_save': 'Save',
  agent: 'Agent',
}

export default agentEN

const liteEN = {
  // namespace your translation keys with "lite", i.e.
  // 'lite.lorem_ipsum': 'Lorem Ipsum'
  'lite.devices.prep-stations': 'Prep Stations',
  'lite.devices.prep-station.create': 'Create Prep Station',
  'lite.devices.prep-station.edit': 'Edit Prep Station',
  'lite.devices.prep-station.delete_modal.title':
    'Are you sure you want to delete this prep station?',
  'lite.devices.prep-station.delete': 'Delete Prep Station',
  'lite.devices.prep-station.configured-on-the-pos': 'Prep stations must be configured on the POS',
}

export default liteEN

import React, { FunctionComponent } from 'react'
import { SnackbarProvider } from 'notistack'

const SnackBarWrapper: FunctionComponent<{
  children?: React.ReactNode
}> = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={2}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      autoHideDuration={3000}
    >
      {children}
    </SnackbarProvider>
  )
}

export default SnackBarWrapper

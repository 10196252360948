import i18next, { i18n, FormatFunction } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationEN from '~/src/shared/locales/en'
import translationES from '~/src/shared/locales/es'
import { formatCurrency } from '~/src/shared/locales/formatters'

// https://www.i18next.com/translation-function/formatting
const formatFunction: FormatFunction = (value, format, lng): string => {
  switch (format) {
    case 'formatCurrency':
    default:
      return formatCurrency(value, lng)
  }
}

const resources = {
  en: { translation: translationEN },
  es: { translation: translationES },
}

const createI18n = (): i18n => {
  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
      resources, // loads supported languages
      fallbackLng: 'en', // sets initial language
      interpolation: {
        escapeValue: false, // react already safes from xss
        format: formatFunction,
        formatSeparator: ',',
      },
    })

  return i18next
}

export default createI18n()

export const formatCurrency = (value: string, lng = 'en-US'): string => {
  const localeToCurrencyMapper = (locale: string): string => {
    switch (locale) {
      case 'en-US':
        return 'USD'
      case 'es-MX':
        return 'MXN'
      case 'en-CA':
      case 'fr-CA':
      default:
        return 'CAD'
    }
  }

  const { format } = new Intl.NumberFormat(lng, {
    currency: localeToCurrencyMapper(lng),
    style: 'currency',
  })

  return format(Number(value))
}

import { useCallback } from 'react'
import { NextPage } from 'next'
import { useTranslation } from 'react-i18next'
import {
  BadRequestError,
  ClientSideError,
  ForbiddenError,
  InternalServerError,
  NotFoundError,
  UnauthorizedError,
  UnprocessibleEntityError,
} from '~/src/shared/components/surfaces/errors/Errors'
import { ParsedError } from '~/src/shared/helpers/errors'
import { StatusCodes } from '~/src/shared/types'

interface IErrorPageProps {
  parsedError?: Partial<ParsedError>
}

const ErrorPage: NextPage<IErrorPageProps> = ({ parsedError }) => {
  const { t } = useTranslation()

  const renderErrorContent = useCallback(() => {
    // Render the client side error content if parsedError is not passed
    if (!parsedError) {
      return <ClientSideError t={t} />
    }

    switch (parsedError?.statusCode) {
      case StatusCodes.BAD_REQUEST: // 400
        return <BadRequestError t={t} parsedError={parsedError} />
      case StatusCodes.UNAUTHORIZED: // 401
        return <UnauthorizedError t={t} parsedError={parsedError} />
      case StatusCodes.FORBIDDEN: // 403
        return <ForbiddenError t={t} parsedError={parsedError} />
      case StatusCodes.NOT_FOUND: // 404
        return <NotFoundError t={t} parsedError={parsedError} />
      case StatusCodes.UNPROCESSABLE_ENTITY: // 422
        return <UnprocessibleEntityError t={t} parsedError={parsedError} />
      case StatusCodes.SERVER_ERROR: // 500
      default: // 5XX (or anything really)
        return <InternalServerError t={t} parsedError={parsedError} />
    }
  }, [parsedError, t])

  return <>{renderErrorContent()}</>
}

export default ErrorPage

const settingsES = {
  'settings.cannot_change_address': 'Coming Soon',
  'settings.venue_info_warning_modal':
    '<2>¿Estás seguro de que quieres cambiar esta información? Al cambiar <1>el nombre del lugar, el país o el número comercial</1>, esto tendrá un impacto en la cuenta TB Payments del comerciante, si corresponde. Asegúrese de actualizar esta información en el portal TB Payments para el comerciante.</2>',
  'settings.venue_state_label_error_length': 'El estado debe tener menos de 26 caracteres',
  'settings.save_address': 'Guardar dirección',
  'settings.venue_address_suggestions_modal_title': 'Encontramos una dirección similar',
  'settings.venue_address_suggestions_modal_message':
    'El uso de nuestra dirección sugerida garantiza que la dirección está en el formato correcto para que podamos entenderla. ¿Qué dirección deseas utilizar?',
  'settings.original_address': 'direccion original',
  'settings.suggested_address': 'Dirección sugerida',
  'settings.venue_address_confirmation_modal_title': 'No pudimos encontrar tu dirección',
  'settings.venue_address_confirmation_modal_message.1':
    'La dirección que has proporcionado afectará a ciertos productos de TouchBistro. Asegúrate de haber introducido la dirección correcta.',
  'settings.venue_address_confirmation_modal_message.2':
    '¿Estás seguro de que quieres guardar esta dirección de esta sede?',
}

export default settingsES

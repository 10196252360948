const reportsES = {
  // namespace your translation keys with "rpt", i.e.
  // 'report.lorem_ipsum': 'Lorem Ipsum'
  'report.by_bill_time': 'Por tiempo de factura',
  'report.by_sales': 'Por ventas',
  'report.by_close_time': 'Hora de cierre de facturación',
  'report.by_start_time': 'Hora de inicio de facturación',
  'report.net_sales': 'Ventas netas',
  'report.gross_sales': 'Ventas brutas',
  'report.step': 'Paso',
  'report.report_format_desc':
    'Por favor tenga en cuenta que recibirá un archivo .csv para cada informe y un archivo .xlsx con varias hojas (una hoja para cada informe).',
  'report.scheduled_report_emails_desc':
    'Usted puede ingresar múltiples correos electrónicos separados por una coma',
  'report.daily-tab': 'Ventas diarias',
  'report.hourly-tab': 'Ventas por hora',
  'report.voids': 'Anulados',
  'report.discounts': 'Descuentos',
  'report.weekly': 'Semanal',
  'report.monthly': 'Mensual',
  'report.choose_scheduled_multi':
    'Por favor elija los reportes que quiere incluir en su informe programado.',
  'report.select_date': 'Seleccione la fecha de inicio',
  'report.continue_scheduling': 'Continuar programando',
  'report.not_scheduled_yet_confirm_create':
    'Su reporte aún no ha sido programado. ¿Está seguro de que desea volver?',
  'report.will_not_be_scheduled_create':
    'Tenga en cuenta que su reporte no se programará y perderá cualquier progreso que haya realizado.',
  'report.not_scheduled_yet_confirm_update':
    'Sus cambios aún no se han guardado. ¿Está seguro de que desea volver?',
  'report.will_not_be_scheduled_update':
    'Tenga en cuenta que sus cambios no se guardarán y tendrá que empezar de nuevo la próxima vez.',
  'report.success': '¡Exitoso!',
  'report.has_been_scheduled': 'Su informe ha sido programado',
  'report.schedule_report_title_required': 'Por favor ingrese el título',
  'report.send_to': 'Enviar a',
  'report.select_at_least_one_venue': 'Por favor selecciona al menos un establecimiento',
  'report.select_at_least_one_report': 'Por favor selecciona al menos un reporte',
  'report.invalid_email': 'Por favor introduce una dirección de correo electrónico válida',
  'report.duplicate_email': 'Elimine los correos electrónicos duplicados',
  'report.email_add_required': 'Por favor introduzca al menos una dirección de correo electrónico',
  'report.validation_required_min': 'Por favor, seleccione al menos una sección',
  'report.validation_required': 'Este campo es obligatorio',
  'report.select_venues': 'Seleccionar Establecimientos',
  'report.warning_deleting_scheduled_report_sub':
    'Esta acción eliminará permanentemente su informe. Usted no podrá recuperar esta información.',
  'report.schedule_report_freq_daily': 'Recibirá su reporte todos los días.',
  'report.schedule_report_freq_weekly':
    'Usted recibirá su reporte semanalmente. Su reporte va a consolidar la información del inicio al fin de la semana de tu servicio semanal anterior.',
  'report.schedule_report_freq_2weeks':
    'Usted recibirá su reporte cada 2 semanas. Puede seleccionar la fecha de inicio (cualquier día hasta 2 semanas anterior al día actual).',
  'report.schedule_report_freq_monthly':
    'Usted recibirá su reporte el primer día de cada mes. Su reporte va a consolidar la información del inicio al fin del mes anterior.',
  'report.next_report_date_column': 'Fecha del próximo informe (A/M/D)',
  'report.next_report_date': 'Fecha de próximo reporte:',
  'report.merging_report_title':
    'Los reportes "{{reportName}}" y "{{reportNameTwo}}" serán unidos en el nuevo reporte "{{mergeReportName}}".',
  'report.merging_report_merge_body':
    'En las siguientes semanas uniremos los reportes "{{reportName}}" and "{{reportNameTwo}}" en un único reporte llamado "{{mergeReportName}}". Usted puede encontrar este nuevo reporte en Reportes > {{mergeSectionName}} y será capaz de descargarlo en el formato CSV o XLS. Previamente programado para recibir estos dos reportes, tenga en consideración que recibirá un único reporte de ahora en adelante.',
  'report.reopened_bills': 'Cuentas Reabiertas',
  'report.gift_card_analysis': 'Análisis de tarjetas de regalo',
  'report.sales': 'Ventas',
  'report.activity': 'Actividad',
  'report.takeout_types': 'Tipos de comida para llevar',
  'report.deleted_items': 'Objetos eliminados',
  'report.tax_exclusion': 'Reglas de exclusión de Impuestos',
  'report.no_sale': 'Objetos no vendidos',
  'report.name_scheduled_report': 'Nombra el informe programado',
  'report.indicates_required_field': 'indica un campo obligatorio.',
  'report.server': 'Mozo',
  'report.date_and_timestamp': 'Fecha/sello de hora',
  'report.reason': 'Motivo',
  'report.amount': 'Amount (Monto)',
  'report.register': 'Caja registradora',
  'report.comment': 'Comentario',
  'report.accounts': 'Cuentas',
  'report.accounts_deposits_payments': 'Mostrar depósitos/pagos',
  'report.payins': 'Cuentas por cobrar',
  'report.payouts': 'Cuentas por pagar',
  'report.payins_payouts': 'Cuentas por pagar/Cuentas por cobrar',
  'report.updated_at': 'Actualizado por última vez el',
  'report.report_merged_into_staff_performance':
    'Este informe se fusionó con el informe de rendimiento del personal y se movió a la sección Laboral',
  'report.moved_to_other_section': 'Este informe se ha trasladado a la Sección {{section}}',
  'report.detailed_sales': 'Ventas detalladas',
  'report.decommission_title': '{{currentName}} se fusionará con el reporte {{newReportName}}',
  'report.decommission_body':
    'El reporte {{currentName}} se ha fusionado con el reporte "{{newReportName}}" en la sección {{newSection}}. Podrá descargar el informe en su formato preferido.',
  'report.single_vs_multi_selection':
    'El numero de informes disponibles cambiarán según la cantidad de restaurantes seleccionados; cambiar el número de restaurantes seleccionados después de elegir los informes borrará la selección del informe y tendrá que seleccionar su(s) informe(s) nuevamente.',
  'report.print_this_report': 'Imprimir este informe',
  'report.report_range': 'Rango del informe',
  'report.number_of_venues': 'Cant. de lugares',
  'report.venues_included': 'Lugares incluidos',
  'report.report_summary': 'Resumen del informe',
  'report.incompatible_browser_title': 'Navegador incompatible',
  'report.incompatible_browser_body':
    'Recomendamos usar Google Chrome o Mozilla Firefox para imprimir reportes.',
  'report.incompatible_browser_button': 'Imprimir de todos modos',
  'report.printing_in_progress': 'Preparando tu informe...',
  'report.printing_disclaimer':
    'Ten en cuenta que los grandes volúmenes de datos pueden tardar más en imprimirse. Recomendamos imprimir informes con volúmenes de datos más pequeños para garantizar una impresión exitosa.',
  'report.send_report': 'Enviar Reporte',
  'report.send_reports': 'Enviar Reportes',
  'report.send_report_desc':
    'Por favor seleccione su formato preferido y agregue al menos un correo electrónico para enviar su reporte.',
  'report.report_format': 'Formato del reporte',
  'report.success_sent': 'Tu reporte ha sido enviado satisfactoriamente.',
  'report.error_sent': 'Su informe no pudo ser enviado. Inténtalo de nuevo por favor.',
  'report.dashboard_top_sales_categories': 'Principales Categorías de Ventas',
  'report.dashboard_top_shifts': 'Turnos Superiores',
  'report.dashboard_tender_types': 'Tipos de Licitación',
  'report.dashboard_top_menu_groups': 'Grupos de Menú Principales',
  'report.dashboard_top_discounts_voids': 'Descuentos y Vacíos Principales',
  'report.dashboard_top_menu_items': 'Elementos del Menú Superior',
  'report.no_bills_found': 'No se encontraron facturas',
  'report.reports': 'Reportes',
  'report.select_other_reports': 'Seleccionar Otro(s) Reporte(s)',
  'report.pos_to_cloud_reports_limited_data':
    'Por favor actualiza TouchBistro POS a la version 10.4 o arriba para acceder a este reporte. Una vez actualizado, tendrás acceso a la información desde esa fecha en adelante hasta que agreguemos el historial de información.',
  'report.pos_to_cloud_reports_limited_data_gift_card':
    'Por favor actualiza TouchBistro POS a la version 10.7 o arriba para acceder a este reporte. Una vez actualizado, tendrás acceso a la información desde esa fecha en adelante hasta que agreguemos el historial de información.',
  'report.week_selector': 'Seleccionar Semana',
  'report.month_selector': 'Seleccionar Mes',
  'report.staff_summary': 'Resumen del personal',
  'report.continue_editing': 'Seguir Editando',
  'report.configurable_reports': 'Reportes Configurables',
  'report.view-reports': 'Ver los informes',
  'report.view-reports-description':
    'Seleccione una categoría y luego un informe que le gustaría ver. Personalice y guarde su nuevo informe para acceder rápidamente a él para futuro uso.',
  'report.saved-reports': 'Saved Reports',
  'report.no_saved_reports_title': 'No hay informes guardados',
  'report.no_saved_reports_message':
    'Actualmente no tiene ningún informe guardado. Vea, personalice y guarde los informes para acceder a ellos aquí.',
  'report.description': 'Descripción',
  'report.category': 'Categorías',
  'report.created_at': 'Creado',
  'report.select_a_category': 'Seleccione una Categoría',
  'report.saved_report_desc': '[ES] View, edit or favorite your saved reports.',
  'report.basic_list_view_update_prompt':
    '¿Está seguro de que desea actualizar {{candidateForUpdate}}?',
  'report.warning_favorite_report': '[ES] This action will set your report as favorite.',
  'report.end_of_day': 'Fin del dia',
  'report.update_custom_report_error': 'Hubo un error al actualizar su informe.',
  'report.report_updated': 'Reporte actualizado',
  'report.all_reports': 'Todos los Reportes',
  'report.print': 'Imprimir',
  'report.select_reports': 'Seleccionar Reportes',
  'report.end_of_day_report': 'Informe de fin de día',
  'report.shift_report': 'Reporte de Turno',
  'report.no_data': 'No hay datos disponibles',
  'report.all_selected': 'Todo seleccionado',
  'report.edit_columns': 'Editar columnas',
  'report.save_report_as': 'Guardar Informe Como',
  'report.new_custom_report': 'Nuevo Informe',
  'report.update_custom_report': 'Actualizar Informe Actual',
  'report.saved_report_name': 'Reporte Nombre',
  'report.optional_description': 'Agregue una nota (opcional)',
  'report.save_report_title': 'Guardar Los Informes',
  'report.report_update_success_notification': 'El informe {{reportName}} ha sido actualizado',
  'report.day_part_name': 'Nombre de la parte del día',
  'report.start_and_end_time': 'Hora de inicio y hora de finalización',
  'report.scheduled_days': 'Días programados',
  'report.no_data_for_shift': 'Datos no disponibles {{date}}',
  'report.no_data_for_shift_to_show_data':
    'Para mostrar los datos de su informe de turno, seleccione las fechas en las que marcó su entrada o salida. Las fechas intermedias no mostrarán ningún dato.',
  'report.no_data_for_shift_reminder':
    'Un recordatorio para marcar la salida para evitar turnos que abarquen varios días.',
  'report.eod_report.sales-tax-and-tip-summary': '[ES] Sales, Tax, and Tip Summary',
  'report.eod_report.sales-summary': '[ES] Detailed Sales Summary',
  'report.eod_report.sales-by-category': '[ES] Sales By Sales Category',
  'report.eod_report.sales-by-section': '[ES] Sales By Section',
  'report.eod_report.gift-card-sales': '[ES] Gift Card Sales',
  'report.eod_report.labor-summary': '[ES] Detailed Labor Summary',
  'report.eod_report.tips-by-employee': '[ES] Tips By Employee',
  'report.eod_report.tips-summary': '[ES] Tips Summary',
  'report.eod_report.payments-summary': '[ES] Payments Summary',
  'report.eod_report.payment-by-payment-type': '[ES] Payments By Payment Type',
  'report.eod_report.tip-outs-summary': '[ES] Tip Outs',
  'report.eod_report.accounts': '[ES] Accounts Summary',
  'report.eod_report.payouts-payins': '[ES] Payouts/Payins',
  'report.eod_report.cash-deposit': '[ES] Cash Deposit',
  'report.generating_report': '[ES] Generating Your Report',
  'report.generating_report_message':
    '[ES] Please wait a moment while we generate your report to download',
  'report.purchases': '[ES] Purchases',
  'report.explain_this_report': '[ES] Explain This Report',
  'report.advanced_filters': '[ES] Advanced Filters',
  'report.deposits': '[ES] Deposits',
  'report.table.date_time': '[ES] DATE/TIME',
  'report.table.status': '[ES] STATUS',
  'report.table.reasons': '[ES] REASONS',
  'report.table.total_amount': '[ES] TOTAL AMOUNT',
  'report.table.tip_amount': '[ES] TIP AMOUNT',
  'report.table.fees': '[ES] FEES',
  'report.table.network_state': '[ES] NETWORK STATE',
  'report.table.card_type': '[ES] CARD TYPE',
  'report.table.card_number': '[ES] CARD NUMBER',
  'report.table.bill_number': '[ES] BILL NUMBER',
  'report.table.order_number': '[ES] ORDER NUMBER',
  'report.table.transaction_id': '[ES] TRANSACTION ID',
  'report.table.auth_id': '[ES] AUTH ID',
}

export default reportsES

import React, { FunctionComponent, createContext, useContext } from 'react'
import type { GlobalProps } from '~/src/shared/helpers/withServerSidePropsDecorator'

const defaultValue: GlobalProps = {
  user: null,
  base: null,
  incomingUserAgent: '',
}

const GlobalCtx = createContext<GlobalProps>(defaultValue)

export const GlobalContextProvider: FunctionComponent<{
  value: GlobalProps | undefined
  children?: React.ReactNode
}> = ({ children, value }) => {
  return <GlobalCtx.Provider value={value ?? defaultValue}>{children}</GlobalCtx.Provider>
}

export const useGlobalContext = (): GlobalProps => {
  return useContext(GlobalCtx)
}

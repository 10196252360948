import { FC } from 'react'
import { TFunction } from 'react-i18next'
import ErrorContent from '~/src/shared/components/surfaces/errors/ErrorContent'
import { ParsedError } from '~/src/shared/helpers/errors'

interface IErrorProps {
  t: TFunction
}

interface IHTTPErrorProps extends IErrorProps {
  parsedError?: Partial<ParsedError>
}

export const BadRequestError: FC<IHTTPErrorProps> = ({ t, parsedError }) => (
  <ErrorContent
    message={t('error_page_400_message')}
    errorCode={t('error_page_400_error_code')}
    reasonList={[
      t('error_page_400_reason_1'),
      t('error_page_400_reason_2'),
      t('error_page_400_reason_3'),
    ]}
    optionList={[
      t('error_page_400_option_1'),
      t('error_page_400_option_2'),
      t('error_page_400_option_3'),
      t('error_page_400_option_4'),
    ]}
    parsedError={parsedError}
  />
)

export const UnauthorizedError: FC<IHTTPErrorProps> = ({ t, parsedError }) => (
  <ErrorContent
    message={t('error_page_401_message')}
    errorCode={t('error_page_401_error_code')}
    reasonList={[t('error_page_401_reason_1'), t('error_page_401_reason_2')]}
    optionList={[
      t('error_page_401_option_1'),
      t('error_page_401_option_2'),
      t('error_page_401_option_3'),
    ]}
    parsedError={parsedError}
  />
)

export const ForbiddenError: FC<IHTTPErrorProps> = ({ t, parsedError }) => (
  <ErrorContent
    message={t('error_page_403_message')}
    errorCode={t('error_page_403_error_code')}
    reasonList={[t('error_page_403_reason_1')]}
    optionList={[t('error_page_403_option_1'), t('error_page_403_option_2')]}
    parsedError={parsedError}
  />
)

export const NotFoundError: FC<IHTTPErrorProps> = ({ t, parsedError }) => (
  <ErrorContent
    message={t('error_page_404_message')}
    errorCode={t('error_page_404_error_code')}
    reasonList={[t('error_page_404_reason_1'), t('error_page_404_reason_2')]}
    optionList={[
      t('error_page_404_option_1'),
      t('error_page_404_option_2'),
      t('error_page_404_option_3'),
    ]}
    parsedError={parsedError}
  />
)

export const UnprocessibleEntityError: FC<IHTTPErrorProps> = ({ t, parsedError }) => (
  <ErrorContent
    message={t('error_page_422_message')}
    errorCode={t('error_page_422_error_code')}
    reasonList={[t('error_page_422_reason_1')]}
    optionList={[t('error_page_422_option_1'), t('error_page_422_option_2')]}
    parsedError={parsedError}
  />
)

export const InternalServerError: FC<IHTTPErrorProps> = ({ t, parsedError }) => (
  <ErrorContent
    message={t('error_page_5XX_message')}
    errorCode={t('error_page_5XX_error_code', { statusCode: parsedError?.statusCode || 500 })}
    reasonList={[t('error_page_5XX_reason_1'), t('error_page_5XX_reason_2')]}
    optionList={[
      t('error_page_5XX_option_1'),
      t('error_page_5XX_option_2'),
      t('error_page_5XX_option_3'),
    ]}
    parsedError={parsedError}
  />
)

// This error is only for client-side JS errors (i.e. not HTTP nor server-side)
export const ClientSideError: FC<IErrorProps> = ({ t }) => (
  <ErrorContent message={t('error_page_client_message')} />
)

class UndoManager {
  #pendingItems: string[]

  constructor(pendingItems: string[]) {
    this.#pendingItems = pendingItems
  }

  public enqueue(uuid: string): void {
    this.#pendingItems = [...this.#pendingItems, uuid]
  }

  public dequeue(uuid: string): void {
    this.#pendingItems = [...this.#pendingItems.filter((pendingItem) => pendingItem !== uuid)]
  }

  public getPendingItems(): string[] {
    return this.#pendingItems
  }

  public isItemPending(uuid: string): boolean {
    return this.#pendingItems.includes(uuid)
  }

  public hasPendingItems(): boolean {
    return this.#pendingItems.length > 0
  }

  public reset(): void {
    this.#pendingItems = []
  }
}

export default UndoManager

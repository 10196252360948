import {
  ComponentProps,
  ForwardedRef,
  forwardRef,
  FunctionComponent,
  HTMLAttributeAnchorTarget,
  MouseEventHandler,
  ReactElement,
} from 'react'
import ButtonMaterial from '@mui/material/Button'
import { makeStyles } from 'tss-react/mui'
import Typography from '~/src/shared/components/display/Typography'

type TextTransform = 'initial' | 'capitalize' | 'uppercase' | 'lowercase'

type StyleProps = {
  danger: boolean
  textTransform: TextTransform
}

const useStyles = makeStyles<StyleProps>()((theme, { danger, textTransform }) => ({
  button: {
    gap: 10,
    height: 44,

    '& > p': {
      textTransform,
      lineHeight: '1.25rem',
    },

    '& svg': {
      height: '1.25rem',
      width: '1.25rem',
    },

    '&:focus': {
      borderColor: danger ? theme.palette.tb.coral : theme.palette.tb.cerulean,
    },
  },
  containedVariant: {
    backgroundColor: danger ? theme.palette.tb.scarlet : theme.palette.tb.navy,
    borderColor: danger ? theme.palette.tb.scarlet : theme.palette.tb.navy,

    '&:hover:not(:active), &:focus': {
      backgroundColor: danger ? theme.palette.tb.coral : theme.palette.tb.cerulean,
      borderColor: danger ? theme.palette.tb.coral : theme.palette.tb.cerulean,
    },

    '& > p': {
      color: theme.palette.tb.white,
    },
    '&:disabled': {
      '& > p, & svg': {
        color: theme.palette.tb.slate,
      },
    },
  },
  outlinedVariant: {
    borderColor: danger ? theme.palette.tb.scarlet : theme.palette.tb.navy,

    '& > p, & svg': {
      color: danger ? theme.palette.tb.scarlet : theme.palette.tb.navy,
    },
    '&:hover:not(:active), &:focus': {
      borderColor: danger ? theme.palette.tb.coral : theme.palette.tb.cerulean,

      '& > p': {
        borderColor: danger ? theme.palette.tb.coral : theme.palette.tb.navy,
        color: danger ? theme.palette.tb.coral : theme.palette.tb.cerulean,
      },

      '& svg': {
        fill: danger ? theme.palette.tb.coral : theme.palette.tb.cerulean,
      },
    },
    '&:disabled': {
      borderColor: theme.palette.tb.slate,

      '& > p, & svg': {
        color: theme.palette.tb.slate,
      },
    },
  },
  textVariant: {
    '& > p, & svg': {
      color: danger ? theme.palette.tb.scarlet : theme.palette.tb.navy,
    },

    '&:hover:not(:active), &:focus': {
      '& > p': {
        color: danger ? theme.palette.tb.coral : theme.palette.tb.cerulean,
      },

      '& svg': {
        fill: danger ? theme.palette.tb.coral : theme.palette.tb.cerulean,
      },
    },
    '&:disabled': {
      '& > p, & svg': {
        color: theme.palette.tb.slate,
      },
    },
  },
  plain: {
    backgroundColor: theme.palette.tb.white,
    borderColor: theme.palette.tb.white,

    '&&:hover': {
      backgroundColor: danger ? theme.palette.tb.shell : theme.palette.tb.cloud,
      borderColor: danger ? theme.palette.tb.shell : theme.palette.tb.cloud,
    },
  },
  white: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',

    '& > p': {
      color: theme.palette.tb.white,
    },

    '&:hover:not(:active), &:focus': {
      borderColor: 'transparent',

      '& > p': {
        color: theme.palette.tb.white,
      },
    },
  },
  active: {
    backgroundColor: `${theme.palette.tb.cloud} !important`,
  },
  inheritMinWidth: {
    minWidth: 'inherit',
  },
  iconSmall: {
    '& svg': {
      height: '1rem',
      width: '1rem',
    },
  },
  noPadding: {
    padding: 0,
  },
  fullWidth: {
    width: '100%',
  },
  icon: {
    display: 'inline-flex',
    verticalAlign: 'middle',

    '& svg': {
      fill: 'currentColor',
    },
  },
  startIcon: {
    marginRight: 8,
  },
  endIcon: {
    marginLeft: 8,
  },
  imageBackground: {
    borderColor: theme.palette.tb.white,
    backgroundColor: 'transparent',

    '& > p, & svg': {
      color: theme.palette.tb.white,
    },
  },
}))

interface CommonButtonProps {
  label: string
  type?: 'button' | 'reset' | 'submit'
  form?: string
  /** @deprecated overriding the css of a component from the outside is a terrible idea. Please do not use for any new code. Use `variant` and other available props, or ask an ark-component-maintainer if you need more variations **/
  className?: string
  onClick?: MouseEventHandler
  disabled?: boolean
  id?: string
  innerRef?: ForwardedRef<HTMLButtonElement>
  href?: string
  target?: HTMLAttributeAnchorTarget
  startIcon?: ReactElement
  endIcon?: ReactElement
  iconSmall?: boolean
  noPadding?: boolean
  fullWidth?: boolean
  danger?: boolean
  textAlign?: ComponentProps<typeof Typography>['align']
  textTransform?: TextTransform
  bold?: boolean
  inheritMinWidth?: boolean
  active?: boolean
  imageBackground?: boolean
}

interface NonTextVariants extends CommonButtonProps {
  variant?: 'contained' | 'outlined'
}

interface TextVariant extends CommonButtonProps {
  variant?: 'text'
  plain?: boolean
  white?: boolean
}

type ButtonProps = NonTextVariants | TextVariant

const isTextVariant = (props: ButtonProps): props is TextVariant => {
  return props && props.variant === 'text'
}

const ButtonComponent: FunctionComponent<ButtonProps> = (props) => {
  const {
    id,
    href,
    type,
    form,
    label,
    target,
    onClick,
    innerRef,
    disabled,
    startIcon,
    endIcon,
    iconSmall,
    textAlign,
    inheritMinWidth = false,
    noPadding = false,
    fullWidth = false,
    danger = false,
    variant = 'contained',
    textTransform = 'capitalize',
    bold = true,
    active = false,
    imageBackground = false,
    ...rest
  } = props

  const { classes, cx } = useStyles({ danger, textTransform })

  const plain = isTextVariant(props) ? props.plain : false
  const white = isTextVariant(props) ? props.white : false

  return (
    <ButtonMaterial
      // TODO fix this, this is a bandaid for now
      {...rest}
      id={id}
      variant={variant}
      disabled={disabled}
      className={cx(
        classes.button,
        variant === 'outlined' && classes.outlinedVariant,
        variant === 'contained' && classes.containedVariant,
        variant === 'text' && classes.textVariant,
        noPadding && classes.noPadding,
        fullWidth && classes.fullWidth,
        plain && classes.plain,
        white && classes.white,
        iconSmall && classes.iconSmall,
        inheritMinWidth && classes.inheritMinWidth,
        active && classes.active,
        imageBackground && classes.imageBackground
      )}
      type={type}
      onClick={onClick}
      disableRipple
      disableElevation
      ref={innerRef}
      href={href}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore This works fine, just MUI typings are incorrect
      target={target}
      form={form}
    >
      <Typography align={textAlign} variant={bold ? 'bodyBold' : 'body'} noMargin>
        {startIcon && <span className={cx(classes.icon, classes.startIcon)}>{startIcon}</span>}
        {label}
        {endIcon && <span className={cx(classes.icon, classes.endIcon)}>{endIcon}</span>}
      </Typography>
    </ButtonMaterial>
  )
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return <ButtonComponent innerRef={ref} {...props} />
})

Button.displayName = 'Button'

export default Button

const devicesES = {
  'devices.devices': 'Dispositivos',
  'devices.device-setup': 'Configuración de dispositivo',
  'devices.prep-stations': '[ES]Prep Stations',
  'devices.prep-station.name': '[ES]Prep Station Name',
  'devices.prep-station.create': '[ES]Create Prep Station',
  'devices.prep-station.edit': '[ES]Edit Prep Station',
  'devices.prep-station.bulk-update':
    '[ES]Prep station changes will add to the existing setup for all selected items.',
  'devices.prep-station.delete_modal.title':
    "[ES] Are you sure you want to delete the prep station named '{{label}}'?",
  'devices.prep-station.delete': '[ES]Delete Prep Station',
  'devices.prep-station.delete.message': "[ES]'{{label}}' has been deleted from your prep stations",
  'devices.prep-station.configured-on-the-pos': '[ES]Printers must be configured on the POS',
  'devices.prep-station.explanation':
    '[ES] Prep Stations manage how printers are organized and where tickets are printed in your restaurant. Prep stations can house one or more printers. Common prep stations include: drinks, food, and no print. Once your prep stations have been created and you have assigned printers to their respective prep stations through the POS app, you can send tickets to be printed.',
  'devices.prep-station.is_required': '[ES] Prep Station Name is required',
  'devices.view_details': '[ES] View Details',
  'devices.link-device': '[ES] Connect Main Device',
  'devices.link-device-reservations': '[ES] Connect Device',
  'devices.link-modal-descriptions.0':
    '[ES] Your Main Device will link your POS to your Restaurant in Cloud, allowing you to manage your restaurant and view reports from anywhere. If your main device is an iPad, install TouchBistro POS from the App Store, tap Setup My Restaurant, and then select Main Device.',
  'devices.link-modal-descriptions.1':
    '[ES] If your main device is a Mac, <1>download</1> and install TouchBistro Pro Server.',
  'devices.link-modal-descriptions.2':
    '[ES] Enter the device code or scan the QR code to connect the Main Device to your restaurant.',
  'devices.link-modal-descriptions-reservations.0':
    '[ES] Your Device will link TouchBistro Reservations to your Restaurant in Cloud, allowing you to manage your bookings from anywhere.',
  'devices.link-modal-descriptions-reservations.1':
    '[ES] On your iPad, install TouchBistro Reservations from the app store, tap “Connect This Device”, then enter this device code or scan this QR code to connect the device.',
  'devices.relink-device': '[ES] Re-Authenticate Device',
  'devices.relink-device-reservations': '[ES] Re-Authenticate Device',
  'devices.relink-modal-descriptions.0':
    '[ES]A new code has been generated to re-authenticate your Main Device, and link your POS to your Restaurant in the Cloud. Allowing you to continue managing your restaurant and view reports from anywhere.',
  'devices.relink-modal-descriptions.1':
    '[ES]If your main device is an iPad, in Admin Settings, go to Re-authenticate, log-in to your Cloud account, then enter the Device Code or Scan QR Code, provided below.',
  'devices.relink-modal-descriptions.2':
    '[ES]If your main device is a Mac, go to Touchbistro Pro Server in the menu bar, and choose Re-authenticate, then enter the Device Code, provided below.',
  'devices.expire_timer': '[ES]This code will expire in',
  'devices.peripheral-setup': '[ES] Peripheral Setup',
  'devices.peripheral_setup.empty_landing_text':
    "[ES] Your Restaurant doesn't have any connected peripherals.<br />First setup peripherals on your POS to manage them here.",
  'devices.receipt_printer': '[ES] Receipt Printer',
  'devices.kitchen_printer': '[ES] Kitchen Printer',
  'devices.kds': '[ES] KDS',
  'devices.select_prep_stations': '[ES] Select prep stations',
  'devices.no_prep_stations_available': '[ES] No stations available',
  'devices.select_prep_stations.helper': '[ES] Selected prep stations send items to this printer',
  'devices.peripheral_details': '[ES] Peripheral Details',
  'devices.back_to_peripheral_setup': '[ES] Back to Peripheral Setup',
  'devices.device_name': '[ES] Device Name',
  'devices.printer_type': '[ES] Printer Type',
  'devices.printer_model': '[ES] Printer Model',
  'devices.ip_address': '[ES] IP Address',
  'devices.cash_drawer': '[ES] Cash Drawer',
  'devices.last_updated': '[ES] Last Updated',
  'devices.back-to-setup': '[ES] Back to Device Setup',
  'devices.device_model': '[ES] Device Model',
  'devices.device_id': '[ES] Device ID',
  'devices.pos_version': '[ES] POS Version',
  'devices.host_app_version': '[ES] Host App Version',
  'devices.os_version': '[ES] OS Version',
  'devices.last_connected': '[ES] Last Connected',
}

export default devicesES

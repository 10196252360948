const guestEngagementES = {
  'ge.guest_engagement': 'Interacción con los clientes',
  'ge.no_results_found': 'Todavia no hay beneficios de lealtad.',
  'ge.create_new': 'Crea un nuevo beneficio',
  'ge.rewards': 'Beneficios de lealtad',
  'ge.reward': '[ES] reward',
  'ge.showing': '[ES]Showing',
  'ge.set_expiring_offer_reminder_buffer_time': '[ES]Set Expiring Offer Reminder Buffer Time',
  'ge.subscriptions': '[ES]Subscriptions',
  'ge.expiring_promotional_offer': '[ES]Expiring Promotional Offer Reminder Buffer',
  'ge.offer_reminder_1': '[ES]Offer Reminder Buffer 1 (days)',
  'ge.offer_reminder_2': '[ES]Offer Reminder Buffer 2 (days)',
  'ge.offer_subscriptions_tb_info':
    '[ES]With TouchBistro you can offer subscriptions that include automated fees, promotions, and emails.',
  'ge.offer_subscriptions': '[ES]Offer Subscriptions',
  'ge.api_key': '[ES]API Key',
  'ge.copy_and_paste_your_key_here': '[ES]Copy and paste your key here',
  'ge.moonclerk_api_key_saved': '[ES]Moonclerk Api Key saved',
  'ge.set_buffer_time_saved': '[ES]Your set buffer time was saved',
  'ge.api_subscription_description_1': "[ES]To begin offering subscriptions, be sure you've ",
  'ge.follow_the_instructions': '[ES]followed the instructions',
  'ge.api_subscription_description_2':
    '[ES]to set up your MoonClerk account and obtain your API Key. Enter your API Key below and click Save',
  'ge.confirm_cancel_moonclerk_dialog_msg':
    "[ES]Your account is already linked to MoonClerk and setup to offer subscriptions. To unlink your account, click the below button. This will remove the link to any activity in MoonClerk, but will not stop any Subscription related promotions or emails you've created.",
  'ge.create_new_reward': '[ES] Create New Reward',
  'ge.save': '[ES] Save',
  'ge.save_changes': '[ES] Save Changes',
  'ge.confirm_and_save': '[ES] Confirm & Save',
  'ge.cancel': '[ES] Cancel',
  'ge.close': 'Cerrar',
  'ge.continue': '[ES] Continue',
  'ge.apply': '[ES] Apply',
  'ge.step': '[ES] Step',
  'ge.name_placeholder': '[ES] Enter a name',
  'ge.status': '[ES] Status',
  'ge.status_active': 'Activo',
  'ge.status_inactive': '[ES] Inactive',
  'ge.status_all': '[ES] All',
  'ge.max_discount_amount': '[ES] Max Discount Amount',
  'ge.discount_percentage': 'Porcentaje de Descuento',
  'ge.reward_points': '[ES] Reward Points',
  'ge.reward_points.desc':
    '[ES] For Rewards that are ONLY being used in a Promotion or Item-Based Program, set this to 0 (zero).',
  'ge.reward_description': '[ES] Description',
  'ge.reward_description_placeholder': '[ES] Enter a description',
  'ge.select_reward_type': '[ES] Select Reward Type:',
  'ge.reward_details': '[ES] Reward Details:',
  'ge.CHECKFIXED': '[ES] Bill Discount - Fixed',
  'ge.CHECKFIXED.desc': '[ES] A dollar amount is taken off the bill',
  'ge.CHECKPERCENTAGE': '[ES] Bill Discount - Percentage',
  'ge.CHECKPERCENTAGE.desc': '[ES] A percentage is taken off the bill',
  'ge.ITEMFIXED': '[ES] Item Discount - Fixed',
  'ge.ITEMFIXED.desc': '[ES] A dollar amount is taken off the highest priced qualifying item',
  'ge.ITEMPERCENTAGE': '[ES] Item Discount - Percentage',
  'ge.ITEMPERCENTAGE.desc': '[ES] A percentage is taken off the highest priced qualifying item',
  'ge.ITEMBOGO': '[ES] Buy X Get Y',
  'ge.ITEMBOGO.desc': "[ES] A percentage is taken off the 'Get' item when a 'Buy' item is bought",
  'ge.reward.save.success': '[ES] Reward created successfully',
  'ge.reward.edit.success': '[ES] Reward edited successfully',
  'ge.delete.success': '[ES] Item was successfully deleted.',
  'ge.reward_name': '[ES] Reward Name',
  'ge.type': '[ES] Type',
  'ge.points': '[ES] Points',
  'ge.description': '[ES] Description',
  'ge.active': '[ES] Active',
  'ge.inactive': '[ES] Inactive',
  'ge.all': '[ES] All',
  'ge.add': '[ES] Add',
  'ge.hours': '[ES] Hours',
  'ge.mon': '[ES] Monday',
  'ge.tue': '[ES] Tuesday',
  'ge.wed': '[ES] Wednesday',
  'ge.thu': '[ES] Thursday',
  'ge.fri': '[ES] Friday',
  'ge.sat': '[ES] Saturday',
  'ge.sun': '[ES] Sunday',
  'ge.reward_availability': '[ES] When can this Reward be redeemed?',
  'ge.all_times': '[ES] ALL times',
  'ge.selected_days': '[ES] Selected Days & Times',
  'ge.create_multiple_times': '[ES] You can create multiple redemption times.',
  'ge.click_to_remove_time_block': '[ES] Click x to remove a time block',
  'ge.available_entire_day': '[ES] Available the entire day',
  'ge.starting_from': '[ES] Starting From',
  'ge.up_until': '[ES] Up Until',
  'ge.days_error_message': '[ES] Please select the day(s) this reward is available.',
  'ge.program_days_error_message': '[ES] Please select the day(s) this program is available.',
  'ge.select-modal-confirm-title': '[ES] Please confirm',
  'ge.menu-groups': '[ES] Menu Groups',
  'ge.menu-items': '[ES] Menu Items',
  'ge.menu.no_menu_groups_message': '[ES] There are no Menu Groups to Display',
  'ge.menu.no_menu_items_message': '[ES] There are no Menu Items to Display',
  'ge.menu.prohibited_groups_items': '[ES] Prohibited Groups/Items',
  'ge.menu.applicable_groups_items': '[ES] Applicable Groups/Items',
  'ge.menu.filter_by_groups_items': 'Filtrar por categoría / artículo',
  'ge.menu.bogo_buy_groups_items': '[ES] Buy Groups/Items',
  'ge.menu.bogo_get_groups_items': '[ES] Get Groups/Items',
  'ge.menu.remove_applicable_group_item': '[ES] Click x to remove an Applicable Group/Item.',
  'ge.menu.remove_prohibited_group_item': '[ES] Click x to remove a Prohibited Group/Item.',
  'ge.menu.remove_bogo_buy_group_item': '[ES] Click x to remove a Buy Group/Item.',
  'ge.menu.remove_bogo_get_group_item': '[ES] Click x to remove a Get Group/Item.',
  'ge.menu.all_venues': 'All Venues',
  'ge.menu.all_selected_venues': '[ES] All Selected Venues ({{current}} of {{total}})',
  'ge.select-menu-items-groups-btn': '[ES] Select Groups/Items',
  'ge.menu.refresh-failed': '[ES] An error occurred while refreshing the Menu, please try again.',
  'ge.menu.selection_based_on_venue_filter': '[ES] This selection is based on the Venue filter.',
  'ge.menu.refresh_menu': '[ES] Refresh Menu',
  'ge.menu.view_menu_items': '[ES] View Menu Items',
  'ge.menu.filtering_by': '[ES] Filtering by',
  'ge.menu.back_to_all_menu_items': '[ES] Back to all Menu Items',
  'ge.select-modal-confirm-category-text':
    '[ES] Selecting this Menu Group will override your Menu Items selected in this Menu Group.',
  'ge.select-modal-confirm-item-text':
    '[ES] Deselecting this Menu Item will deselect the entire Menu Group to which it belongs.',
  'ge.select-modal-confirm-ending': '[ES] Are you sure you want to continue?',
  'ge.timeslot.overlapping_error': '[ES] Ensure there are no overlapping time slots below',
  'ge.timeslot.start_differ_end_error': '[ES] Start time should differ from end time.',
  'ge.timeslot.select_days_and_hours':
    "[ES] Please select the days and hours, and click the 'Add' button to create the timeslots for this reward.",
  'ge.edit_reward': '[ES]Edit Reward',
  'ge.data-import.instructions':
    'Use esta página para importar un archivo con extensión .csv que contiene los datos de los clientes. Por favor asegurase de que el archivo csv tiene los siguientes valores en el encabezamiento',
  'ge.data-import.header': 'Importación de datos de clientes',
  'ge.data-import.action-button-choose-file': 'Escoja su archivo',
  'ge.data-import.action-button-reset-file': 'Borrar y empezar de nuevo',
  'ge.data-import.upload.success':
    "¡El archivo es válida! Haga clic en el botón 'Enviar' para subirlo.",
  'ge.data-import.upload.invalid-file':
    '¡El archivo con los datos de los clientes no es válida! Ajusta el archivo e intenta de nuevo.',
  'ge.data-import.send.success': 'Los datos de los clientes se enviaron con éxito',
  'ge.data-import.action-button-send-request': 'Enviar',
  'ge.reward.menu.filter_by_menu_group_items': 'Agregar categoría y/o artículo de menú al filtro',
  'ge.reward.menu.prohibited_menu_group_items':
    '[ES] Exclude Menu Group and/or Menu Items from the Reward.',
  'ge.reward.menu.applicable_menu_group_items':
    '[ES] Apply Menu Groups and/or Menu Items to the Reward.',
  'ge.reward.menu.bogo_buy_menu_group_items':
    '[ES] Apply Menu Groups and/or Menu Items to the Reward.',
  'ge.reward.menu.bogo_get_menu_group_items':
    '[ES] Apply Menu Groups and/or Menu Items to the Reward.',
  'ge.reward.select-menu-items-groups': '[ES] Select Menu Groups and/or Menu Items for the Reward.',
  'ge.reward.menu.group.placeholder': '[ES] Search by Group name',
  'ge.reward.menu.item.placeholder': '[ES] Search by Item name',
  'ge.reward.menu.item_in_group.placeholder':
    '[ES] Search by Menu Item name within {{categoryName}}',
  'ge.reward.menu.select_all': '[ES] Select All',
  'ge.reward.menu.clear': '[ES] Clear',
  'ge.reward.menu.in_venue_only': '[ES] In-Venue Only',
  'ge.reward.menu.available_online': '[ES] Available Online',
  'ge.searchBar.no_results': 'No hay resultados',
  'ge.venues': '[ES] Venues',
  'ge.apply_to_all_venues': '[ES] Apply this Reward to ALL your Venues',
  'ge.select_venues': '[ES] Select Venues',
  'ge.see_more': '[ES] See More',
  'ge.remove_prompt': '[ES] Click x to remove a Venue.',
  'ge.select_a_venue': '[ES] At least 1 Venue needs to be selected.',
  'ge.reward.venues.select-venues': 'Seleccionar lugares',
  'ge.reward.venues.cancel': 'Cancelar',
  'ge.reward.venues.apply': 'Aplicar',
  'ge.reward.venues.searchBar.placeholder': 'Buscar',
  'ge.reward.venues.select_all': 'Seleccionar todo',
  'ge.reward.venues.clear': 'Eliminar',
  'ge.remove_location_modal.heading': '[ES] Are you sure you want to change Venues selection?',
  'ge.remove_location_modal.body':
    '[ES] By changing your Venues you will lose ALL your Applicable/Prohibited Groups/Items selection.',
  'ge.reward.menu.select_category_or_item':
    '[ES] At least 1 Menu Category or Menu Item needs to be selected.',
  'ge.marketing.promotions': 'Promociones',
  'ge.marketing.promotions.promotion_name': 'Nombre de la promoción',
  'ge.marketing.promotions.no_results_found': '[ES] There are no Promotions to be displayed.',
  'ge.marketing.promotions.no_rewards_found': '[ES] There are no Rewards to be displayed.',
  'ge.marketing.promotions.create': '[ES] Create New Promotion',
  'ge.marketing.promotions.edit': '[ES] Edit Promotion',
  'ge.marketing.promotions.create.success': '[ES] Promotion created successfully',
  'ge.marketing.promotions.edit.success': '[ES] Promotion updated successfully',
  'ge.marketing.promotions.details': '[ES] Promotion Details',
  'ge.marketing.promotions.guest_page': '[ES] Promotion Guest Page',
  'ge.marketing.promotions.new': '[ES] New Promotion',
  'ge.marketing.promotions.reward_blocks.expires_in': '[ES] Expires in: {{days}} day(s)',
  'ge.marketing.promotions.reward_blocks.quantity': '[ES] Quantity',
  'ge.marketing.promotions.reward_blocks.helper': '[ES] Click x to remove',
  'ge.marketing.promotions.rewards_to_send': '[ES] Reward(s) to Send',
  'ge.marketing.promotions.rewards_to_send.notice':
    '[ES] A Promotion needs to have at least one Reward.',
  'ge.marketing.promotions.rewards_to_send.placeholder': '[ES] Select Reward',
  'ge.marketing.promotions.rewards_to_send.error': '[ES] Please select a Reward',
  'ge.marketing.promotions.rewards_to_send.create_reward': '[ES] Create a New Reward',
  'ge.marketing.promotions.rewards_to_send.create_reward_modal.heading':
    '[ES] You will be redirected to the Rewards section.',
  'ge.marketing.promotions.rewards_to_send.create_reward_modal.body':
    '[ES] The changes you made to the page will not be saved.\nAre you sure you want to leave?',
  'ge.marketing.promotions.rewards_to_send.no_rewards': '[ES] No Rewards to Display',
  'ge.marketing.promotions.days_till_expire': '[ES] # Days Until Expiration',
  'ge.marketing.promotions.quantity_per_user': '[ES] Quantity Per User',
  'ge.marketing.promotions.internal_notes': '[ES] Internal Notes',
  'ge.marketing.promotions.internal_notes.placeholder': '[ES] Enter a Note',
  'ge.marketing.promotions.internal_notes.notice': '[ES] Only visible to Admin.',
  'ge.marketing.promotions.reports.no_results_found':
    '[ES] You currently have no results to be displayed.',
  'ge.marketing.promotions.reports.user_count': 'Numero de usuarios',
  'ge.marketing.promotions.reports.rewards_sent_count': 'Número de recompensas enviadas',
  'ge.marketing.promotions.reports.rewards_redeemed_count': 'Número de recompensas redimidas',
  'ge.marketing.promotions.reports.rewards_redeemed_percentage':
    'Porcentaje de recompensas redimidas',
  'ge.marketing.promotions.reports.total_spend': 'Total de $ gastados',
  'ge.marketing.promotions.reports.average_spend_per_check': 'Promedio de $ gastados por cheque',
  'ge.marketing.promotions.reports.user_report_guest_name': 'Nombre del cliente',
  'ge.marketing.promotions.reports.user_report_email': 'Correo electrónico',
  'ge.marketing.promotions.reports.user_report_phone': 'Número de teléfono',
  'ge.marketing.promotions.reports.user_report_rewards_name': 'Nombre de la recompensa',
  'ge.marketing.promotions.reports.user_report_sent_date': 'La fecha en que fue recibido',
  'ge.marketing.promotions.reports.user_report_expiration_date': 'Fecha de caducidad',
  'ge.marketing.promotions.reports.user_report_redeemed_date': 'Fecha de redención',
  'ge.marketing.promotions.reports.user_report_ticket_sub_total': 'Dólares gastados para redimir',
  'ge.marketing.promotions.reports': 'Informes de promoción',
  'ge.marketing.promotions.reports.search_placeholder':
    'Buscar por nombre, correo electrónico o número de teléfono',
  'ge.marketing.promotions.reports.status_UNREDEEMED': 'Recompensas no redimidas',
  'ge.marketing.promotions.reports.status_REDEEMED': 'Recompensas redimidas',
  'ge.marketing.promotions.reports.status_EXPIRED': 'Recompensas caducadas',
  'ge.marketing.promotions.reports.status_ALL': 'Todas las recompensas',
  'ge.marketing.promotions.reports.show_by': 'Mostrar por:',
  'ge.marketing.promotions.reports.remove_all_rewards': 'Eliminar todas las recompensas',
  'ge.marketing.promotions.reports.remove_all_rewards_modal_title':
    '¿Estás seguro de que quieres continuar?',
  'ge.marketing.promotions.reports.remove_all_rewards_modal_body':
    'Esta acción eliminará TODAS las recompensas de TODAS las cuentas de usuario. Esto NO se puede deshacer.',
  'ge.marketing.promotions.reports.update_rewards_successfully':
    'Recompensas promocionales actualizadas con éxito.',
  'ge.marketing.promotions.reports.redeem_rewards': 'Redimir la recompensa',
  'ge.marketing.promotions.reports.remove_rewards_from_user': 'Eliminar la recompensa del usuario',
  'ge.marketing.promotions.reports.redeem_rewards_modal_title':
    '¿Estás seguro de que quieres redimir esta recompensa?',
  'ge.marketing.promotions.reports.remove_rewards_modal_title':
    '¿Estás seguro de que quieres eliminar esta recompensa?',
  'ge.marketing.promotions.view_report': 'Ver el informe',
  'ge.of': 'de',
  'ge.marketing.promotions.guest_page.promo_link': '[ES] Guest Promotion Page Link',
  'ge.marketing.promotions.guest_page.copy_btn': '[ES] Copy Link',
  'ge.marketing.promotions.guest_page.copy_success': '[ES] Link was successfully copied',
  'ge.marketing.promotions.guest_page.reward_name':
    '[ES] Reward Name to Appear on Guest Promotion Page',
  'ge.marketing.promotions.guest_page.reward_description':
    '[ES] Reward Description to Appear on Guest Promotion Page',
  'ge.marketing.promotions.guest_page.max_rewards_per_user':
    '[ES] Max TOTAL Rewards by SINGLE user',
  'ge.marketing.promotions.guest_page.max_rewards': '[ES] Max TOTAL Rewards for ALL users',
  'ge.marketing.promotions.guest_page.link_exp': '[ES] Link Expiration Date',
  'ge.guest_reporting': 'Estadísticas de Clientes',
  'ge.guest_reporting.userDisplayName': 'Nombre de Usuario',
  'ge.guest_reporting.marketingEmailConsent': '[ES] Email opt-in',
  'ge.guest_reporting.totalVisits': 'Todas las Visitas',
  'ge.guest_reporting.totalSpend': 'Gasto Total',
  'ge.guest_reporting.totalSpendOnline': 'Gasto Total en Línea',
  'ge.guest_reporting.totalSpendInStore': 'Gasto Total en la Tienda',
  'ge.guest_reporting.averageSpend': 'Promedio de Gasto',
  'ge.guest_reporting.totalChecks': 'Facturas',
  'ge.guest_reporting.totalPointsEarned': 'Puntos Ganados',
  'ge.guest_reporting.totalItemsClaimed': 'Artículos Reclamados',
  'ge.guest_reporting.totalPointsRedeemed': 'Puntos Redimidas',
  'ge.guest_reporting.totalMilestonesRedeemed': 'Hitos Redimidas',
  'ge.guest_reporting.totalOffersRedeemed': 'Ofertas Redeemed',
  'ge.guest_reporting.onlineOrdersPlaced': 'Pedidos en Línea Realizados',
  'ge.guest_reporting.currentPointsBalance': 'Saldo de Puntos',
  'ge.guest_reporting.birthdayRelative': 'Cumpleaño',
  'ge.guest_reporting.registrationDateRelative': 'Fecha de Registro',
  'ge.guest_reporting.lastVisitDateRelative': 'Fecha de la Última Visita',
  'ge.guest_reporting.no_results_found': 'No hay estadísticas de clientes para mostrar todavía.',
  'ge.guest_reporting.guest_data_update_alert':
    '[ES] Guest information is updated every 24 hours. The data on this page may not be up to date.',
  'ge.guest_reporting.column_header_info': '[ES] Click on a column header to filter the results.',
  'ge.guest_reporting.export_button': 'Export',
  'ge.guest_reporting.show_guest_search': '[ES] Show Guest Search',
  'ge.guest_reporting.hide_guest_search': 'Ocultar búsqueda de clientes',
  'ge.guest_reporting.selected_filters': '[ES] Selected Filters',
  'ge.guest_reporting.save_filters': '[ES] Save Filters',
  'ge.guest_reporting.LAST_WEEK': '[ES] Last Week',
  'ge.guest_reporting.THIS_WEEK': '[ES] This Week',
  'ge.guest_reporting.LAST_MONTH': '[ES] Last Month',
  'ge.guest_reporting.THIS_MONTH': '[ES] This Month',
  'ge.guest_reporting.NEXT_MONTH': '[ES] Next Month',
  'ge.guest_reporting.LAST_YEAR': '[ES] Last Year',
  'ge.guest_reporting.THIS_YEAR': '[ES] This Year',
  'ge.guest_reporting.BEFORE_30_DAYS_AGO': '[ES] Before 30 Days Ago',
  'ge.guest_reporting.BEFORE_90_DAYS_AGO': '[ES] Before 90 Days Ago',
  'ge.guest_reporting.BEFORE_180_DAYS_AGO': '[ES] Before 180 Days Ago',
  'ge.guest_reporting.BEFORE_365_DAYS_AGO': '[ES] Before 1 Year Ago',
  'ge.guest_reporting.ascending': '[ES] Ascending',
  'ge.guest_reporting.descending': '[ES] Descending',
  'ge.guest_reporting.sort_by': '[ES] Sort {{field}} By',
  'ge.guest_reporting.filter_by': '[ES] Filter {{field}} By',
  'ge.guest_reporting.GREATER_THAN': '[ES] Greater than',
  'ge.guest_reporting.LESS_THAN': '[ES] Lesser than',
  'ge.guest_reporting.GREATER_THAN_EQUAL': '[ES] Greater than (or Equal to)',
  'ge.guest_reporting.LESS_THAN_EQUAL': '[ES] Lesser than (or Equal to)',
  'ge.guest_reporting.tags_button': '[ES] Tags',
  'ge.guest_reporting.manage_tags': '[ES] Manage Tags',
  'ge.guest_reporting.create_new_tag': '[ES] Create New Tag',
  'ge.guest_reporting.add_tag': '[ES] Add Tag',
  'ge.guest_reporting.no_tags_available': '[ES] No Tags available',
  'ge.guest_reporting.no_locations_available': '[ES] No Locations available',
  'ge.guest_reporting.no_programs_available': '[ES] No Programs available',
  'ge.guest_reporting.no_promotions_available': '[ES] No Promotions available',
  'ge.guest_reporting.add_to_filtered_users': '[ES] Add to Filtered Users',
  'ge.guest_reporting.edit_tag': '[ES] Edit Tag',
  'ge.guest_reporting.tag_name_placeholder': '[ES] Enter a name',
  'ge.guest_reporting.customer_facing': "[ES] Allow guests to see that they've received this Tag?",
  'ge.guest_reporting.customer_self_subscribe': '[ES] Allow guests to self subscribe to this Tag?',
  'ge.guest_reporting.edited_tag_saved': '[ES] Tag "{{tag}}" successfully saved',
  'ge.guest_reporting.created_tag_saved': '[ES] Tag "{{tag}}" successfully added',
  'ge.guest_reporting.add_tag_to_users': '[ES] Tag "{{tag}}" successfully saved to {{users}} users',
  'ge.guest_reporting.add_tag_to_users_confirmation':
    '[ES] Are you sure you want to add the Tag "{{tag}}" to {{users}} users in this filtered search?',
  'ge.guest_reporting.remove_tag_confirmation': '[ES] Are you sure you want to remove this Tag?',
  'ge.guest_reporting.remove_tag_to_users': '[ES] Tag "{{tag}}" successfully removed',
  'ge.guest_reporting.advanced_filters': 'Filtros avanzados',
  'ge.guest_reporting.advanced_filters.filter_by_tags': 'Filtrar por etiquetas',
  'ge.guest_reporting.advanced_filters.filter_by_loyalty_program':
    'Filtrar por programa de lealtad',
  'ge.guest_reporting.advanced_filters.lt': 'El número actual de artículos es inferior a',
  'ge.guest_reporting.advanced_filters.gt': 'El número actual de artículos es mayor que',
  'ge.guest_reporting.advanced_filters.lte': 'El número actual de artículos es menor o igual que',
  'ge.guest_reporting.advanced_filters.select_a_loyalty_program': '[ES] Select a Program',
  'ge.guest_reporting.advanced_filters.filter_by_location': 'Filtrar por establecimiento',
  'ge.guest_reporting.advanced_filters.filter_by_items_ordered': 'Filtrar por artículos pedidos',
  'ge.guest_reporting.tagIds': 'Etiquetas',
  'ge.guest_reporting.homeLocationIds': 'Establecimientos',
  'ge.guest_reporting.orderedCategoryIds': 'Artículos de menú en categorías',
  'ge.guest_reporting.orderedMenuItemIds': 'Artículos de menú',
  'ge.guest_reporting.greater_than_or_equal': 'Mayor qué o igual a',
  'ge.guest_reporting.lifetime': 'Siempre',
  'ge.guest_reporting.last_one_year': 'Durante el último año',
  'ge.guest_reporting.last_90_days': 'Durante los últimos 90 días',
  'ge.guest_reporting.last_30_days': 'Durante los últimos 30 días',
  'ge.guest_reporting.last_7_days': 'Durante los últimos 7 días',
  'ge.guest_reporting.userFirstName': 'Nombre',
  'ge.guest_reporting.userLastName': 'Apellido',
  'ge.guest_reporting.userEmail': 'Correo electrónico',
  'ge.guest_reporting.userPhoneNumber': 'Número de teléfono',
  'ge.guest_reporting.submit': 'Enviar',
  'ge.guest_reporting.date_range': 'Rango de fechas',
  'ge.guest_reporting.user_details': '[ES] User Details',
  'ge.guest_reporting.activity_feed': '[ES] Activity Feed',
  'ge.guest_reporting.select_tags': '[ES] Select Tags',
  'ge.guest_reporting.select_locations': '[ES] Select Locations',
  'ge.guest_reporting.PASSPORTITEMMANUALLYADDED':
    '[ES] Loyalty Program Item Manually Added By Admin - Menu Item: {{menuItemName}} - Quantity: {{quantity}} - Loyalty Program: {{rewardBlockName}} - New Balance: {{numberOfItems}} item(s)',
  'ge.guest_reporting.PASSPORTITEMMANUALLYREMOVED':
    '[ES] Loyalty Program Item Manually Removed By Admin - Menu Item: {{menuItemName}} - Quantity: {{quantity}} - Loyalty Program: {{rewardBlockName}} - New Balance: {{numberOfItems}} item(s)',
  'ge.guest_reporting.PASSPORTITEMRECONCILED':
    '[ES] Loyalty Program Item Earned - Menu Item: {{menuItemName}} - Loyalty Program: {{rewardBlockName}}',
  'ge.guest_reporting.POINTSEARNED':
    '[ES] Points {{action}} - {{updatePoints}} point(s) - New Balance: {{newPointsBalance}} point(s)',
  'ge.guest_reporting.POINTSMANUALLYADDED':
    '[ES] Points Manually {{action}} By Admin - {{updatePoints}} point(s) - New Balance: {{newPointsBalance}} point(s) - Comment: {{comment}}',
  'ge.guest_reporting.REDEMPTIONVOIDED_PROMOTION':
    '[ES] Promotion Reward Redemption Voided - Reward: {{rewardName}}',
  'ge.guest_reporting.REDEMPTIONVOIDED_MILESTONE':
    '[ES] Milestone Reward Redemption Voided - Reward: {{rewardName}}',
  'ge.guest_reporting.REDEMPTIONVOIDED_POINTS':
    '[ES] Reward Redemption Voided - Reward: {{rewardName}} - {{updatePoints}} point(s) - New Balance: {{newPointsBalance}} point(s)',
  'ge.guest_reporting.REWARDREDEEMED_PROMOTION':
    '[ES] Promotion Reward Redeemed - Reward: {{rewardName}}',
  'ge.guest_reporting.REWARDREDEEMED_MILESTONE':
    '[ES] Milestone Reward Redeemed - Reward: {{rewardName}} - Loyalty Program: {{rewardBlockName}}',
  'ge.guest_reporting.REWARDREDEEMED_POINTS':
    '[ES] Reward Redeemed - Reward: {{rewardName}} - {{updatePoints}} point(s) - New Balance: {{newPointsBalance}} point(s)',
  'ge.guest_reporting.restaurant': '[ES] at {{restaurantName}}',
  'ge.guest_reporting.removed': '[ES] Removed',
  'ge.guest_reporting.earned': '[ES] Earned',
  'ge.guest_reporting.added': '[ES] Added',
  'ge.guest_reporting.view_bill': '[ES] View Bill',
  'ge.guest_reporting.no_activity_available': '[ES] No User Activity Yet',
  'ge.guest_reporting.promotions_and_comm': '[ES] Promotions & Communications',
  'ge.guest_reporting.communicate_with_guests': '[ES] Communicate With Guests',
  'ge.guest_reporting.promotions': '[ES] Promotions',
  'ge.guest_reporting.email': '[ES] Email',
  'ge.guest_reporting.in_app_messaging': '[ES] In-App Messaging',
  'ge.guest_reporting.message_title': '[ES] Message Title',
  'ge.guest_reporting.valid_message_sent': '[ES] Your message was sent successfully',
  'ge.guest_reporting.message': '[ES] Message',
  'ge.guest_reporting.promotion_to_send': '[ES] Promotion to Send',
  'ge.guest_reporting.send_promotions_success':
    '[ES] Success! Users have been added to the promotion',
  'ge.guest_reporting.send_to_guests': '[ES] Send to {{guestNum}} Guest(s)',
  'ge.guest_reporting.configure_send_grid': '[ES] Configure SendGrid',
  'ge.guest_reporting.connect_send_grid_account_msg':
    "[ES] Enter your API key and the email address used to register your SendGrid account to connect. \nEmails sent to guests will be 'From' this email address.",
  'ge.guest_reporting.configure_send_grid_info_message':
    '[ES] To send emails with TouchBistro Marketing, connect your SendGrid account.',
  'ge.guest_reporting.select_a_promotion': '[ES] Select a Promotion',
  'ge.guest_reporting.api_key': '[ES] API Key',
  'ge.guest_reporting.from_email': '[ES] "From" Email',
  'ge.guest_reporting.valid_update_api_key': '[ES] Update Successful',
  'ge.guest_reporting.email_template': '[ES] Email Template',
  'ge.guest_reporting.choose_a_template': '[ES] Choose a template',
  'ge.guest_reporting.preview_template': '[ES] Preview Template',
  'ge.guest_reporting.manage_templates_info':
    '[ES] To manage your templates, visit your SendGrid Dashboard and click Email API, then Dynamic Templates in the left navigation',
  'ge.guest_reporting.change_email_text': '[ES] Change SendGrid API Key and "from" email address',
  'ge.guest_reporting.back': '[ES] Back',
  'ge.guest_reporting.send_grid_success_message':
    '[ES] Your emails have been successfully added to the queue and will be sent shortly',
  'ge.guest_reporting.trouble_connecting_sendgrid_header':
    '[ES] We are having trouble connecting to your SendGrid Account.',
  'ge.guest_reporting.trouble_connecting_sendgrid_num_1': '[ES] 1. Ensure that your ',
  'ge.guest_reporting.trouble_connecting_sendgrid_num_1_link': '[ES] SendGrid account',
  'ge.guest_reporting.trouble_connecting_sendgrid_num_1_end': '[ES]  is active',
  'ge.guest_reporting.trouble_connecting_sendgrid_num_2': '[ES] 2. Double check that your ',
  'ge.guest_reporting.trouble_connecting_sendgrid_num_2_link': '[ES] SendGrid API key',
  'ge.guest_reporting.trouble_connecting_sendgrid_num_2_end': '[ES]  is correct.',
  'ge.guest_reporting.subtotal': '[ES] Subtotal',
  'ge.guest_reporting.online_order': '[ES] Online Order',
  'ge.guest_reporting.quantity': '[ES] Quantity',
  'ge.guest_reporting.create_a_new_saved_filter': '[ES] Create A New Saved Filter',
  'ge.guest_reporting.updated_saved_filter': '[ES] Update Saved Filter',
  'ge.guest_reporting.filter_name': '[ES] Filter Name',
  'ge.guest_reporting.filter_saved': '[ES] Filter "{{filterName}}" Saved',
  'ge.guest_reporting.filter_updated': '[ES] Filter "{{filterName}}" Updated',
  'ge.guest_reporting.filter_applied': '[ES] Saved Filter: {{customFilterName}}',
  'ge.guest_reporting.guest_list': '[ES] Guest List',
  'ge.guest_reporting.your_saved_filters': '[ES] Your Saved Filters',
  'ge.guest_reporting.no_filters_found': '[ES] There are no Filters to be displayed.',
  'ge.guest_reporting.update_or_create_filters':
    '[ES] Do you want to update this filter or create a new filter?',
  'ge.guest_reporting.update_this_filter': '[ES] Update This Filter',
  'ge.guest_reporting.create_a_filter': '[ES] Create New Filter',
  'ge.guest_reporting.filter_delete': '[ES] Are you sure you want to delete this filter?',
  'ge.guest_reporting.filter_delete_success': '[ES] Filter {{filterName}} was deleted successfully',
  'ge.guest_feedback': 'Comentarios del cliente',
  'ge.guest_feedback.experience_feedback': 'Comentarios sobre la experiencia',
  'ge.guest_feedback.item_feedback': 'Comentarios sobre artículos',
  'ge.guest_feedback.placeholder': 'Buscar comentarios',
  'ge.guest_feedback.buffer_time': 'Establecer tiempo de búfer de notificación de mensaje de texto',
  'ge.guest_feedback.overall_rating': 'Calificación general',
  'ge.guest_feedback.based_on_ratings': 'Basado en {{ratingAmount}} calificacion(es)',
  'ge.guest_feedback.since': 'Desde {{sinceDate}}',
  'ge.guest_feedback.filter_by': 'Filtrado por',
  'ge.guest_feedback.stars': '{{star}} Estrellas',
  'ge.guest_feedback.sort_by': 'Ordenar por',
  'ge.guest_feedback.most_recent': 'Más reciente',
  'ge.guest_feedback.high_to_low': 'Calificación de alta a baja',
  'ge.guest_feedback.low_to_high': 'Calificación baja a alta',
  'ge.guest_feedback.alpha': '[ES] Alphabetical',
  'ge.guest_feedback.only_comments': 'Solo calificaciones con comentarios',
  'ge.guest_feedback.rating': 'Calificaciones',
  'ge.guest_feedback.review': 'Comentarios',
  'ge.guest_feedback.visited': 'Fecha de visita: {{dateVisited}}',
  'ge.guest_feedback.no_results_found': 'No hay comentarios para mostrar.',
  'ge.guest_feedback.all': 'Todos los',
  'ge.guest_feedback.venue': 'Lugares',
  'ge.guest_feedback.all_dates': 'Todas las fechas',
  'ge.guest_feedback.diner_text_notify_buffer': '[ES]Diner Feedback Text Notification Buffer',
  'ge.guest_feedback.text_notify_buffer_time':
    '[ES]Buffer Time for Sending the Feedback Text Notification (minutes)',
  'ge.guest_reporting.suggested_filters': '[ES] Suggested Filters',
  'ge.guest_reporting.suggested_filters.filter_desc': '[ES] Filter Description',
  'ge.guest_reporting.suggested_filters.loyalty_club': '[ES] Loyalty Club',
  'ge.guest_reporting.suggested_filters.loyalty_club.desc':
    '[ES] Find guests who are far along in your Beer Club, Lunch Club, etc.',
  'ge.guest_reporting.suggested_filters.birthdays': '[ES] Birthdays',
  'ge.guest_reporting.suggested_filters.birthdays.desc':
    '[ES] Identify guests with previous or upcoming birthdays',
  'ge.guest_reporting.suggested_filters.new_guests': '[ES] New Guests',
  'ge.guest_reporting.suggested_filters.new_guests.desc':
    '[ES] Anyone whose account was recently activated',
  'ge.guest_reporting.suggested_filters.we_love_you': '[ES] We Love You',
  'ge.guest_reporting.suggested_filters.we_love_you.desc':
    '[ES] Identify guests who have visited frequently or have spent a lot recently',
  'ge.guest_reporting.suggested_filters.we_miss_you': '[ES] We Miss You',
  'ge.guest_reporting.suggested_filters.we_miss_you.desc':
    '[ES] Identify big spenders that have not visited recently',
  'ge.guest_reporting.opt_out_marketing_email_dialog_title':
    '[ES] Are you sure you want to remove this guest from future email marketing?',
  'ge.guest_reporting.opt_out_marketing_email_dialog_message':
    '[ES] This action cannot be undone in TouchBistro Cloud. Only the guest will be able to re-consent to receive email marketing communications in the future.',
  'ge.guest_reporting.opt_out_marketing_email_success': '[ES] User opted out of marketing email.',
  'ge.guest_reporting.opt_out_marketing_email_failure':
    '[ES] There was an error while trying to opt out of marketing email.',
  'ge.guest_reporting.automations_delete_modal_warning':
    '[ES] This filter is currently being used by {{automationsTotal}} Marketing Automation(s) and cannot be deleted.',
  'ge.guest_reporting.go_to_marketing': '[ES] Go to Marketing Automations',
  'ge.guest_feedback.item_placeholder': '[ES] Search item names',
  'ge.guest_feedback.item_name': '[ES] Item Name',
  'ge.guest_feedback.item_avg_rating': '[ES] Average Rating',
  'ge.guest_feedback.item_num_rating': '[ES] # of Ratings',
  'ge.guest_feedback.no_item_results_found': '[ES] There is no item feedback to display.',
  'ge.guest_feedback.positive': 'positivo',
  'ge.guest_feedback.positive_ratings': 'Calificaciones positivas',
  'ge.guest_feedback.negative_ratings': 'Calificaciones negativas',
  'ge.guest_feedback.high_to_low_avg_rating': 'Calificación promedio alta a baja',
  'ge.guest_feedback.low_to_high_avg_rating': 'Calificación promedio de baja a alta',
  'ge.guest_feedback.no_ratings': 'No hay votos',
  'ge.automated_marketing': '[ES] Automated Marketing',
  'ge.automated_marketing.automation_name': '[ES] Automation Name',
  'ge.automated_marketing.automation_title':
    '[ES] Automated Marketing for "{{customFilterName}}" Filter',
  'ge.automated_marketing.automation_create_new': '[ES] Create New Automation',
  'ge.automated_marketing.no_results_found': '[ES] There are no Automations to be displayed.',
  'ge.automated_marketing.limit': 'Ha alcanzado el límite de 10 hitos.',
  'ge.automated_marketing.write_a_message': '[ES] Write a Message',
  'ge.customer_web_app': 'Aplicación web progresiva',
  'ge.customer_web_app.home_screen_buttons': 'Botones de la pantalla de inicio',
  'ge.customer_web_app.home_screen_design': 'Diseño de la pantalla de inicio',
  'ge.customer_web_app.general_screen_design': 'Diseño de la pantalla general',
  'ge.customer_web_app.app_details': 'Detalles de la aplicación',
  'ge.customer_web_app.locations': '[ES] Locations',
  'ge.customer_web_app.save_changes': 'Guardar',
  'ge.customer_web_app.view_web_app': 'Ver aplicación',
  'ge.customer_web_app.short_name': 'Un nombre corto',
  'ge.customer_web_app.short_name_placeholder': 'Introduce un nombre corto',
  'ge.customer_web_app.help_faq_link': 'Enlace de ayuda a preguntas frecuentes',
  'ge.customer_web_app.help_faq_link_placeholder': '[ES] Enter a link',
  'ge.customer_web_app.help_faq_link_description':
    "Agregue un enlace para su sitio web con cualquier información adicional sobre su aplicación. Este enlace aparecerá en la pantalla de 'Ayuda' de su aplicación web.",
  'ge.customer_web_app.help_email': 'Correo electrónico de ayuda',
  'ge.customer_web_app.help_email_placeholder': 'Introduce un correo electrónico de ayuda',
  'ge.customer_web_app.help_email_description':
    "Cuando los invitados necesiten ayuda con su aplicación, pueden enviar preguntas a esta correo electrónico. Esto aparece en la pantalla de 'Ayuda'.",
  'ge.customer_web_app.loyalty_faq_link':
    'Enlace de preguntas frecuentes sobre programas de lealtad',
  'ge.customer_web_app.loyalty_faq_link_placeholder':
    'Introduce un enlace de preguntas frecuentes sobre programas de lealtad',
  'ge.customer_web_app.loyalty_faq_link_description':
    'Agrega un enlace a tu sitio web con las preguntas frecuentes de tus programas de lealtad. Este enlace aparecerá en la pantalla de Recompensas.',
  'ge.customer_web_app.app_text': 'Texto',
  'ge.customer_web_app.app_link_text': 'Enlaces y correos electrónicos',
  'ge.customer_web_app.app_icons': 'Iconos',
  'ge.customer_web_app.email_validation_message':
    'Por favor introduzca una dirección de correo electrónico válida',
  'ge.customer_web_app.url_validation_message': 'Ingrese una UR válidaL',
  'ge.cwa_image_picker.preview_text': 'Selecciona para ver tamaño completo',
  'ge.cwa_image_picker.drop_text': 'Arrastre y suelte imágenes o busque archivos',
  'ge.customer_web_app.pixel_icon': 'icono de {{pixel}}x{{pixel}} píxeles',
  'ge.automated_marketing.create_new_automation': '[ES] Create New Automation',
  'ge.automated_marketing.edit_automation': '[ES] Edit Automation',
  'ge.automated_marketing.select_a_saved_filter': '[ES] Select a Saved Filter',
  'ge.automated_marketing.saved_filter': '[ES] CRM Saved Filter',
  'ge.automated_marketing.promotion': '[ES] Promotion',
  'ge.automated_marketing.set_as_active': '[ES] Set as Active',
  'ge.automated_marketing.create_a_new_saved_filter': '[ES] Create a New Saved Filter',
  'ge.automated_marketing.create_a_new_saved_filter.create_saved_filter_modal.heading':
    '[ES] You will be redirected to CRM',
  'ge.automated_marketing.edited_automation_saved':
    '[ES] Automation "{{automation}}" successfully saved',
  'ge.automated_marketing.created_automation_saved':
    '[ES] Automation "{{automation}}" successfully added',
  'ge.automated_marketing.select_a_promotion': 'Seleccione una promoción',
  'ge.automated_marketing.create_a_new_promotion': 'Crear una nueva Promoción',
  'ge.automated_marketing.create_a_new_promotion.create_promotion_modal.heading':
    'Será redirigido a Promociones',
  'ge.automated_marketing.in_app_message': 'mensajería en la aplicación',
  'ge.automated_marketing.create_in_app_message': 'crear mensajes en la aplicación',
  'ge.automated_marketing.edit_message': 'Editar mensaje',
  'ge.automated_marketing.remove_message': 'Eliminar mensaje',
  'ge.automated_marketing.email': 'Correo electrónico',
  'ge.automated_marketing.select_email_template': 'Seleccionar plantilla de correo electrónico',
  'ge.automated_marketing.edit_email': 'Editar correo electrónico',
  'ge.automated_marketing.remove_email': 'Eliminar correo electrónico',
  'ge.automated_marketing.template_name': 'Nombre de la plantilla',
  'ge.automated_marketing.schedule': 'Horario',
  'ge.automated_marketing.add_schedule': '[ES] Add Schedule',
  'ge.automated_marketing.add_new': 'Agregar nuevo',
  'ge.automated_marketing.add_new_schedule': 'Agregar nuevo horario',
  'ge.automated_marketing.intervals': 'Intervalos',
  'ge.automated_marketing.week_day': 'Día laborable',
  'ge.automated_marketing.choose_a_week_day': '[ES] Select a week day',
  'ge.automated_marketing.starting_date': '[ES] Starting Date',
  'ge.automated_marketing.day_of_month': '[ES] Day of Month',
  'ge.automated_marketing.choose_a_month': '[ES] Select a month',
  'ge.automated_marketing.occurrence': '[ES] Occurrence',
  'ge.automated_marketing.day_29':
    '[ES] NOTE: This automation will not run in February, except leap years',
  'ge.automated_marketing.day_30': '[ES] NOTE: This automation will not run in February',
  'ge.automated_marketing.day_31':
    '[ES] NOTE: This automation will not run in February, April, June, September, and November',
  'ge.customer_web_app.top_image_header': '[ES] Top Image',
  'ge.customer_web_app.top_image_info':
    '[ES] Images will be cropped to 960x714px. Supported file types are .png .jpg .jpeg',
  'ge.customer_web_app.background_header': '[ES] Background',
  'ge.customer_web_app.background_info':
    '[ES] Images will be cropped to 640x1600px. Supported file types are .png .jpg .jpeg',
  'ge.customer_web_app.button_titles_header': '[ES] Button Titles',
  'ge.customer_web_app.button_descriptions_header': '[ES] Button Descriptions',
  'ge.customer_web_app.desktop_gradient_header': '[ES] Desktop Gradient',
  'ge.customer_web_app.reset_section_modal_title': '[ES] Are you sure you want to reset?',
  'ge.customer_web_app.reset_section_modal_body':
    '[ES] This will reset any changes in the {{section}} section back to its original settings.',
  'ge.customer_web_app.color_picker_tip': '[ES] Click below to add a new color',
  'ge.customer_web_app.color_delete_modal_title':
    '[ES] Are you sure you want to delete this color?',
  'ge.customer_web_app.color_delete_modal_body':
    '[ES] This color will be removed from any places that it is currently applied',
  'ge.customer_web_app.save_config_success': '[ES] Configuration saved successfully',
  'ge.customer_web_app.top_image_alt': '[ES] Image preview for the Top Image',
  'ge.customer_web_app.background_image_alt': '[ES] Image preview for the Background Image',
  'ge.cwa_font_picker.drop_text': '[ES] Drag and drop font or browse files',
  'ge.cwa_select_saved.imageurl': '[ES] Select a saved image',
  'ge.cwa_select_saved.fonturl': '[ES] Select a saved font',
  'ge.cwa_select_saved.imageurl_title': '[ES] Saved Images',
  'ge.cwa_select_saved.imageurl_not_available': '[ES] No saved images available',
  'ge.cwa_select_saved.fonturl_title': '[ES] Saved Fonts',
  'ge.cwa_select_saved.fonturl_not_available': '[ES] No saved fonts available',
  'ge.cwa_assetupload.imageurl_success': '[ES] Image successfully uploaded',
  'ge.cwa_assetupload.fonturl_success': '[ES] Font successfully uploaded',
  'ge.cwa_font_picker.sample_text': '[ES] Sample Text',
  'ge.cwa_select_saved.imageurl_confirm_delete_title':
    '[ES] Are you sure you want to delete this image?',
  'ge.cwa_select_saved.imageurl_confirm_delete_body':
    '[ES] This image will be removed from any places that it is currently applied',
  'ge.cwa_select_saved.fonturl_confirm_delete_title':
    '[ES] Are you sure you want to delete this font?',
  'ge.cwa_select_saved.fonturl_confirm_delete_body':
    '[ES] This font will be removed from any places that it is currently applied',
  'ge.customer_web_app.highlight_text_header': '[ES] Highlight Text',
  'ge.customer_web_app.regular_text_header': '[ES] Regular Text',
  'ge.customer_web_app.icon_button_bg_header': '[ES] Icon & Button Background',
  'ge.customer_web_app.button_text_header': '[ES] Button Text',
  'ge.day-0': 'domingo',
  'ge.day-1': 'lunes',
  'ge.day-2': 'martes',
  'ge.day-3': 'miércoles',
  'ge.day-4': 'jueves',
  'ge.day-5': 'viernes',
  'ge.day-6': 'sábado',
  'ge.month-0': 'enero',
  'ge.month-1': 'febrero',
  'ge.month-2': 'marzo',
  'ge.month-3': 'abril',
  'ge.month-4': 'mayo',
  'ge.month-5': 'junio',
  'ge.month-6': 'julio',
  'ge.month-7': 'agosto',
  'ge.month-8': 'septiembre',
  'ge.month-9': 'octubre',
  'ge.month-10': 'noviembre',
  'ge.month-11': 'diciembre',
  'ge.automated_marketing.interval-DAYOFWEEK': '[ES] Weekly',
  'ge.automated_marketing.interval-DAYOFMONTH': '[ES] Monthly',
  'ge.automated_marketing.interval-DAYOFYEAR': '[ES] Yearly',
  'ge.automated_marketing.interval-OCCURRENCE': '[ES] Once',
  'ge.automated_marketing.weekly_every': 'Semanalmente todos los {{day}}',
  'ge.automated_marketing.monthly': 'Mensualmente el {{date}}',
  'ge.automated_marketing.yearly': 'Mensualmente el {{day}} de {{month}}',
  'ge.automated_marketing.starting': ' comenzando el {{date}}',
  'ge.automated_marketing.once': 'Una vez el {{date}}',
  'ge.customer_web_app.instructions':
    'Seleccione las páginas que desea ver en su aplicación. Arrastra y suelta para reorganizar el orden de los botones.',
  'ge.customer_web_app.add_button': 'Agregar un botón personalizado',
  'ge.customer_web_app.custom_name_placeholder': 'Introduce un título personalizado',
  'ge.customer_web_app.custom_description_placeholder':
    'Introduzca una descripción personalizada opcional',
  'ge.customer_web_app.custom_url_placeholder': '[ES] Enter a custom link',
  'ge.customer_web_app.confirm_delete': '¿Estás seguro de que quieres eliminar este botón?',
  'ge.customer_web_app.button_name': '[ES] Button name',
  'ge.customer_web_app.button_description': '[ES] Button description',
  'ge.customer_web_app.enter_a_name': 'Por favor introduce un nombre.',
  'ge.customer_web_app.enter_a_url': '[ES] Please enter a valid link.',
  'ge.customer_web_app.enter_a_valid_phone_number': '[ES] Please enter a valid phone number.',
  'ge.customer_web_app.rewards_tooltip': 'Vinculado a: página de recompensas en la aplicación',
  'ge.customer_web_app.feedback_tooltip': 'Vinculado a: página de comentarios en la aplicación',
  'ge.customer_web_app.giftcards_tooltip':
    'Vinculado a: comprar y canjear tarjetas de regalo en la aplicación',
  'ge.customer_web_app.rewards_default_name': 'Recompensas',
  'ge.customer_web_app.rewards_default_description': 'Gana puntos y canjea recompensas',
  'ge.customer_web_app.feedback_default_name': 'Comentarios de los invitados',
  'ge.customer_web_app.feedback_default_description': 'Cuéntanos cómo fue nuestro servicio',
  'ge.customer_web_app.giftcards_default_name': 'Tarjetas de regalo',
  'ge.customer_web_app.giftcards_default_description': 'Comprar y canjear tarjetas de regalo',
  'ge.customer_web_app.locations_default_name': '[EN] Locations',
  'ge.customer_web_app.locations_default_description': '[EN] View locations information',
  'ge.customer_web_app.giftcards_redirect':
    'No se ha configurado un lugar de TouchBistro Payments.',
  'ge.customer_web_app.giftcards_redirect_reason': `Los invitados pueden ver sus tarjetas de regalo en la aplicación, pero no podrán realizar compras con tarjetas de regalo en la aplicación. Puede conectar un lugar de Pagos TouchBistro en la sección Tarjetas de regalo.`,
  'ge.customer_web_app.redirect': `Continuar a la sección Tarjetas de regalo`,
  'ge.customer_web_app.add': `Agregar botón de tarjetas de regalo de todos modos`,
  'ge.customer_web_app.button_delete_toast': 'Botón personalizado eliminado con éxito',
  'ge.customer_web_app.locations.venue_name': '[ES] Name',
  'ge.customer_web_app.locations.address1': '[ES] Address Line 1',
  'ge.customer_web_app.locations.address2': '[ES] Address Line 2',
  'ge.customer_web_app.locations.city_name': '[ES] City',
  'ge.customer_web_app.locations.state': '[ES] State / Province',
  'ge.customer_web_app.locations.zipcode': '[ES] Zip  / Postal Code',
  'ge.customer_web_app.locations.phone_number': '[ES] Phone Number',
  'ge.customer_web_app.locations.website_url': '[ES] Website link',
  'ge.customer_web_app.locations.facebook_url': '[ES] Facebook link',
  'ge.customer_web_app.locations.instagram_url': '[ES] Instagram link',
  'ge.customer_web_app.locations.twitter_url': '[ES] Twitter link',
  'ge.customer_web_app.locations.location': '[ES] Location',
  'ge.customer_web_app.locations.menus': '[ES] Menus',
  'ge.customer_web_app.locations.add_menu_table_btn': '[ES] Add menu link',
  'ge.customer_web_app.locations.menu_name': '[ES] Name',
  'ge.customer_web_app.locations.menu_url': '[ES] Link',
  'ge.customer_web_app.locations.add_menu_submit_btn': '[ES] Add Menu',
  'ge.customer_web_app.locations.no_venues': '[ES] No Venues Available',
  'ge.customer_web_app.locations.new_menu_modal_header': '[ES] Add New Menu',
  'ge.customer_web_app.locations.state_error_message':
    '[ES] Must look like ON, NY, or other 2 letter state/province code',
  'ge.customer_web_app.locations.delete_menu_confirmation':
    '[ES] Are you sure you want to delete this menu?',
  'ge.customer_web_app.locations.locations_button_disabled_warning':
    '[ES] The Locations button is currently disabled on your Customer Web App. Enable it on the Buttons tab so your diners can see your Locations information.',
  'ge.customer_web_app.save_error_message': '[ES] Please address any issues prior to saving.',
  'ge.customer_web_app.locations.locations_button_disabled_warning_new':
    '[ES] The Locations button is currently disabled on your Customer Web App. Enable it on the <1>Home Screen Buttons</1> tab so diners can see your Locations information.',
  'ge.customer_web_app.locations.locations_button_info':
    '[ES] Heads up! The Customer Web App automatically pulls your venue details from the <1>Venue Details</1> page.',
  'ge.loyalty_programs': '[ES] Loyalty Programs',
  'ge.loyalty_programs.name': '[ES] Program Name',
  'ge.loyalty_programs.points_per_dollar': '[ES] Points per dollar',
  'ge.loyalty_programs.description': '[ES] Description',
  'ge.loyalty_programs.description_placeholder': '[ES] Enter a description',
  'ge.loyalty_programs.apply_to_all_venues': '[ES] Apply this Program to ALL your Venues',
  'ge.loyalty_programs.schedule': '[ES] Schedule',
  'ge.loyalty_programs.spend_based': '[ES] Spend-Based Loyalty Programs',
  'ge.loyalty_programs.spend_based_empty':
    '[ES] You currently have no Spend-Based Loyalty Programs to be displayed.',
  'ge.loyalty_programs.add_remove_points': '[ES] Add/Remove Points',
  'ge.loyalty_programs.item_based': '[ES] Item-Based Loyalty Programs',
  'ge.loyalty_programs.item_based_empty':
    '[ES] You currently have no Item-Based Loyalty Programs to be displayed.',
  'ge.loyalty_programs.add_remove_claimed_items': '[ES] Add/Remove Claimed Items',
  'ge.loyalty_programs.page_title_create': '[ES] Create New Program',
  'ge.loyalty_programs.page_title_edit': '[ES] Edit Loyalty Program',
  'ge.loyalty_programs.step_select_program_type': '[ES] Select Program Type',
  'ge.loyalty_programs.step_program_details': '[ES] Program Details',
  'ge.loyalty_programs.program_type_spend_name': '[ES] Spend-Based',
  'ge.loyalty_programs.program_type_item_name': '[ES] Item-Based',
  'ge.loyalty_programs.program_type_spend_description':
    '[ES] Guests can accrue Points based on how much they spend. Points can be redeemed for Rewards.',
  'ge.loyalty_programs.program_type_item_description':
    '[ES] Guests make progress when they purchase specific Menu Items. They can redeem Rewards when they hit Milestones.',
  'ge.loyalty_programs.save_success': '[ES] Program created successfully',
  'ge.loyalty_programs.edit_success': '[ES] Program edited successfully',
  'ge.loyalty_programs.individual_item_claim_limit':
    '[ES] Number of Times Each Individual Item Can Be Claimed',
  'ge.loyalty_programs.daily_claim_limit': '[ES] Claim Limit Per Day for ALL Items',
  'ge.loyalty_programs.reward_milestones': 'Hitos en el programa de recompensa',
  'ge.loyalty_programs.number_of_claimed_items': 'Número de artículos canjeados',
  'ge.loyalty_programs.items': '{{items}} artículos',
  'ge.loyalty_programs.menu.select_reward_milestone':
    '[ES] At least 1 Reward Milestone needs to be added.',
  'ge.loyalty_programs.menu.sub_label': '[ES] Apply Menu Groups and/or Menu Items to the Program.',
  'ge.loyalty_programs.search_user': '[ES] Search for a guest by email or phone number:',
  'ge.loyalty_programs.add_remove_points_modal.comment_placeholder':
    '[ES] Why are you editing the points balance?',
  'ge.loyalty_programs.add_remove_points_modal.current_balance': '[ES] Current Points Balance:',
  'ge.loyalty_programs.add_remove_points_modal.new_balance': '[ES] New Points Balance:',
  'ge.invalid_email_phone': '[ES] Invalid email address or phone number',
  'ge.loyalty_programs.add_remove_points_modal.add_successfully':
    '[ES] Points successfully added to {{name}}. New balance: {{points}} Points.',
  'ge.loyalty_programs.add_remove_points_modal.remove_successfully':
    '[ES] Points successfully removed from {{name}}. New balance: {{points}} Points.',
  'ge.provisioning': '[ES] Guest Engagement Provisioning',
  'ge.provisioning.group_details': '[ES] Group Details',
  'ge.provisioning.ge_group': '[ES] Guest Engagement Group',
  'ge.provisioning.ge_groups': '[ES] Guest Engagement Groups',
  'ge.provisioning.ge_group_products': '[ES] Guest Engagement Products',
  'ge.provisioning.corporation_unavailable': '[ES] Corporation details unavailable',
  'ge.provisioning.ge_enabled': '[ES] Guest Engagement Enabled',
  'ge.provisioning.no_venues': '[ES] There are no Venues to Display',
  'ge.provisioning.loyaltyEnabled': '[ES] Loyalty',
  'ge.provisioning.marketingEnabled': '[ES] Marketing',
  'ge.provisioning.giftCardsEnabled': '[ES] Digital Gift Cards',
  'ge.provisioning.save_success': '[ES] Provisioning data updated successfully',
  'ge.provisioning.sync_success': '[ES] Guest Engagement Enabled Successfully',
  'ge.provisioning.no_venues_message': '[ES] No Venues',
  'ge.provisioning.drag_and_drop_venues':
    '[ES] Drag and drop venues here to provision their Guest Engagement products',
  'ge.provisioning.unassignedVenues': '[ES] Unassigned Venues',
  'ge.provisioning.add_venue_notice_1':
    '[ES] Please note the following before adding "{{venueName}}" to the "{{groupName}}" Guest Engagement group:',
  'ge.provisioning.add_venue_notice_1_1':
    '[ES] Any existing Rewards, Promotions, Marketing Automations, Saved Filters, and Loyalty Programs set for “ALL LOCATIONS” in "{{groupName}}" will not be valid at "{{venueName}}" automatically. These items must be updated manually to include "{{venueName}}".',
  'ge.provisioning.add_venue_notice_1_2':
    '[ES] All existing Digital Gift Cards at "{{groupName}}" will automatically be valid at "{{venueName}}".',
  'ge.provisioning.add_venue_notice_2':
    '[ES] If "{{venueName}}" was previously a member of a different Guest Engagement group:',
  'ge.provisioning.add_venue_notice_2_1':
    '[ES] The CRM order data (shown in the CRM table) and Guest Feedback data generated through this venue will become visible to "{{groupName}}"',
  'ge.provisioning.add_venue_notice_2_2':
    '[ES] The diner’s activity feed data (visible in the CRM and CWA) will remain visible in the prior group and will NOT be moved to "{{groupName}}"',
  'ge.provisioning.remove_venue_notice_1':
    '[ES] Please note the following before removing "{{venueName}}" from the "{{groupName}}" Guest Engagement group:',
  'ge.provisioning.remove_venue_notice_1_1':
    '[ES] Diners will no longer be able to use any of their existing Rewards, Promotions or Loyalty Programs at "{{venueName}}". These items will remain valid and can be used at other venues within the "{{groupName}}" Guest Engagement group, assuming the items are configured for usage at the other venues.',
  'ge.provisioning.remove_venue_notice_1_2':
    '[ES] Existing Digital Gift Cards will no longer be valid at "{{venueName}}", but will remain valid at all other venues within the "{{groupName}}" Guest Engagement group. The Digital Gift Card usage report will continue to show data from "{{venueName}}".',
  'ge.provisioning.remove_venue_notice_1_3':
    '[ES] The CRM order data (shown in the CRM table) and Guest Feedback data created by "{{venueName}}" will be removed.',
  'ge.provisioning.remove_venue_notice_1_4':
    '[ES] The diner’s activity feed data (visible in the CRM and CWA) will remain visible in "{{groupName}}".',
  'ge.provisioning.remove_venue_notice_1_5':
    '[ES] If this venue is the TB Payments destination for online DGC purchases for {{groupName}}, then it will be removed and a new venue will need to be selected.',
  'ge.provisioning.add_new_group_confirmation_title':
    '[ES] Are you sure you would like to create a new group?',
  'ge.provisioning.add_new_group_confirmation_body':
    '[ES] Once a Guest Engagement group has been created, it cannot be deleted.',
  'ge.provisioning.not_available_message':
    '[ES] Guest Engagement provisioning is not available for this venue.',
  'ge.provisioning.not_available_description':
    '[ES] The venue and group relationships for this customer are not in-sync. Once the outreach for this customer has been completed, please return to this page to provision their Guest Engagement functionality.',
  'ge.loyalty_programs.edit_claims.page_title': '[ES] Add/Remove Claimed Items',
  'ge.loyalty_programs.edit_claims.program_name': '[ES] Program Name',
  'ge.loyalty_programs.edit_claims.total_claimed': '[ES] Total Claimed Items',
  'ge.loyalty_programs.edit_claims.num_claimed': '[ES] {{claims}} Claimed Items',
  'ge.loyalty_programs.edit_claims.search_menu': '[ES] Search for a specific Menu Group/Item:',
  'ge.loyalty_programs.edit_claims.claimed': '[ES] Claimed',
  'ge.loyalty_programs.edit_claims.success': '[ES] Successfully modified claims for {{name}}',
  'ge.loyalty_programs.edit_claims.load_more': '[ES] Load More',
  'ge.loyalty_programs.edit_claims.confirm_user_change.body':
    '[ES] These changes will not be saved.\nAre you sure you want to search for another guest?',
  'ge.loyalty_programs.search.no_results':
    "[ES] No user was found. You must enter an EXACT match for the guest's email address or phone number.",
  'ge.tu_redirect_page.title':
    '[ES] The Loyalty Portal (production.tableup.com) has been decommissioned.',
  'ge.tu_redirect_page.subtitle':
    '[ES] You can now manage <1>TouchBistro Loyalty</1>, <1>Marketing</1>, and <1>Digital Gift Cards</1> in <1>TouchBistro Cloud</1>.\nAfter logging into Cloud, look for the <1>"Guest Engagement"</1> section in the left navigation bar.',
  'ge.tu_redirect_page.login_btn': '[ES] Login to Cloud',
  'ge.tu_redirect_page.desc':
    '[ES] If you do not have a Cloud login, contact your Venue\'s Administrator and request an invitation.\nIf you are logged in to Cloud but cannot see the "Guest Engagement" tab, please contact us to discuss necessary changes to your account.',
  'ge.tu_redirect_page.phone':
    '[ES] <1>By Phone:</1>\nCall <2/> and ask to be escalated to the Value Added Services team.',
  'ge.tu_redirect_page.email':
    '[ES] <1>By Email:</1>\n<2/> and please include your Venue name and ID.',
  'ge.tu_redirect_page.helpful': '[ES] Helpful Resources',
  'ge.tu_redirect_page.helpful_links.1': '[ES] Using Guest Engagement in TouchBistro Cloud',
  'ge.tu_redirect_page.helpful_links.2':
    '[ES] Creating accounts for my staff to access TouchBistro Cloud',
  'ge.tu_redirect_page.helpful_links.3': '[ES] How to Reset your TouchBistro Cloud password',
  'ge.dgc': '[ES] Digital Gift Cards',
  'ge.dgc.connection_instructions_header': 'Conecte el lugar de pago de TouchBistro',
  'ge.dgc.connection_instructions':
    'e requiere TouchBistro Payments para las compras con tarjeta de regalo en la aplicación web de los cliente. Elija un lugar para facturar sus compras de tarjeta de regalo de la aplicación web.',
  'ge.dgc.venue': 'Lugar',
  'ge.dgc.success_toast': 'El lugar de pago de TouchBistro se conectó correctamente',
  'ge.dgc.select_venue': 'Seleccione un lugar',
  'ge.dgc.no_tb_payments_venue': '[ES] No venues with TouchBistro Payments available.',
  'ge.dgc.total_sold': '[ES] Total Gift Cards Sold',
  'ge.dgc.active': '[ES] Active Gift Cards',
  'ge.dgc.search': '[ES] Search by Reference ID or name or email address',
  'ge.dgc.connect_tb_payments': '[ES] Connect TouchBistro Payments Venue',
  'ge.dgc.tb_payments_connected': '[ES] Payments Connected to venue {{venue}}',
  'ge.dgc.referenceID': '[ES] Reference ID',
  'ge.dgc.recipientName': '[ES] Recipient Name',
  'ge.dgc.recipientEmail': '[ES] Recipient Email',
  'ge.dgc.purchaserName': '[ES] Purchaser Name',
  'ge.dgc.purchaserEmail': '[ES] Purchaser Email',
  'ge.dgc.startingBalance': '[ES] Starting Balance',
  'ge.dgc.currentBalance': '[ES] Current Balance',
  'ge.dgc.purchaseDate': '[ES] Purchase Date',
  'ge.dgc.deliveryDate': '[ES] Delivery Date',
  'ge.dgc.no_gift_cards': '[ES] You currently have no Digital Gift Cards issued to diners.',
  'ge.dgc.no_matching_result':
    '[ES] No matching results to display. Please try a different Reference ID or name or email address',
  'ge.dgc.details': '[ES] Digital Gift Card Details',
  'ge.dgc.recipient_info': '[ES] Recipient Info',
  'ge.dgc.purchaser_info': '[ES] Purchaser Info',
  'ge.dgc.no_gift_card_activity_feed': '[ES] No Gift Card Activity',
  'ge.dgc.delivery_date_updated': '[ES] Delivery Date updated successfully',
  'ge.dgc.transfer_card_modal_title': '[ES] Recover an Inaccessible Gift Card',
  'ge.dgc.transfer_card_modal_subtitle':
    '[ES] You can recover an inaccessible and unused gift card by editing the email address associated to the DGC. Once the email is changed, the prior email address will not be able to retrieve or access this gift card for purchases.',
  'ge.dgc.current_owner_email': '[ES] Current Recipient Email',
  'ge.dgc.new_recipient_email': '[ES] New Recipient Email',
  'ge.dgc.resend_email_btn': '[ES] Resend Email',
  'ge.dgc.resend': '[ES] Resend',
  'ge.dgc.resend_email_title': '[ES] Are you sure you want to resend this gift card to {{email}}?',
  'ge.dgc.resend_email_notice': '[ES] The email will be resent immediately and cannot be undone.',
  'ge.dgc.resend_email_status': '[ES] Email successfully resent!',
  'ge.dgc.resend_email_successfully':
    '[ES] You have successfully resent the gift card email to {{email}}.',
  'ge.dgc.new_recipient_firstname_placeholder': '[ES] Enter a first name',
  'ge.dgc.new_recipient_lastname_placeholder': '[ES] Enter a last name',
  'ge.dgc.new_recipient_email_placeholder': '[ES] Enter an email',
  'ge.dgc.recipient_transferred_successfully': '[ES] Gift Card recipient changed successfully',
  'ge.dgc.recipient_transferred_successfully_content_1':
    '[ES] You have successfully changed the gift card recipient to {{firstName}} {{lastName}} {{email}}. The current balance is {{balance}}',
  'ge.dgc.recipient_transferred_successfully_content_2':
    '[ES] {{firstName}} {{lastName}} {{email}} can no longer use this gift card.',
  'ge.dgc.activity.GIFTCARDPURCHASE': '[ES] Gift Card Purchased',
  'ge.dgc.activity.GIFTCARDREDEEM': '[ES] Gift Card Redeemed',
  'ge.dgc.activity.GIFTCARDREFUND': '[ES] Order Credit Applied',
  'ge.dgc.activity.GIFTCARDROLLBACK': '[ES] Gift Card Transaction Cancelled',
  'ge.dgc.activity.GIFTCARDDELIVERYDATECHANGED': '[ES] Delivery Date Changed',
  'ge.dgc.activity.GIFTCARDRECIPIENTTRANSFERRED': '[ES] Gift Card Recipient Changed',
  'ge.dgc.activity.GIFTCARDEMAILRESENT': '[ES] Gift Card Email Resent',
  'ge.dgc.activity.source': '[ES] Source',
  'ge.dgc.activity.amount': '[ES] Amount: ${{amount}}',
  'ge.dgc.activity.redeemed_amount': '[ES] Redeemed Amount: ${{amount}}',
  'ge.dgc.activity.new_balance': '[ES] New Balance: ${{amount}}',
  'ge.dgc.activity.credit_amount': '[ES] Credit Amount: ${{amount}}',
  'ge.dgc.activity.transaction_amount': '[ES] Transaction Amount: ${{amount}}',
  'ge.dgc.activity.prior_delivery_date': '[ES] Prior Delivery Date: {{date}}',
  'ge.dgc.activity.new_delivery_date': '[ES] New Delivery Date: {{date}}',
  'ge.dgc.activity.transferred_from': '[ES] From: {{user}}',
  'ge.dgc.activity.transferred_to': '[ES] To: {{user}}',
  'ge.dgc.activity.balance_transferred': '[ES] Balance: ${{amount}}',
  'ge.dgc.activity.resent_to': '[ES] Resent To: {{user}}',
  'ge.dgc.list': '[ES] Gift Card List',
  'ge.dgc.settings': '[ES] Settings',
  'ge.dgc.tb_payment': '[ES] TouchBistro Payments',
  'ge.dgc.tb_payment_note':
    '[ES] \nNOTE: If you also use TouchBistro Online Ordering, connecting a Payments venue will immediately add a banner to your menu page to promote the availability of your Digital Gift Cards and allow guests to start purchasing them.',
  'ge.dgc.limits': '[ES] Sales Limits for Online Sales',
  'ge.dgc.limits.desc': '[ES] Apply limits to Digital Gift Cards sold online.',
  'ge.dgc.limits.max_dollar': '[ES] Max dollar amount that can be added on a single card',
  'ge.dgc.limits.by_single_guest':
    '[ES] Max # of Digital Gift Cards that can be purchased by a single guest',
  'ge.dgc.limits.by_single_guest.desc':
    '[ES] NOTE: If one of these maximums is reached, the guest will be prompted with a generic error message that refers to "Error code: 100"',
  'ge.dgc.limits.with_24_hour_period': '[ES] within a 24 hour period',
  'ge.dgc.limits.with_7_day_period': '[ES] within a 7 day period',
  'ge.dgc.limits.updated_message': '[ES] Digital Gift Cards settings updated successfully',
  'ge.dgc.limits.error_message':
    '[ES] The 7 day limit must be equal to or greater than the 24 hour limit',
  'ge.provisioning.section_back_button': '[ES] Back',
  'ge.provisioning.section_warning_message': '[ES] Moving Venues to {{target_group_name}}',
  'ge.privisioning.section_info_message':
    '[ES] Please note the following before moving venue(s) to this group:',
  'ge.provisioning.section_title_staff': '[ES] Staff',
  'ge.provisioning.section_title_menu': '[ES] Menu',
  'ge.provisioning.section_title_tbloyalty': '[ES] TouchBistro Loyalty',
  'ge.provisioning.section_title_tbmarketing': '[ES] TouchBistro Marketing',
  'ge.provisioning.section_title_tbdge': '[ES] TouchBistro Digital Gift Cards',
  'ge.provisioning.section_title_reservation': '[ES] Reservations',
  'ge.provisioning.section_staff_item_1':
    '[ES] Prior to moving your venue, perform EOD and ensure all users are clocked out. Failure to do so will result in prohibited users having access to the venue(s).',
  'ge.provisioning.section_staff_item_2':
    '[ES] Staff that had permission to the venue may lose access unless they have access to {{destinationGroupName}}',
  'ge.provisioning.section_staff_item_3':
    '[ES] Staff that had permissions to {{destinationGroupName}} will automatically have access to the venue after the venue has been moved',
  'ge.provisioning.section_menu_item_1':
    '[ES] Any Menu Management records that were created by the {{originalGroupName}} and inherited by this venue will not be carried over',
  'ge.provisioning.section_menu_item_2':
    '[ES] Any Menu Management records that were created at the venue will be carried over',
  'ge.provisioning.section_menu_item_3':
    '[ES] Failure to re-assign printers to new prep stations will result in the printers not working for the venue(s)',
  'ge.provisioning.section_tbloyalty_desc':
    '[ES] The following applies only if the venue being moved is already entitled for TouchBistro Loyalty.',
  'ge.provisioning.section_tbloyalty_desc_1':
    '[ES] If the destination group already has TB Loyalty:',
  'ge.provisioning.section_tbloyalty_item_1':
    '[ES] The existing Rewards, CRM Saved Filters, and Loyalty Programs set for “ALL LOCATIONS” in the destination group will NOT automatically become valid at the newly assigned venue(s). These items must be manually updated to include the newly assigned venue(s).',
  'ge.provisioning.section_tbloyalty_desc_2':
    '[ES] If the venue is moving from a group with TB Loyalty:',
  'ge.provisioning.section_tbloyalty_item_2':
    '[ES] The CRM order data from the newly assigned venues will be removed from their prior group(s) and become visible to the destination group',
  'ge.provisioning.section_tbloyalty_item_3':
    '[ES] Existing Rewards and Loyalty progress (points and/or items purchased) earned by guests at the prior group will no longer be valid at the newly assigned venue, but will remain valid and can be used at other venues in the prior group if they are configured for those venues.',
  'ge.provisioning.section_tbloyalty_item_4':
    '[ES] The guest’s activity feed data (visible in the CRM and CWA) will remain visible in the prior group and will NOT be moved to the destination group. However, any historical bills belonging to the newly assigned venues will become inaccessible by the prior group.',
  'ge.provisioning.section_tbmarketing_desc':
    '[ES] The following applies only if the venue being moved is already entitled for TouchBistro Marketing.',
  'ge.provisioning.section_tbmarketing_desc_1':
    '[ES] If the destination group already has TB Marketing:',
  'ge.provisioning.section_tbmarketing_item_1':
    '[ES] The existing Rewards, Promotions, Marketing Automations, and CRM Saved Filters set for “ALL LOCATIONS” in the destination group will NOT automatically become valid at the newly assigned venue(s). These items must be updated manually to include the newly assigned venue(s).',
  'ge.provisioning.section_tbmarketing_desc_2':
    '[ES] If the venue is moving from a group with TB Marketing:',
  'ge.provisioning.section_tbmarketing_item_2':
    '[ES] The CRM order data and Guest Feedback data from the newly assigned venues will be removed from their prior group(s) and become visible to the destination group.',
  'ge.provisioning.section_tbmarketing_item_3':
    '[ES] Existing Rewards or Promotions earned by guests at the prior group will no longer be valid at the newly assigned venue, but will remain valid and can be used at other venues within the prior group(s) if they are configured for those venue(s).',
  'ge.provisioning.section_tbmarketing_item_4':
    '[ES] The guest’s activity feed data (visible in the CRM and CWA) will remain visible in the prior group and will NOT be moved to the destination group. However, any historical bills belonging to the newly assigned venues will become inaccessible by the prior group.',
  'ge.provisioning.section_tbdge_desc':
    '[ES] The following applies only if the venue being moved is already entitled for TouchBistro Digital Gift Cards.',
  'ge.provisioning.section_tbdge_desc_1':
    '[ES] If the destination group already has TB Digital Gift Cards:',
  'ge.provisioning.section_tbdge_item_1':
    '[ES] All existing Digital Gift Cards at the destination group will automatically be valid at the newly assigned venue(s).',
  'ge.provisioning.section_tbdge_desc_2':
    '[ES] If the venue is moving from a group with TouchBistro Digital Gift Cards:',
  'ge.provisioning.section_tbdge_item_2':
    '[ES] Digital Gift Cards previously purchased at the newly assigned venue(s) will not be valid at the destination group, but will remain valid at other venues within the prior group(s).',
  'ge.provisioning.section_tbdge_item_3':
    '[ES] The Digital Gift Card usage report for the prior group(s) will continue to show data from the removed venue(s).',
  'ge.provisioning.section_tbdge_item_4':
    '[ES] The guest’s activity feed data (visible in the CRM and CWA) will remain visible in the prior group and will NOT be moved to the destination group.',
  'ge.provisioning.section_reservation_desc':
    '[ES] The following applies only if the venue being moved is already entitled for TouchBistro Reservations in Cloud.',
  'ge.provisioning.section_reservation_desc_1':
    '[ES] If the destination group already has TB Reservations in Cloud, please note:',
  'ge.provisioning.section_reservation_item_1':
    '[ES] The venue will inherit group level settings from the destination group, which may result in a configuration change for the venue.',
  'ge.provisioning.section_reservation_item_2':
    '[ES] Group level booking widgets for the destination group will not automatically include the new venue.',
  'ge.provisioning.unassigned': '[ES] Unassigned',
}

export default guestEngagementES

const floorplanEN = {
  // namespace your translation keys with "flr", i.e.
  // 'flr.lorem_ipsum': 'Lorem Ipsum'
  'floorplan.tabletypes_select_label': 'Table Type',
  'floorplan.tabletypes_menu_item_name': 'Name',
  'floorplan.tabletypes_menu_item_default': 'Default',
  'floorplan.tabletypes_menu_item_edit': 'Edit',
  'floorplan.tabletypes_create_tabletype_label': 'Create Table Type',
  'floorplan.tabletypes_edit_tabletype_label': 'Edit Table Type',
  'floorplan.tabletypes_tabletype_name_label': 'Table Type Name',
  'floorplan.tabletypes_edit_en_textfield': 'Enter Table Type name',
  'floorplan.tabletypes_edit_es_textfield': 'Spanish translation',
  'floorplan.tabletypes_edit_fr_textfield': 'French translation',
  'floorplan.tabletypes_en_chip': 'EN',
  'floorplan.tabletypes_es_chip': 'ES',
  'floorplan.tabletypes_fr_chip': 'FR',
  'floorplan.tabletypes_create_success_notification':
    '{{tabletype_name}} Table Type created successfully.',
  'floorplan.tabletypes_edit_success_notification':
    '{{tabletype_name}} Table Type saved successfully',
  'floorplan.tabletypes_too_many_tabletypes_dialog_title':
    'You’ve reached the maximum number of table types.',
  'floorplan.tabletypes_too_many_tabletypes_dialog_message':
    'Please modify your existing table types or delete a table type in order to create a new one.',
  'floorplan.tabletypes_duplicate_name_error': 'A Table Type with this name already exists.',
  'floorplan.rightpane_tab_floorplan': 'Floor Plan',
  'floorplan.rightpane_tab_table_combination': 'Table Combinations',
  'floorplan.rightpane_tabletype_name_too_long_error':
    'Table Type Names must have less than 20 characters.',
  'floorplan.rightpane_create_table_combination_label': 'Create a Table Combination',
  'floorplan.rightpane_create_table_combination_instructions':
    'Select 2 to 10 tables by holding down shift key or selecting and dragging across your desired tables.',
  'floorplan.rightpane_create_table_combination_button': 'Create Table Combination',
  'floorplan.rightpane_create_table_combination_button_too_many_selected':
    'You can only select a maximum of 10 tables in a table combination.',
  'floorplan.rightpane_create_table_combination_button_combination_already_exists_header':
    'The Table Combination selection already exists.',
  'floorplan.rightpane_create_table_combination_button_combination_already_esists_body':
    'Please select another set of tables in order to create a new table combination or review your saved combinations in the Table Type Combination list',
  'floorplan.rightpane_tabletype_no_empty_fields':
    'Please enter a Table Type Name for at least one language.',
  'floorplan.dialog_prompt':
    'Are you sure you want to leave this page without saving your changes?',
  'floorplan.remove_table_combination_modal': 'Are you sure you want to remove this combination?',
  'floorplan.cancel': 'Cancel',
  'floorplan.remove': 'Remove',
  'floorplan.dialog_discard_changes': 'Discard Changes',
  'floorplan.dialog_save_changes': 'Save Changes',
  'floorplan.tabletypes_edit_default_success_notification':
    '{{tabletype_name}} has been set as the default table type',
  'floorplan.empty_table_combination': 'No combinations exist',
  'floorplan.start_over': 'Start Over',
  'floorplan.set_table_combination_details': 'Set Table Combinations Details',
  'floorplan.table_combination_info_part_1':
    'The maximum seats for Table Combinations is 24 seats.',
  'floorplan.table_combination_info_part_2':
    'Note: Parties larger than 24 seats can still be booked in-person or over the phone by staff. ',
  'floorplan.selected_table_combination': 'Selected Table Combination',
  'floorplan.see_suggested_combinations': 'See Suggested Table Combination Variations',
  'floorplan.additional_combinations_info': 'Additional combinations within your selected tables.',
  'floorplan.min_seats': 'Min Seats',
  'floorplan.max_seats': 'Max Seats',
  'floorplan.table_type_selection': 'table type / table selection',
  'floorplan.table_combination_save_success': 'New Table Combination saved successfully.',
  'floorplan.table_combination_duplicate_error': 'Table combination already exists.',
  'floorplan.table_combination_update_success': 'Table combination(s) updated successfully.',
  'floorplan.min_max_seats_error': 'Min Seats must be less than or equal to Max Seats',
  'floorplan.max_min_seats_error': 'Max Seats must be equal to or more than Min Seats',
  'floorplan.max_seats_limit_error': 'Table Combinations cannot exceed 24 seats.',
  'floorplan.delete_table_part_of_combination_title':
    'Removing {{tableName}} will impact Table Combinations.',
  'floorplan.delete_table_part_of_combination_message':
    'All Table Combinations containing {{tableName}} will be removed. This change won’t be published until Floor Plan is Saved.',
  'floorplan.delete_table_part_of_combination_confirm_button': 'Remove Table',
  'floorplan.priority': 'Priority',
  'floorplan.view_table_combination_list_label': 'View Selected Table Combination List',
  'floorplan.suggested_table_combination_label': 'Suggested Table Combination ({{suggestionQty}})',
  'floorplan.add_selection': 'Add Selection',
}

export default floorplanEN

const crmEN = {
  'crm.appbar-title': 'Guest Manager',
  'crm.guestlist-title': 'Guest List',
  'crm.guestlist-table-header-guest-name': 'Guest Name',
  'crm.guestlist-table-header-email-opt-in': 'Opt-In Status',
  'crm.guestlist-days-since-last-order': 'Days Since Last Order',
  'crm.guestlist-total-spent12m': 'Total Spent (12M)',
  'crm.guestlist-average-spent-12m': 'Average Spent (12M)',
  'crm.guestlist-total-orders': 'Total Orders (12M)',
  'crm.guestlist-birthday': 'Birthday',
  'crm.guestlist-table-header-phone': 'Phone',
  'crm.guestlist-table-header-email': 'Email',
  'crm.guestlist-table-header-lifetime-spend': 'Lifetime Spend',
  'crm.guestlist-table-header-last-order-date': 'Last Order Date',
  'crm.guestlist-fetch-error': 'Failed to fetch guest list',
  'crm.guestlist-search': 'Guest Search',
  'crm.guestlist-empty-content':
    'Loyal Guests are only a few quick steps away. Let us help you get started by clicking the button below.',
  'crm.guestlist-nooption': 'No Guests match your search.',
  'crm.guestlist-moreoptions': 'And {{totalSearch}} more.',
  'crm.guestlist-getstarted': 'Get Started',
  'crm.guestlist-selectalltext': 'Select all {{allGuestCount}} Guests',
  'crm.guestlist-selectiontext': 'You have selected {{selectedGuestCount}} Guests',
  'crm.guestlist-clearselection': 'Clear Selection',

  'crm.guest-event-type-enum': {
    guest_created: 'Guest Created',
    item_claimed: 'Item Claimed',
    item_removed: 'Item Removed',
    ordered: 'Ordered',
    points_earned: 'Points Earned',
    points_removed: 'Points Removed',
    promotion_sent: 'Promotion Sent',
    refunded: 'Refunded',
    reward_earned: 'Reward Earned',
    reward_redeemed: 'Reward Redeemed',
    reward_removed: 'Reward Removed',
    reward_returned: 'Reward Returned',
    tag_added: 'Tag Added',
    tag_removed: 'Tag Removed',
  },

  'crm.guest-important-date-enum': {
    ANNIVERSARY: 'Anniversary',
    BIRTHDAY: 'Birthday',
  },

  'crm.guestprofile-back-button': 'Back to Guest List',
  'crm.guest-appbar-title': 'Guest Profile',
  'crm.guest-creation-date': 'Guest since',
  'crm.guest-loyalty-and-reward-section': 'Loyalty & Rewards',
  'crm.guest-activity-section': 'Activity',
  'crm.guest-personal-detail-section': 'Personal Details',
  'crm.guest-email-section': 'Email',
  'crm.guest-phonenumber-section': 'Phone',
  'crm.guest-marketing-section': 'Marketing',
  'crm.guest-recently-ordered-section': 'Most Recently Ordered',
  'crm.guest-email-subscribed': 'Email Subscribed',
  'crm.guest-email-notsubscribed': 'Not Subscribed',
  'crm.guestprofile-fetch-error': 'Failed to fetch guest activities',
  'crm.guest-aggregate-total-spent': 'total spend (12M)',
  'crm.guest-event-activity-feed-empty': 'Your activity feed is currently empty',
}

export default crmEN

import React, { useReducer, FunctionComponent } from 'react'

interface PendingItem {
  uuid: string
}

interface DeleteQueueState {
  pending: string[]
}

type EnqueuePayload = PendingItem
type DequeuePayload = PendingItem

enum DeleteQueueActionTypes {
  ENQUEUE_ITEM = 'ENQUEUE_ITEM',
  DEQUEUE_ITEM = 'DEQUEUE_ITEM',
}

type DeleteQueueAction =
  | { type: DeleteQueueActionTypes.ENQUEUE_ITEM; payload: EnqueuePayload }
  | { type: DeleteQueueActionTypes.DEQUEUE_ITEM; payload: DequeuePayload }

const initialState: { pending: string[] } = {
  pending: [],
}

const customDeleteQueueReducer = (
  state: DeleteQueueState,
  action: DeleteQueueAction
): DeleteQueueState => {
  switch (action.type) {
    case 'ENQUEUE_ITEM':
      return {
        ...state,
        pending: [...state.pending, action.payload.uuid],
      }
    case 'DEQUEUE_ITEM':
      return {
        ...state,
        pending: [...state.pending.filter((pendingItem) => pendingItem !== action.payload.uuid)],
      }
    default:
      throw new Error('Unexpected action')
  }
}

const DeleteQueueContext = React.createContext({
  state: initialState,
  dispatch: (value: DeleteQueueAction) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
})

const DeleteQueueProvider: FunctionComponent<{
  children?: React.ReactNode
}> = ({ children }) => {
  const [state, dispatch] = useReducer(customDeleteQueueReducer, initialState)
  return (
    <DeleteQueueContext.Provider value={{ state, dispatch }}>
      {children}
    </DeleteQueueContext.Provider>
  )
}

export { DeleteQueueContext, DeleteQueueProvider }

const liteES = {
  'lite.devices.prep-stations': '[ES] Prep Stations',
  'lite.devices.prep-station.create': '[ES] Create Prep Station',
  'lite.devices.prep-station.edit': '[ES] Edit Prep Station',
  'lite.devices.prep-station.delete_modal.title':
    '[ES] Are you sure you want to delete this prep station?',
  'lite.devices.prep-station.delete': '[ES] Delete Prep Station',
  'lite.devices.prep-station.configured-on-the-pos':
    '[ES] Prep stations must be configured on the POS',
}

export default liteES

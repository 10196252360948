import { PaletteOptions } from '@mui/material/styles'

/**
 * NOTE:
 * PLEASE READ BEFORE MAKING ANY CHANGES TO THE BLOCK BELOW
 *
 * WE ARE USING THE EXACT SAME COLOR MAPPING IN VCS AS WELL
 * ANY CHANGES HERE SHOULD ALSO BE COPIED OVER TO THE FOLLOWING FILES
 * https://github.com/TouchBistro/venue-config-service/blob/master/src/domains/configurational-menu/types/constants/menu_import.ts#L124-L185
 * https://github.com/TouchBistro/venue-config-service/blob/master/data/configurational-menu/bulk-operations/menu_export_template.xlsx (Color Legends sheet)
 *
 * NOT DOING SO COULD BREAK SOME FEATURES IN VCS
 */
export const tbPalette: PaletteOptions['tb'] = {
  arctic: '#B8D1ED',
  army: '#69663C',
  azure: '#315A85',
  berry: '#8F2B62',
  black: '#000000',
  blush: '#FCD5CE',
  carrot: '#E25700',
  cerulean: '#2C7389',
  cloud: '#F3F7F8',
  coral: '#F16651',
  cranberry: '#8B1E3F',
  denim: '#25195A',
  dust: '#F5F5F5',
  featherGrey: '#CCCCCC',
  flower: '#96BEE8',
  fossil: '#707D8F',
  fuschia: '#E91E63',
  heatherGrey: '#666666',
  indigo: '#4867BC',
  iris: '#C089DC',
  jade: '#62B599',
  kale: '#159948',
  kelly: '#8BC63F',
  leaf: '#2D9463',
  lemon: '#FFB81C',
  lightCoral: '#F8B9AF',
  lightTeal: '#AEE9E8',
  lightYellow: '#FFFAEF',
  lilac: '#AA9BCB',
  mint: '#94D0AB',
  mintCream: '#F3FAF6',
  moss: '#C2B484',
  muave: '#CB7790',
  navy: '#06556E',
  ocean: '#94B8C4',
  petal: '#EDBAD6',
  pewter: '#DDE5E7',
  pine: '#00471F',
  pink: '#E378B2',
  plum: '#5D5476',
  pool: '#5CC4CA',
  powderBlue: '#BAD0D7',
  pumpkin: '#E5780F',
  raisin: '#312F3E',
  sage: '#C5CD85',
  sapphire: '#53A0D8',
  scarlet: '#DB331A',
  sepia: '#AB8F4A',
  shadow: '#323232',
  shell: '#FEF8F7',
  sky: '#8AB1BE',
  slate: '#757575',
  smoke: '#888888',
  softGrey: '#E5E5E5',
  spaceGrey: '#454545',
  steel: '#A9A9A9',
  tangerine: '#FF671F',
  teal: '#00A3AD',
  violet: '#673AB7',
  white: '#FFFFFF',
}

const palette = {
  primary: {
    main: tbPalette.navy,
    light: tbPalette.cerulean,
    // dark: missing,
  },
  secondary: {
    main: tbPalette.teal,
    light: tbPalette.lightTeal,
    // dark: missing,
  },
  error: {
    main: tbPalette.scarlet,
    light: tbPalette.shell,
    // dark: missing,
  },
  success: {
    main: tbPalette.kale,
    // light: missing,
    // dark: missing,
  },
  tb: tbPalette,
}

export default palette

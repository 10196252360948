import { datadogRum } from '@datadog/browser-rum'
import browserConfig from '~/src/shared/config/browser'

if (browserConfig.environment === 'production') {
  datadogRum.init({
    service: 'venue-ark-frontend',
    applicationId: browserConfig.ddRumApplicationId,
    clientToken: browserConfig.ddRumClientToken,
    site: 'datadoghq.com',
    sessionReplaySampleRate: 100,
    sessionSampleRate: 25,
    useCrossSiteSessionCookie: true,
    version: process.env.NEXT_PUBLIC_GITSHA,
    allowedTracingUrls: [/https:\/\/.*\.touchbistro\.com/],
    trackResources: true,
    trackUserInteractions: true,
    enableExperimentalFeatures: ['feature_flags'],
    defaultPrivacyLevel: 'allow',
  })

  datadogRum.startSessionReplayRecording()
}

import { createTheme } from '@mui/material'
import { createBreakpoints, createSpacing } from '@mui/system'
import {
  defaultFontStack,
  fontFamilyBold,
  fontFamilyMedium,
  fontFamilyRegular,
} from '~/src/shared/styles/theme/fonts'
import palette, { tbPalette } from '~/src/shared/styles/theme/palette'

const spacing = createSpacing()
const breakpoints = createBreakpoints({
  keys: ['mobile', 'sm', 'md', 'lg'],
  values: {
    mobile: 320,
    sm: 768,
    md: 1024,
    lg: 1440,
  },
})
const borderRadius = 4
const padding = {
  mobile: 16,
  sm: 36,
  md: 52,
}

const theme = createTheme({
  breakpoints,
  padding,
  boxShadow: { boxShadow: '0 11px 15px 0 rgba(0, 0, 0, 0.07)' },
  paperFormBoxShadow: { boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.07)' },
  dropDownBoxShadow: { boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.07)' },
  palette,
  // https://www.figma.com/file/a0096utVzn4zdb8l0jXB4u/Cloud-%2F-Text-Styles
  typography: {
    fontFamilyRegular,
    fontFamilyMedium,
    fontFamilyBold,
    defaultFontStack,
    fontFamily: fontFamilyRegular,
    useNextVariants: true,
    subtitle1: undefined,
    subtitle2: undefined,
    body1: undefined,
    body2: undefined,
    h5: undefined,
    h6: undefined,
    body: {
      lineHeight: 1.5,
      fontFamily: fontFamilyRegular,
      color: tbPalette.spaceGrey,
      letterSpacing: 0,
      fontSize: 16,
      margin: '8px 0',
    },
    bodyBold: {
      fontFamily: fontFamilyMedium,
      color: tbPalette.spaceGrey,
      letterSpacing: 0,
      lineHeight: 1.5,
      fontSize: 16,
      flex: 1,
      margin: '8px 0',
    },
    h1: {
      fontFamily: fontFamilyMedium,
      color: tbPalette.spaceGrey,
      letterSpacing: 0.4,
      lineHeight: 1.2,
      fontSize: 24,
      margin: '8px 0',
    },
    h2: {
      fontFamily: fontFamilyMedium,
      color: tbPalette.spaceGrey,
      letterSpacing: 0.4,
      lineHeight: 1.2,
      fontSize: 20,
      margin: '8px 0',
    },
    h3: {
      fontFamily: fontFamilyMedium,
      color: tbPalette.spaceGrey,
      letterSpacing: 0.4,
      lineHeight: 1.2,
      fontSize: 18,
      margin: '8px 0',
    },
    h4: {
      fontFamily: fontFamilyMedium,
      color: tbPalette.spaceGrey,
      letterSpacing: 0.2,
      lineHeight: 1.2,
      fontSize: 16,
      margin: '8px 0',
    },
    caption: {
      fontFamily: fontFamilyRegular,
      color: tbPalette.slate,
      letterSpacing: 0,
      lineHeight: 1.2,
      fontSize: 16,
      margin: '8px 0',
    },
    subtitle: {
      lineHeight: 1.5,
      fontFamily: fontFamilyRegular,
      color: tbPalette.slate,
      letterSpacing: 0,
      fontSize: 16,
      margin: '8px 0',
    },
    overline: {
      // called "table title"
      fontFamily: fontFamilyMedium,
      color: tbPalette.spaceGrey,
      textTransform: 'uppercase',
      letterSpacing: 0.6,
      lineHeight: 1.2,
      fontSize: 10,
      margin: '8px 0',
    },
    code: {
      color: tbPalette.spaceGrey,
      fontFamily: `Courier, Lucida, Monaco, ${fontFamilyRegular}`,
    },
  },
  topBar: {
    height: 80,
  },
  appBar: {
    minHeight: 64,
  },
  drawer: {
    mediumAndUp: {
      width: {
        open: 260,
        closed: 92,
      },
    },
    small: {
      width: {
        open: 320,
        closed: 0,
      },
    },
    mobile: {
      width: {
        open: '100%',
        closed: 0,
      },
    },
  },
  // MUI requires 25 shadows
  shadows: [
    'none',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '0px -4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
    '0px 4px 9px 1px rgba(0, 0, 0, 0.09)',
  ],
  shape: {
    borderRadius,
  },
  mixins: {
    toolbar: undefined,
    topBarPadding: {
      padding: `0 ${padding.mobile}px`,

      [breakpoints.up('sm')]: {
        padding: `0 ${padding.sm}px`,
      },
      [breakpoints.up('md')]: {
        padding: `0 ${padding.md}px`,
      },
    },
    contentPadding: {
      padding: `${padding.mobile}px`,

      [breakpoints.up('sm')]: {
        padding: `${padding.sm}px`,
      },
      [breakpoints.up('md')]: {
        padding: `${padding.md}px`,
      },
    },
  },

  // Component Overrides
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        variant: 'contained',
        color: 'primary',
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          borderStyle: 'solid',
          borderWidth: 1,
          boxShadow: 'none',
          fontFamily: fontFamilyMedium,
          fontSize: 14,
          height: 42,
          padding: spacing(0, 3.5),
          textTransform: 'capitalize',

          '&:hover:not(:active), &:focus': {
            boxShadow: 'none',
          },

          '&:active': {
            boxShadow: 'none',
          },
        },
        contained: {
          '&:disabled': {
            background: tbPalette.softGrey,
            borderColor: tbPalette.softGrey,

            '& svg': {
              color: tbPalette.steel,
            },
          },
        },
        outlined: {
          backgroundColor: tbPalette.white,
          color: tbPalette.navy,

          '&:hover:not(:active), &:focus': {
            backgroundColor: tbPalette.white,
          },
          '&:disabled': {
            borderColor: tbPalette.steel,
            color: tbPalette.steel,

            '& svg': {
              color: tbPalette.steel,
            },
          },
        },
        text: {
          borderColor: 'transparent',

          '&:hover:not(:active)': {
            backgroundColor: 'transparent',
          },
          '&:disabled': {
            color: tbPalette.steel,

            '& svg': {
              color: tbPalette.steel,
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: tbPalette.white,
          border: `1px solid ${tbPalette.featherGrey}`,
          borderRadius,
          margin: spacing(0.5, 0, 2, 0),
          '&.Mui-error': {
            border: `1px solid ${tbPalette.scarlet}`,
          },
        },
        input: {
          padding: spacing(0, 1.5),
          fontSize: 16,
          height: 42,
        },
        inputMultiline: {
          padding: spacing(1, 1.5),
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          height: 24,
          maxHeight: 24,
          padding: 9,
          margin: 0,

          '& svg': {
            fontSize: '1rem',
          },
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          padding: 0,

          '&:before, &:after': {
            borderBottom: 0,
          },
          '&:hover:before': {
            borderBottom: `0 !important`,
          },
          '&.Mui-focused': {
            border: `1px solid ${tbPalette.cerulean}`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { border: 'none' },
        input: { padding: spacing(0, 1.5) },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: false,
      },
      styleOverrides: {
        root: {
          fontFamily: fontFamilyMedium,
          transform: 'translate(0, -12px) scale(0.75)',

          '& ~ .MuiInput-root': {
            marginTop: '0',
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 1,
      },
      styleOverrides: {
        root: {
          borderRadius,
        },
        rounded: {
          borderRadius,
        },
        elevation0: {
          border: `1px solid ${tbPalette.softGrey}`,
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          border: 'none',
          '&.Mui-expanded': {
            margin: 0,
          },
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          // allows line-breaks on translated texts using \n
          whiteSpace: 'pre-line',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&> [class*="MuiFormHelperText"]': {
            marginLeft: 0,
            marginTop: -12,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: spacing(0, 1.5),

          '& .MuiAutocomplete-input': {
            padding: '1px 4px 1px 5px',
          },
        },
        input: {
          padding: spacing(0, 1.5),
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.error': {
            color: tbPalette.scarlet,
          },
          '& svg': {
            height: '1.25rem',
            width: '1.25rem',
            fontSize: 'initial',
            fill: tbPalette.navy,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          border: `1px solid ${tbPalette.softGrey}`,
          borderRadius: 24,
          display: 'flex',
          height: 30,
          overflow: 'visible',
          padding: 0,
          width: 58,
        },
        thumb: {
          border: `1px solid ${tbPalette.softGrey}`,
          boxShadow: '4px 4px 8px 0 rgba(0, 0, 0, 0.2)',
          height: 26,
          position: 'absolute',
          top: 0,
          left: 0,
          width: 26,

          '.Mui-disabled &': {
            backgroundColor: tbPalette.softGrey,
          },

          '.Mui-checked  &': {
            color: tbPalette.white,
            transform: 'translateX(9px)',
          },
        },
        track: {
          backgroundColor: tbPalette.featherGrey,
          borderRadius: 24,
          opacity: 1,

          '.Mui-disabled.Mui-disabled + &': {
            opacity: 1,
          },

          '.Mui-checked.Mui-checked + &': {
            backgroundColor: tbPalette.navy,
            opacity: 1,
          },

          '.Mui-checked.Mui-disabled + &': {
            backgroundColor: tbPalette.sky,
          },

          // for keyboard a11y
          '.Mui-focusVisible + &': {
            borderRadius: 24,
            boxShadow: `0 0 2px 2px ${tbPalette.navy}`,
            height: '100%',
            outline: `1px solid ${tbPalette.softGrey}`,
            width: '100%',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        fullWidth: {
          backgroundColor: tbPalette.softGrey,
          margin: '1rem 0',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: { borderRadius },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        // increase specificity
        select: {
          '&': {
            padding: '1px 12px',
          },
        },
        icon: {
          height: 12,
          marginRight: 6,
          marginTop: 2,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: 44,
          padding: spacing(0, 1.5),
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          marginTop: spacing(1.5),
          marginLeft: spacing(0.5),
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: spacing(0.5),
          marginRight: spacing(1),
          color: tbPalette.featherGrey,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: tbPalette.white,
          borderRadius,
          border: `1px solid ${tbPalette.softGrey}`,
          boxSizing: 'border-box',
          borderCollapse: 'initial',
          padding: spacing(4),
          '& th': {
            fontFamily: fontFamilyMedium,
            fontSize: 14,
            lineHeight: '13.2px',
            textTransform: 'uppercase',
          },
          '& td': {
            fontFamily: fontFamilyRegular,
            lineHeight: '15.4px',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius,
          margin: '70px 60px 20px 60px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '70px 60px 20px 60px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          margin: '0px',
          padding: '0px',
        },
        dividers: {
          paddingLeft: '60px',
          paddingRight: '60px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        spacing: {
          padding: '20px 60px',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${tbPalette.softGrey}`,
          padding: spacing(1, 3),
          '&.Mui-expanded': {
            minHeight: '48px',
          },
        },
        content: {
          margin: spacing(1.5, 0),
          '&.Mui-expanded': {
            margin: spacing(1.5, 0),
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${tbPalette.softGrey}`,
          flexFlow: 'column',
          padding: 0,
        },
      },
    },
    MuiDropzoneArea: {
      styleOverrides: {
        root: {
          borderColor: tbPalette.featherGrey,
          borderRadius,
          padding: spacing(2),
          borderStyle: 'dashed',
          minHeight: 'auto',
          borderWidth: 1,
        },
        textContainer: {
          color: tbPalette.featherGrey,
          flexDirection: 'column-reverse',
          gap: spacing(1),
          alignItems: 'center',
          display: 'flex',
        },
        text: {
          color: tbPalette.slate,
          fontSize: spacing(2),
          marginBottom: 0,
          marginTop: 0,
        },
      },
    },
    MuiDropzonePreviewList: {
      styleOverrides: {
        root: {
          marginTop: spacing(2),
        },
        imageContainer: {
          padding: 0,
          width: '100%',
          '& > div': {
            fontFamily: fontFamilyMedium,
            color: tbPalette.heatherGrey,
            background: tbPalette.cloud,
            borderRadius,
            fontSize: spacing(1.75),
            padding: spacing(2, 1),
            width: 'inherit', // truncates text
            border: 'none',
            height: 'auto',
            '& span': {
              // truncates text
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            },
          },
        },
      },
    },
    MUIRichTextEditor: {
      styleOverrides: {
        container: {
          margin: spacing(2, 0),
          border: `1px solid ${tbPalette.featherGrey}`,
          borderRadius,
        },
        editorContainer: {
          padding: spacing(1, 1.5),
          boxSizing: 'border-box',
          margin: 0,
          '&.Mui-error': {
            border: `1px solid ${tbPalette.scarlet}`,
          },
        },
        editor: {
          minHeight: 33,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          letterSpacing: 0,
          fontFamily: fontFamilyRegular,
          fontSize: 16,

          '&:hover': {
            color: tbPalette.cerulean,
          },
        },
      },
    },
    MuiToolbar: {
      variants: [
        {
          props: { variant: 'tabs' },
          style: {
            marginBottom: '0 !important',
          },
        },
      ],
    },
  },
})

export default theme
